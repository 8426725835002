import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import "../../styles/candidate_nav.css";

const HomePageNav = () => {
    let accessToken = useSelector(state => state.loginReducer.accessToken);
    const session = JSON.parse(sessionStorage.getItem("session"));
    
    if (accessToken || (session && session?.jwtResponse?.token)) {
        return null;
    } else {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-color-third row justify-content-end pr100 h50">
                <div className="container-fluid justify-content-end">
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="text-center me-3">
                            <Link to="/candidate" className="nav-link" onClick={() => window.location.href = "/candidate"}>
                                <span className="text-third-nav ms-1" data-toggle="tab">{RegistrationJson.HOME}</span>
                            </Link>
                        </li>
                        <li className="text-center">
                            <Link to="/candidate/login" className="nav-link" onClick={() => window.location.href = "/candidate/login"}>
                                <span className="text-third-nav ms-1" data-toggle="tab">{RegistrationJson.Login}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
};

export default HomePageNav;