/* eslint-disable */
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from "react-redux";

import AppLayout from './common/components/AppLayout';
import { getAPIRepoVersion } from './registration_login/actions/sessionActions';
import SupportProfileDisplay from './support/components/SupportProfileDisplay';

import './App.css';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAPIRepoVersion());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/candidate/adminsupport/viewprofile" element={<SupportProfileDisplay />} />
        <Route path="*" element={<AppLayout />} />
      </Routes>
    </Router>
  );
}

export default App;


// import { useEffect } from 'react';
// import { Route, Router, Switch } from 'react-router-dom';
// import { useDispatch } from "react-redux";
// import { createBrowserHistory } from 'history';

// import AppLayout from './common/components/AppLayout';
// import { getAPIRepoVersion } from './registration_login/actions/sessionActions';
// import SupportProfileDisplay from './support/components/SupportProfileDisplay';

// import './App.css';

// function App() {
//   const history = createBrowserHistory();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAPIRepoVersion());
//   }, []);

//   return (
//     <Router history={history}>
//       <Switch>
//         <Route path="/candidate/adminsupport/viewprofile" component={SupportProfileDisplay} />
//         <Route exact path="" component={AppLayout} />
//       </Switch>
//     </Router>
//   );
// }

// export default App;
