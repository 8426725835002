import React from "react";
import { Controller } from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json';
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import numberformat from "../labels/serial_nos_en.json";
import { alphanumeric, numberWithDecimal, percentage } from '../../common/constants/regex';
import dayjs from 'dayjs';
import _ from "lodash/fp";
import { RESULTS } from "../../common/constants/dropdownOptions";
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

const CertificationsQualification = () => {

  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType, setQualificationState,
    register, handleSubmit, errors, qualificationState, currentEditQualificationIndex, control,
    isResultDateMandatory, onQualificationNameChange, handleResultTypeChange, qualificationNames } = useQualificationActions();

  return (
    <form onSubmit={handleSubmit((e) => { handleQualificationInfoSubmit(e) })}>
      <div className="row">
        <QualificationTypeDropdown />
        {/** Following div is to displayed conditionally */}
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.CERTIFICATION_NAME}</label>
          <span className="redColor">*</span>
          <select className="form-select" value={qualificationState.qualificationnamecode} name="qualificationnamecode" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              qualificationNames.sort((name1, name2) => { return name1.qualificationName.localeCompare(name2.qualificationName)}).map((ele, i) => {
                return <option key={i} value={ele.qualificationNameCode}>{ele.qualificationName}</option>
              })
            }
          </select>
          {_.get("qualificationnamecode.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.3"]}&nbsp;{qualificationinformation.RESULT} </label>
          <span className="redColor">*</span>
          <select className="form-select" value={qualificationState.typeResult} name ="typeResult" ref={register({ required: true })} onInput={handleResultTypeChange}>
            {
              RESULTS.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("typeResult.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-2">
          <label className="float-start mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <Controller
            name="resultDate"
            rules={{ required: isResultDateMandatory, validate: value => value <= new Date() }}
            key={qualificationState.resultDate ? "resultDate" : "resultDate1"}
            defaultValue={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : fromUTCDate(value)}
                maxDate={new Date()}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    resultDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                disabled={!isResultDateMandatory}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
          {_.get("resultDate.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("resultDate.type", errors) === "validate" && (
            <p className="errorText">{qualificationinformation.DATA_LTE_TODAY_MSG}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-2">
          <label className="float-start mb-0">{numberformat["4.5"]}&nbsp;{qualificationinformation.GRADE}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" defaultValue={qualificationState.certificationGrade} maxLength={3} name="certificationGrade" ref={register({ required: isResultDateMandatory, pattern: alphanumeric })} onChange={handleQualificationChange}></input>
          {_.get("certificationGrade.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("certificationGrade.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.ONLY_CHARATERS_NUMBERS}</p>
          )}
        </div>
        <div className="col-md-3 mb-2">
          <label className="float-start mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.PERCENTAGE_GRADE}{isResultDateMandatory ? <span className="redColor">*</span>: ""}</label>
          <input type="text" className="form-control" maxLength={5} value={qualificationState.percentageGrade ? qualificationState.percentageGrade : ""} defaultValue={qualificationState.percentageGrade} name="percentageGrade" ref={register({ required: isResultDateMandatory, pattern: percentage })} onChange={handleQualificationChange}></input>
          {_.get("percentageGrade.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("percentageGrade.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_PERCENTAGE_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mt-2 mb-2">
          <label className="float-start mb-0">{numberformat["4.7"]}&nbsp;{qualificationinformation.COURSE_DURATION}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" value={qualificationState.courseDurationMonths ? qualificationState.courseDurationMonths : ""} defaultValue={qualificationState.courseDurationMonths} maxLength={2} name="courseDurationMonths" ref={register({ required: isResultDateMandatory, pattern: numberWithDecimal })} onChange={handleQualificationChange}></input>
          {_.get("courseDurationMonths.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
           {_.get("courseDurationMonths.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_COURSE_DURATION_MESSAGE}</p>
            )}
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
            <button type="submit" name="edit" className="btn btn-primary m-1" onClick={(e) => { setActionType('edit') }}>{qualificationinformation.UPDATE}</button>
            :
            <button type="submit" name="add" className="btn btn-primary m-1" onClick={(e) => { setActionType('add') }}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default CertificationsQualification;