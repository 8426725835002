import { shallowEqual, useSelector } from "react-redux";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import "../../styles/candidate_nav.css";

export default function ApplicationFooter() {
    let { accessToken, apiRepoVersion } = useSelector(state => state.loginReducer, shallowEqual);
    const session = JSON.parse(sessionStorage.getItem("session"));
    return (
        accessToken || (session && session?.jwtResponse?.token) ?
        ""
        :
        <div className="footer d-flex justify-content-between">
            <div className=" text-center">
              <span>{RegistrationJson.DISCLAIMER}</span>
            </div>
            <div className="text-center">
              <span>{RegistrationJson.OFFICIAL_WEB_NOTE_1}<br/>{RegistrationJson.OFFICIAL_WEB_NOTE_2}</span>
            </div>
            <div className="col-sm-1 text-center mt-1 f10" >
              {process.env.REACT_APP_VERSION} {apiRepoVersion ? apiRepoVersion : ''}
            </div>
        </div>
    )
}