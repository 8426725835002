/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import qualificationinformation from '../labels/qualification_information_en.json'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllQualificationTypes, getQualificationNamesOfAType, getPublicationTypeData} from "../actions/profileAction";
import { IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN, SET_SELECTED_QUALIFICATION_TYPE } from '../../common/constants/ActionTypes';
import numberformat from "../labels/serial_nos_en.json";

const QualificationTypeDropdown = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() =>{
    dispatch(getAllQualificationTypes());
    inputRef.current.focus();
  }, []);

  const { qualificationTypes, qualificationtypecode } = useSelector( state => state.profile, shallowEqual );

  const onQualificationTypeChange = (e) => {
    let qualificationSelectedTypeCode = e.target.value;
    /** This action is to be dispatched conditionally as a few Qualification Types
     *  don't have the Qualification Name dropdown. */
    dispatch(getQualificationNamesOfAType(qualificationSelectedTypeCode));
    dispatch({
      type: IS_QUALIFICATION_TYPE_CHANGED_BY_DROPDOWN,
      payload: true
    });

    dispatch({
      type: SET_SELECTED_QUALIFICATION_TYPE,
      payload: qualificationSelectedTypeCode
    });
    if(qualificationSelectedTypeCode === "Pb"){
      dispatch(getPublicationTypeData(qualificationSelectedTypeCode))
    }
  }
 
  return (
      <div className="col-md-3 mb-3">
        <label className='float-start mb-0'>{numberformat["4.1"]}&nbsp;{qualificationinformation.QUALIFICATION_TYPE}</label>
        <span className="redColor">*</span>
        <select className="form-select" name ="qualificationtype" 
          value={qualificationtypecode}
          onInput={onQualificationTypeChange}
          ref={inputRef} disabled={false}>
          <option key={0} value="Select">{qualificationinformation.Select_Qulification_Type}</option>
          {
            qualificationTypes.sort((type1,type2) => { return type1.sortOrder - type2.sortOrder }).map((qualificationType, i) => {
              return (
                <option key={i} value={qualificationType.qualificationtypecode}>
                  { qualificationType.qualificationtypename }
                </option>
              )
            })
          }
        </select>
      </div>
    )
}

export default QualificationTypeDropdown;