import React from "react";
import { Controller } from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json'
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import { RESULTS } from "../../common/constants/dropdownOptions";
import numberformat from "../labels/serial_nos_en.json";
import { schoolname } from '../../common/constants/regex'
import dayjs from 'dayjs';
import _ from "lodash/fp";
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
const maxLengthSchoolName = 100;

const BelowSSCQualification = () =>  {

  const { handleQualificationChange, handleQualificationInfoSubmit, handleResultTypeChange, setActionType,
    isResultDateMandatory, register, control, handleSubmit, errors, setQualificationState, qualificationState,
    currentEditQualificationIndex, qualificationNames, statesList, onQualificationNameChange, onStateChange } = useQualificationActions();

  return (
    <form onSubmit={handleSubmit((e) => {handleQualificationInfoSubmit(e)})}>
      <div className="row">
        <QualificationTypeDropdown/>
        {/** Following div is to displayed conditionally */}
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.STATE}</label>
          <select className="form-select" value={qualificationState.statecode} name ="statecode" ref={register({ required: false })} onInput={onStateChange}>
            <option key={0} value="Select">{qualificationinformation.SELECT_STATE}</option>
            {
              statesList.map((ele, i) => {
                return <option key={i} value={ele.statecode}>{ele.statename}</option>
              })
            }
          </select>
        </div>
        <div className="form-group col-md-6">
            <label className="float-start mb-0" for="schoolName">{numberformat["4.3"]}&nbsp;{qualificationinformation.SCHOOL_NAME}</label>
            <span className="redColor">*</span>
            <input id="schoolName" className="form-control" value={qualificationState.schoolName ? qualificationState.schoolName : ""} defaultValue={qualificationState.schoolName} maxLength={maxLengthSchoolName} name ="schoolName" ref={register({ required: true, pattern: schoolname })} onChange={handleQualificationChange}/>
            {_.get("schoolName.type", errors) === "required" && (
              <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("schoolName.type", errors) === "pattern" && (
              <p className="errorText">{qualificationinformation.Enter_valid_School_Name}</p>
            )}
        </div>
       
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.STANDARD}</label>
          <span className="redColor">*</span>
          <select className="form-select" value={qualificationState.qualificationnamecode} name ="qualificationnamecode" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              qualificationNames.length > 0 ? qualificationNames.sort((name1,name2) => { return name1.sortOrder-name2.sortOrder }).map((ele, i) => {
                return <option key={i} value={ele.qualificationNameCode}>{ele.qualificationName}</option>
              }): ""
            }
          </select>
          {_.get("qualificationnamecode.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.5"]}&nbsp;{qualificationinformation.RESULT} </label>
          <span className="redColor">*</span>
          <select className="form-select" value={qualificationState.typeResult} name ="typeResult" ref={register({ required: true })} onInput={handleResultTypeChange}>
            {
              RESULTS.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("typeResult.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <Controller
            name= "resultDate"
            key= {qualificationState.resultDate ? "resultDate": "resultDate1"}
            defaultValue={qualificationState.resultDate? dayjs(qualificationState.resultDate).toDate(): ""}
            rules={{ required: isResultDateMandatory, validate: value => value <= new Date() }}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.resultDate? dayjs(qualificationState.resultDate).toDate(): fromUTCDate(value)}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                disabled={!isResultDateMandatory}
                maxDate={new Date()}
                onChange={(e) => {setQualificationState({
                  ...qualificationState,
                  resultDate: toUTCDate(e)
                }); onChange(toUTCDate(e))}}
                className="form-control"
              />
            )}
          />
          {_.get("resultDate.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("resultDate.type", errors) === "validate" && (
            <p className="errorText">{qualificationinformation.DATA_LTE_TODAY_MSG}</p>
          )}
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
          <button type="submit" name="edit" className="btn btn-primary m-1" onClick={() => {setActionType('edit')}}>{qualificationinformation.UPDATE}</button>
            :
          <button type="submit" name="add" className="btn btn-primary m-1" onClick={() => {setActionType('add')}}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default BelowSSCQualification;
