/* eslint-disable */
import React, { useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import common from "../labels/common_en.json"
import { Modal } from 'react-bootstrap';
import { setUpdatePostModal , updatePostPreference} from "../actions/myAccountAction";

function UpdatePostPreference() {
    const { handleSubmit } = useForm({
        mode: 'onChange',
    });
    const dispatch = useDispatch();
    const { updatePostPreferenceFlag } = useSelector(state => state.myaccount, shallowEqual);
   
    const {applicationDetails} = useSelector(
        (state) => state.myaccount,
        shallowEqual
    );    
   
    const [updatedPreference, setupdatedPreference] = useState([]);
    const [dummy,setDummy] = useState(0);
    const modalCancel = () => {
        dispatch(setUpdatePostModal(false));
        setupdatedPreference([]);
        setDummy(0);
    }
   
    const movePostPrefUp = (index) => {
        if (index == 0) return;
        const tmp = applicationDetails.applicationPostPrefDtoList[index];
        applicationDetails.applicationPostPrefDtoList[index] = applicationDetails.applicationPostPrefDtoList[index-1];
        applicationDetails.applicationPostPrefDtoList[index-1] = tmp;
        setDummy(dummy+1);
        setupdatedPreference(applicationDetails.applicationPostPrefDtoList);
    }

    const movePostPrefDown = (index) => {
        if (index >= ((applicationDetails.applicationPostPrefDtoList).length)) return;
        const tmp = applicationDetails.applicationPostPrefDtoList[index];
        applicationDetails.applicationPostPrefDtoList[index] = applicationDetails.applicationPostPrefDtoList[index+1];
        applicationDetails.applicationPostPrefDtoList[index+1] = tmp;
        setDummy(dummy+1);
        setupdatedPreference(applicationDetails.applicationPostPrefDtoList);
    }

    const onSubmit = () => {
        let reqBody;
        reqBody = {
            "applicationId" : applicationDetails.application.id,
            "applicationPostPrefList": updatedPreference.map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
        }
        dispatch(updatePostPreference(reqBody));
        dispatch(setUpdatePostModal(false));
        setDummy(0);
        setupdatedPreference([]);
    }

    return (
        <form  className="UpdatePostPreferenceForm mt50">
            <div>
                <Modal show={updatePostPreferenceFlag} onHide={modalCancel} backdrop='static'>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title>{common.UPDATE_POST_PREF}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="list-group list-group-flush">
                                    { applicationDetails.applicationPostPrefDtoList?.map((post, index) =>
                                        <li key={index} className="list-group-item post-pref-item d-flex">
                                            <div className="flex-grow-1">
                                                <span >{post.postName}&nbsp; &nbsp; | {post.postNameDvng} </span> 
                                            </div>
                                            <div className="arrange-arrows d-flex flex-column justify-content-between">
                                                <div>
                                                    { (index > 0) &&
                                                        <i className="fa fa-arrow-up"
                                                            onClick={() => movePostPrefUp(index)}mtitle="Move Up"></i>
                                                    }
                                                </div>
                                                <div>
                                                    { (index < ((applicationDetails.applicationPostPrefDtoList).length-1)) &&
                                                        <i className="fa fa-arrow-down"
                                                            onClick={() => movePostPrefDown(index)} title="Move Down"></i>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )}
                        </ul>                        
                        {(applicationDetails.applicationPostPrefDtoList) &&(applicationDetails.applicationPostPrefDtoList).length <= 1 ? 
                        <span className="Note mt-1 ms-3">{common.MULTIPLE_POST_NOTE}</span> : ""}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>                        
                        <button type="button" className="btn btn-primary" 
                        onClick={handleSubmit((e) => { onSubmit(e) })} disabled={(dummy === 0) }
                        >{common.SAVE}</button>                        
                    </Modal.Footer>
                </Modal>
            </div>
        </form>
    );
}
export default UpdatePostPreference;