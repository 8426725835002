import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import "../../styles/advertisementTable.css";

const QuestionDetails = ({ additionalAnswers, handleQuestions }) => {
    let { applicableAdditionalQuestionsList } = useSelector(state => state.advertisement, shallowEqual);
    let count = 0;

    return (
        <div className="advertisement_details_Eligibility border border-light">
            <label className="fw-bold">
                {advertisementDetailsLabel.QUESTIONS}
            </label>
            <hr />
            <div className="card">
                <table className="table table-bordered mb-3">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="text-center w10 theadBgColor"
                            >
                                {advertisementDetailsLabel.No}
                            </th>
                            <th scope="col" className="w70 theadBgColor">
                                {advertisementDetailsLabel.QUESTION_DESCRIPTION}
                            </th>
                            <th scope="col" className="w20 theadBgColor">
                                {advertisementDetailsLabel.ANSWER}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="f12">
                        {applicableAdditionalQuestionsList &&
                            applicableAdditionalQuestionsList.map(
                                (que) => (
                                    <tr key={que.id}>
                                        <td className="w-10 word-break text-center">{++count}</td>
                                        <td className="word-break">
                                            {que.question}
                                            <span className="redColor">*</span>
                                        </td>
                                        <td className="word-break">
                                            {que.answerType.includes("Text") ||
                                                que.answerType.includes("Date") ||
                                                que.answerType.includes("Number") ? (
                                                <input
                                                    type={que.answerType}
                                                    className="form-control"
                                                    name={que.id}
                                                    id={que.id}
                                                    value={additionalAnswers[que.id] ?? ''}
                                                    onChange={handleQuestions}
                                                />
                                            ) : (
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="radio"
                                                            name={que.id}
                                                            id={que.id}
                                                            value={true}
                                                            onChange={handleQuestions}
                                                        />
                                                        &nbsp;{advertisementDetailsLabel.YES}
                                                        &nbsp;&nbsp;
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="radio"
                                                            name={que.id}
                                                            id={que.id}
                                                            value={false}
                                                            onChange={handleQuestions}
                                                        />
                                                        &nbsp;{advertisementDetailsLabel.NO}
                                                    </label>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default QuestionDetails;
