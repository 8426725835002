/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import profile from "../../candidate_profile_creation/labels/personal_information_en.json";
import profileotherinformation from "../../candidate_profile_creation/labels/other_information_en.json";
import { email, mobile, securityQuestionRegex, numberValidation } from "../../common/constants/regex";
import { setForgotEmailAndMobile, verifyCandidateDetails, updateEmailAndMobile } from "../actions/homeAction";
import { generateOTP, verificationOfOTP, enableOTPButton, checkModuleEnableDisable} from "../../common/actions/commonActions";
import { getSecurityQuestions } from "../../registration_login/actions/registrationActions";
import ForgotPasswordJson from "../../registration_login/labels/forgot_password_en.json";
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/home.css";
import "../../styles/profile.css";
import "../../styles/advertisementTable.css";
import commonLabels from "../../my_account/labels/common_en.json";
import { useNavigate } from 'react-router-dom';

function ForgotEmailandMobile(props) {
    const navigate = useNavigate();
    const { register, control, watch, errors, formState, handleSubmit, setValue, setError } = useForm({
        mode: 'onChange',
    });
    const dispatch = useDispatch();
    const { forgotEmailMobileModal, isVerifyDetails, verifyOTPFlagForgotEmail, verifyOTPFlagForgotMobile, securityQuestionsList, 
        securityAnswersForEmail} = useSelector(state => state.home, shallowEqual);
    let { emailOTPResponse, mobileOTPResponse, moduleDetails, isApiCallForModule} = useSelector(state => state.common, shallowEqual);
    const newEmail = watch("newEmail");
    const newMobileNumber = watch("newMobileNumber");
    let enteredEmailOtp = watch("otp_email");
    let enteredMobileOtp = watch("otp_mobile");
    const [otpDisableForEmail, setotpDisableForEmail] = useState(false)
    const [otpDisableForMobile, setotpDisableForMobile] = useState(false)
    const [securityAnswerMap, setsecurityAnswerMap] = useState(new Map());

    useEffect(()=>{
        dispatch(getSecurityQuestions());
    },[])
    
    useEffect(()=>{
        if(!isApiCallForModule){
            dispatch(checkModuleEnableDisable("FRGT_RESET_EMAIL_MOBILE"));
        }},[isApiCallForModule]);

    useEffect(() => {
        setValue('updateType', 'email');
    }, [forgotEmailMobileModal])

    useEffect(() => {
        if(securityAnswersForEmail){
            let securityAnswer = new Map(Object.entries(securityAnswersForEmail));
            setsecurityAnswerMap(securityAnswer);
        }
    },[securityAnswersForEmail])

    const startTimeCount = (type) => {
        if(type === "email"){
          let start = 180;
          function displayTime() {
              let end = start - 1;
              start = end;
              let element = document.getElementById('emailTimer');
              if (element) {
                  element.textContent = start;
              }
              if (start == 0 ) {
                clearInterval(createClock);
                setotpDisableForEmail(false);
              }
          }
          const createClock = setInterval(displayTime, 1000);      
        }    
        if(type === "mobile"){
          let start = 180;
          function displayTime() {
              let end = start - 1;
              start = end;
    
              let element = document.getElementById('mobileTimer');
              if (element) {
                  element.textContent = start;
              }
              if (start == 0 ) {
                clearInterval(createClock);
                setotpDisableForMobile(false);
              }
          }
          const createClock = setInterval(displayTime, 1000);      
        }     
    };

    const modalCancel = () => {
        dispatch(setForgotEmailAndMobile(false))
        // props.history.push("/candidate/login");
        navigate("/candidate/login");
        dispatch(enableOTPButton('login'))
    };

    const verifyDetails = (data) => {
        let requestBody = {}
        if (data.updateType == 'email') {
            requestBody.oldEmailId = data.oldEmailId
        }
        if (data.updateType == 'mobile') {
            requestBody.oldMobileNumber = data.oldMobileNumber
        }
        requestBody.updateType = data.updateType
        requestBody.updateTypemobile = data.updateType
        requestBody.firstName = data.firstName
        requestBody.middleName = data.middleName
        requestBody.dob = data.dob
        dispatch(verifyCandidateDetails(requestBody))
    };

    const getOTP = (email, mobile, type) => {
        dispatch(generateOTP({
            "email": email,
            "mobile": mobile,
            "type": type,
            "otpFor": "Reset Login Details",
            "getOtpOnlyFor": "email",
            "isForgotPassword": false
        }))
        if (newEmail != "") {
            dispatch(enableOTPButton('home-email'))
            setValue("otp_email", "", { shouldDirty: true })
            setotpDisableForEmail(true)
            startTimeCount(type);
        }
        if (newMobileNumber != "") {
            dispatch(enableOTPButton('home-mobileNumber'))
            setValue("otp_mobile", "", { shouldDirty: true })
            setotpDisableForMobile(true)
            startTimeCount(type);
        }
        setValue("password", "", { shouldDirty: true })
        setValue("confirmPassword", "", { shouldDirty: true })
    };

    const verificationOTP = () => {
        let reqBody = {}
        if (enteredEmailOtp) {
            reqBody.username = watch('newEmail')
            reqBody.enteredOtp = enteredEmailOtp
            reqBody.otpId = emailOTPResponse.id
            reqBody.otpType = emailOTPResponse.type
            dispatch(verificationOfOTP(reqBody, 'home-email'))
        } else {
            setError("otp_email", { type: "required" });
        }
    };

    const verificationOfOTPOnMobile = () => {
        let reqBody = {}
        if (enteredMobileOtp) {
            reqBody.username = watch('newMobileNumber')
            reqBody.enteredOtp = enteredMobileOtp
            reqBody.otpId = mobileOTPResponse.id
            reqBody.otpType = mobileOTPResponse.type
            dispatch(verificationOfOTP(reqBody, 'home-mobileNumber'))
        } else {
            setError("otp_mobile", { type: "required" });
        }
    };

    const onSubmit = (data) => {
        let formData = new Map(Object.entries(data))
        let sQlist = Object.keys(securityQuestionsList)
        let obj = new Map()
        sQlist.map((ele)=>{
            if(securityAnswerMap.has(ele) && securityAnswerMap.get(ele) != null ){
                obj.set(ele, securityAnswerMap.get(ele))
            }else{
                obj.set(ele, formData.get(ele))
            }
        })
        const securityAnswerList = Object.fromEntries(obj.entries())
        let reqBody = {}
        reqBody.updateType = data.updateType
        reqBody.securityAnswerList = securityAnswerList
        if (data.updateType == 'mobile') {
            reqBody.oldMobileNumber = data.oldMobileNumber
            reqBody.newMobileNumber = data.newMobileNumber
        }
        if (data.updateType == 'email') {
            reqBody.oldEmailId = data.oldEmailId
            reqBody.newEmailId = data.newEmail
        }
        dispatch(updateEmailAndMobile(props, reqBody, navigate))
    };

    return moduleDetails.isEnabled == false ?  < ModuleHide details={moduleDetails}/> :
    (
        <form className="ForgotEmailMobileForm">
            <div className="container">
                <div className="row mb35">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 border border-light bg-white p-4">
                    <div className="text-center" >
                        <span className="bold-text">{RegistrationJson.FORGOT_OR_RESET_EMAIL_ID_AND_MOBILE_NUMBER}</span>
                    </div>
                        <div className="row">
                            <div className="col-md-12 text-center m-2">
                                <label className="EmailIdAndMobileNoLabelCss">
                                    <input type="radio" className="radio" name="updateType" value="email" ref={register({ required: false })} />&nbsp;{RegistrationJson.EMAIL_ID}
                        </label>
                                <label className="EmailIdAndMobileNoLabelCss">
                                    <input type="radio" className="radio" name="updateType" value="mobile" ref={register({ required: false })} />&nbsp;{RegistrationJson.Mobile_Number}
                        </label>
                            </div>
                        </div>
                        <div className="row">
                            {watch("updateType") === 'email' ?
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.OLD_EMAIL_ID}<span className="errorText">*</span></label>
                                    <div className="input-group">
                                        <input className="form-control" id="oldEmailId" type="text" name="oldEmailId" ref={register({ required: true, maxLength: 320, pattern: email })} />
                                    </div>
                                    {_.get("oldEmailId.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_REQUIRED}</p>
                                    )}
                                    {_.get("oldEmailId.type", errors) === "maxLength" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_MAX_LENGTH}</p>
                                    )}
                                    {_.get("oldEmailId.type", errors) === "pattern" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_PATTERN}</p>
                                    )}
                                </div> : ""}
                            {watch("updateType") === 'mobile' ?
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.OLD_MOBILE_NO}<span className="errorText">*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="oldMobileNumber" name="oldMobileNumber" ref={register({ required: true, pattern: mobile })} />
                                    </div>
                                    {_.get("oldMobileNumber.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.MOBILE_REQ}</p>
                                    )}
                                    {_.get("oldMobileNumber.type", errors) === "pattern" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.MOBILE_MAX_LENGTH_WARNING}</p>
                                    )}
                                </div> : ""}
                            <div className="form-group col-md-6">
                                <label className="LeftText">{RegistrationJson.CANDIDATE_FN}<span className="errorText">*</span></label>
                                <div >
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={30}
                                        name="firstName" ref={register({
                                            required: true, pattern: {
                                                value: /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s_]+$/

                                            }, maxLength: 30
                                        })}
                                    />
                                    {_.get("firstName.type", errors) === "required" && (
                                        <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                                    )}
                                    {_.get("firstName.type", errors) === "pattern" && (
                                        <p className="errorText">{profile.ENTER_VALID_FIRSTNAME}</p>
                                    )}
                                    {_.get("firstName.type", errors) === "maxLength" && (
                                        <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="LeftText">{RegistrationJson.CANDIDATE_MN}<span className="errorText">*</span></label>
                                <div >
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={30}
                                        name="middleName" ref={register({
                                            required: true, pattern: {
                                                value: /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s_]+$/

                                            }, maxLength: 30
                                        })}
                                    />
                                    {_.get("middleName.type", errors) === "required" && (
                                        <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                                    )}
                                    {_.get("middleName.type", errors) === "pattern" && (
                                        <p className="errorText">{profile.ENTER_VALID_MIDDLENAME}</p>
                                    )}
                                    {_.get("middleName.type", errors) === "maxLength" && (
                                        <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
                                    )}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label className="LeftText">{RegistrationJson.Date_of_Birth}<span className="errorText">*</span></label>
                                <div className="input-group">
                                    <Controller
                                        control={control}
                                        name="dob"
                                        id="registrationFormControlInput7"
                                        rules={{ required: true }}
                                        render={({ onChange, value }) =>
                                            <DatePicker className="form-control"
                                                maxDate={new Date()}
                                                selected={fromUTCDate(value)}
                                                onChange={(e) => {
                                                    onChange(toUTCDate(e))
                                                }}
                                                placeholderText={commonLabels.DATE_FORMAT}
                                                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                                                showMonthDropdown
                                                showYearDropdown
                                                />
                                        }
                                    />
                                    {
                                        errors.dob && errors.dob.type === "validate" && (
                                            <p className="errorText">{RegistrationJson.AGE_NOTE}</p>
                                        )
                                    }
                                    {_.get("dob.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.DOB_REQUIRED}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {watch("updateType") === 'email' && isVerifyDetails ?
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.ENTER_NEW_EMAIL_ID}<span className="errorText">*</span></label>
                                    <div className="input-group">
                                        <input className="form-control" id="registrationFormControlInput1" type="text" name="newEmail" ref={register({ required: true, maxLength: 320, pattern: email })} />
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { getOTP(newEmail, "", "email") }}
                                                disabled={(_.get("newEmail.type", errors) ? true : false) || otpDisableForEmail}>{RegistrationJson.Get_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("newEmail.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_REQUIRED}</p>
                                    )}
                                    {_.get("newEmail.type", errors) === "maxLength" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_MAX_LENGTH}</p>
                                    )}
                                    {_.get("newEmail.type", errors) === "pattern" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.EMAIL_PATTERN}</p>
                                    )}
                                    {otpDisableForEmail && verifyOTPFlagForgotEmail == false? <p className="Note">{RegistrationJson.OTP_NOTE} <span id="emailTimer"></span> {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE} <span id="emailTimer"></span> {RegistrationJson.SECONDS}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.ENTER_NEW_OTP}<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="" name="otp_email" id="registrationFormControlInput3" maxLength={6} ref={register({ required: true, maxLength: 6 })} aria-label="emailotp" aria-describedby="button-addon2" />
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f11" type="button" id="button-addon2" onClick={verificationOTP} disabled={verifyOTPFlagForgotEmail}>{RegistrationJson.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("otp_email.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.OTP_REQ}</p>
                                    )}
                                    {_.get("otp_email.type", errors) === "maxLength" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.OTP_MAX_LENGTH_WARNING}</p>
                                    )}
                                </div>
                            </div> : ""}
                        {watch("updateType") === 'mobile' && isVerifyDetails ?
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.ENTER_NEW_MOBILE_NUMBER}<span className="errorText">*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="registrationFormControlInput2" name="newMobileNumber" ref={register({ required: true, pattern: mobile })} />
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { getOTP("", newMobileNumber, "mobile") }}
                                             disabled={(_.get("newMobileNumber.type", errors) ? true : false)|| otpDisableForMobile } >{RegistrationJson.Get_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("newMobileNumber.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.MOBILE_REQ}</p>
                                    )}
                                    {_.get("newMobileNumber.type", errors) === "pattern" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.MOBILE_MAX_LENGTH_WARNING}</p>
                                    )}
                                    {otpDisableForMobile && verifyOTPFlagForgotMobile == false ? <p className="Note">{RegistrationJson.OTP_NOTE} <span id="mobileTimer"></span> {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE} <span id="mobileTimer"></span> {RegistrationJson.SECONDS}</p>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="LeftText">{RegistrationJson.ENTER_OTP_SENT_TO_NEW_MOBILE_NO}<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="" name="otp_mobile" id="registrationFormControlInput4" maxLength={6} ref={register({ required: true, maxLength: 6 })} aria-label="mobileotp" aria-describedby="button-addon2" />
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f11" type="button" id="button-addon2" onClick={verificationOfOTPOnMobile} disabled={verifyOTPFlagForgotMobile} >{RegistrationJson.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("otp_mobile.type", errors) === "required" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.OTP_REQ}</p>
                                    )}
                                    {_.get("otp_mobile.type", errors) === "maxLength" && (
                                        <p className="errorText">{RegistrationJson.validationMessage.OTP_MAX_LENGTH_WARNING}</p>
                                    )}
                                </div>
                            </div> : ""}
                        <div>
                        { isVerifyDetails ?  
                        <div>
                        {(Object.entries(securityAnswersForEmail).length === Object.entries(securityQuestionsList).length && Object.values(securityAnswersForEmail).every(a => a != null)) ? ""  :
                        <div className="background-field mt-3">
                            <span className="errorText text-primary">{ForgotPasswordJson.SECURITY_QUESTIONS_NOTE}</span>
                        </div>}
                        {Object.entries(securityQuestionsList).sort().map(([key,value]) => {
                            return (
                                <div>
                                    {securityAnswerMap && securityAnswerMap.has(key) && (securityAnswerMap.get(key) != undefined || securityAnswerMap.get(key) != null) ?
                                     "" : 
                                     <div className="row form-group" key={key}>
                                        <div className="col-md-8 d-flex align-items-center">
                                            <label>{value}<span className="errorText">*</span></label>
                                        </div>
                                        { key === "SQ3" ?
                                            <div className="col-md-4 otherFormMarginBottom">
                                                <input type="text" className="form-control" name={key} id="sq" ref={register({required: true})} />
                                                {_.get(key + ".type", errors) === "required" && (
                                                <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                                )}
                                            </div>: ""}
                                        {key === 'SQ4' ?
                                            <div className="col-md-4 otherFormMarginBottom">
                                                <input type="text" className="form-control" maxLength={4} name={key} id="sq" ref={register({required: true, pattern:numberValidation})} />
                                                {_.get(key + ".type", errors) === "required" && (
                                                <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                                )}
                                                {_.get(key + ".type", errors) === "pattern" && (
                                                <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                                )}
                                        </div>: ""}     
                                        {key === ('SQ1') || key === ('SQ2') || key === ('SQ5') || key === ('SQ6')?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" name={key} id="sq" ref={register({required: true, pattern: securityQuestionRegex})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                            {_.get(key + ".type", errors) === "pattern" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                            )}
                                        </div>: ""}                                                                               
                                     </div>
                                    } 
                                </div>
                            )
                        }
                        )} 
                        </div>
                        : "" }
                        </div>    
                        <div className="buttonAlign">
                            {isVerifyDetails ?
                                <button type="button" disabled={!formState.isValid || !(verifyOTPFlagForgotEmail || verifyOTPFlagForgotMobile)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })}>{otpDisableForMobile}{RegistrationJson.SAVE}</button>
                                :
                                <button type="button" disabled={!formState.isValid} className="btn btn-primary" onClick={handleSubmit((e) => { verifyDetails(e) })} >{RegistrationJson.VERIFY}</button>
                            }
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-secondary" onClick={modalCancel} >{RegistrationJson.CANCEL}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ForgotEmailandMobile;