/* eslint-disable */
import React, { useState, useEffect} from "react";
import common from "../labels/common_en.json";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import { verificationOfOTP, cancelApplication, cancelApplicationOTP} from "../actions/myAccountAction";
import "../../styles/profile.css";
import "../../styles/advertisementTable.css";
import Loader from '../../common/components/Loader';

const CancelApplicationModal = ({showModal, setShowModal, myapplication }) => {
    const { register, watch, errors, formState, setError} = useForm({
        mode: 'onChange',
    });
    const dispatch = useDispatch();
    const {  profileUpdateEmailAndMobileOtpResponse, verifyEmailOtpFlag,verifyMobileOtpFlag} = useSelector(state => state.myaccount, shallowEqual);
    const [reason, setReason] = useState("");
    const [showReasonModal , setshowReasonModal] = useState(false);
    const session = JSON.parse(sessionStorage.getItem("session"));
    const emailId = session && session.emailId;
    const mobileNumber = session && session.mobileNumber;
    const [hasMobile, setHasMobile] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [showLoaderFlg, setShowLoaderFlg] = useState(true);

    useEffect(() => {
        if ((profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length > 0) || (profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 0)) {
          setShowLoaderFlg(false);
        } else {
          setShowLoaderFlg(true);
        }
      }, [profileUpdateEmailAndMobileOtpResponse]);

    useEffect(() => {
        const types = profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.map(item => item.type);
        setHasMobile(types.includes("mobile"));
        setHasEmail(types.includes("email"));
      }, [profileUpdateEmailAndMobileOtpResponse]);

    function getMobileMask (mobileno){
        if(mobileno){
        const mobilenumber = mobileno.toString();
        const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(3,8), 'XXXXXX')
        return maskMobileNumber
        }
    }

    const verificationEmailOTP =  (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele,key)=>{
            if(ele.type===type){
                otpid =ele.id;
                otptype = ele.type
            }
        })
        if( watch("OTP_email") && otptype==='email'){
          reqBody = {
            "username": emailId,
            "otpId": otpid, 
            "otpType": otptype,
            "enteredOtp": watch("OTP_email")
          }
         } else{
            setError("OTP_email", {type: "required"});
          }
           dispatch(verificationOfOTP(reqBody,'email'))
      }

      const verificationMobileOTP =  (type) => {
        let reqBody;
        let otpid;
        let otptype;
            profileUpdateEmailAndMobileOtpResponse.map((ele,key)=>{
                if(ele.type===type){
                    otpid =ele.id;
                    otptype = ele.type
                }
            })
          if( watch("OTP_mobile")&& otptype==='mobile'){
             reqBody = {
              "username": emailId,
              "otpId": otpid, 
              "otpType": otptype,
              "enteredOtp": watch("OTP_mobile")
            }
          } else{
            setError("OTP_mobile", {type: "required"});
          }
           dispatch(verificationOfOTP(reqBody,'mobile'))
      }

    const handleReasonChange = (e) => {
        setReason(e.target.value)
    }  

    const modalConfirm = (myapplication) => {
        const reqbody = {
            "isDeleted":true,
            "reasonForCancellation" : reason
        }
        let applicationId = myapplication.id;
        dispatch(cancelApplication(applicationId , reqbody))
        setShowModal(false);
        setshowReasonModal(false)
        setHasMobile(false);
        setHasEmail(false);
        setShowLoaderFlg(true);
    };

    const handleResonModal = () => {
        setshowReasonModal(true);
        setShowLoaderFlg(true);
        dispatch(cancelApplicationOTP());
    }

return (
        <form  className="CancelApplicationForm mt50">
            {((showLoaderFlg) && (showReasonModal)) ?
                <Loader showFlg={true}/>
                :
                <div>
                    <Modal show={showModal} onHide={() => { setShowModal(false);setshowReasonModal(false); setHasMobile(false); setHasEmail(false)}} backdrop='static'>
                        <Modal.Header closeButton className="custom-modal-header">
                            <Modal.Title>{common.CANCEL_APPLICATION}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!myapplication.paymentRefNo && myapplication.paymentRefNo == null && myapplication.paymentRefNo !="" ?
                                <label>{common.CANCEL_APPLICAION_UnPaid_MESSAGE}</label> :
                                <label>
                                    {common.CANCEL_APPLICATION_NOTE}<br/><br/>
                                    {common.CANCEL_APPLICAION_UnPaid_MESSAGE}
                                </label>
                            }
                        { showReasonModal ? 
                                <>
                            <div className="word-break">
                                <label>{common.REASON_FOR_CANCELLATION}<span className="text-danger"> *</span></label>
                                <textarea  type="text"  maxLength={250} className="form-control" name="reason" onChange={handleReasonChange} row="6" 
                                ref={register({ required: true })} ></textarea> 
                                {_.get("reason.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.FIELD_REQ}</p>
                                )}                          
                            </div>
                            {hasEmail &&
                            <div className="form-group">
                                    <label className="LeftText">{common.Email_OTP} {'('}{emailId? emailId.replace(emailId.substring(2,12), 'XXXXXX'):"" }{')'}<span className="text-danger"> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2"  disabled={verifyEmailOtpFlag}/>
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={()=>{verificationEmailOTP('email')}} disabled={verifyEmailOtpFlag}>{common.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("OTP_email.type", errors) === "required" && (
                                        <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                    )}
                            </div>}
                            {hasMobile && 
                                <div className="form-group">
                                    <label className="LeftText">{common.Mobile_OTP} {getMobileMask('('+mobileNumber+')')}<span className="text-danger"> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" maxLength={6} name="OTP_mobile" ref={register({ required: true })} aria-label="mobileotp" aria-describedby="button-addon2" disabled={verifyMobileOtpFlag} />
                                        <div className="input-group-text">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={()=>{verificationMobileOTP('mobile')}} disabled={verifyMobileOtpFlag}>{common.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("OTP_mobile.type", errors) === "required" && (
                                        <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                    )}
                                </div>}                         
                                </>
                                : " "
                        }         
                        </Modal.Body>
                        <Modal.Footer>
                            { showReasonModal ?  
                                <>
                                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false);setshowReasonModal(false) }}>{common.CANCEL}</button>
                                {/* <button type="button" className="btn btn-primary" disabled={
                                    !(verifyMobileOtpFlag && verifyEmailOtpFlag && formState.isValid)} onClick={() => modalConfirm(myapplication)} >{common.CONFIRM}</button>                                          
                                </> */}
                                {(profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 0) ? 
                                    <button type="button" className="btn btn-primary" disabled={
                                        !(reason)} onClick={() => modalConfirm(myapplication)} >{common.CONFIRM}
                                    </button>  
                                    :
                                    (profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 1 && profileUpdateEmailAndMobileOtpResponse[0].type == 'email') ?
                                        <button type="button" className="btn btn-primary" disabled={
                                            !(verifyEmailOtpFlag && formState.isValid)} onClick={() => modalConfirm(myapplication)} >{common.CONFIRM}
                                        </button>
                                    :
                                    (profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 1 && profileUpdateEmailAndMobileOtpResponse[0].type == 'mobile') ?
                                        <button type="button" className="btn btn-primary" disabled={
                                            !(verifyMobileOtpFlag && formState.isValid)} onClick={() => modalConfirm(myapplication)} >{common.CONFIRM}
                                        </button>      
                                    :
                                    <button type="button" className="btn btn-primary" disabled={
                                        !((verifyMobileOtpFlag && verifyEmailOtpFlag) && formState.isValid)} onClick={() => modalConfirm(myapplication)} >{common.CONFIRM}
                                    </button>  
                                }                                   
                                </>
                                    : 
                                <>
                                <button type="button" className="btn btn-secondary" onClick={() => { setShowModal(false);setshowReasonModal(false) }}>{common.NO}</button>                    
                                <button type="button" className="btn btn-primary" onClick={handleResonModal} >{common.YES}</button> 
                                </>
                            }
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </form>
)
}

export default CancelApplicationModal;