/* eslint-disable */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "../../styles/advertisement.css";
import profileadressinformation from "../../candidate_profile_creation/labels/adress_information_en.json";
import profileDetailsLabel from "../../candidate_profile_creation/labels/personal_information_en.json";
import otherinformationLabel from "../../candidate_profile_creation/labels/other_information_en.json";
import qualificationinformation from "../../candidate_profile_creation/labels/qualification_information_en.json";
import personalInformation from "../../candidate_profile_creation/labels/profile_en.json";
import experienceinformation from "../../candidate_profile_creation/labels/experience_information_en.json";
import sportsInformationLable from "../../candidate_profile_creation/labels/sports_details_en.json"
import dayjs from "dayjs";
import { getDropdownOptionLabel, nameOfIndexingBodies, articleType, flgPublicationFirstAuthor, authorType} from "../../helpers/LookupOptionHelper";
import { BOOLEAN_OPTION, FULLTIME_OPTION, AUTHOR_TYPES} from '../../common/constants/dropdownOptions';
import { fromUTCDate, dateDiffereceExServiceMan } from "../../helpers/Datetime";
import ModuleHide from "../../common/components/ModuleHide";
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/advertisementTable.css";

function getLookupLabel(lookupId, profileCreationAllLookupData) {
    const lookupOption = profileCreationAllLookupData && profileCreationAllLookupData.find(
        (lookupOption) => lookupOption.id == lookupId
    );
    if (!lookupOption) return lookupId;
    return lookupOption.label;
}

function getStateName(statecode, stateList) {
    const state = stateList && stateList.find((state) => state.statecode == statecode);
    if (!state) return statecode;
    return state.statename;
}

function getDistrict(districtcode, districtList) {
    const district = districtList && districtList.find(
        (district) => district.districtcode == districtcode
    );
    if (!district) return districtcode;
    return district.districtname;
}
function getTaluka(talukacode, talukaList) {
    const taluka = talukaList && talukaList.find(
        (taluka) => taluka.subdistrictcode == talukacode
    );
    if (!taluka) return talukacode;
    return taluka.subdistrictname;
}
function getVillage(villagecode, villageList) {
    const village = villageList && villageList.find(
        (village) => village.villagecode == villagecode
    );
    if (!village) return villagecode;
    return village.villagename;
}

function getQualificationType(qualificationTypecode, qualificationtypeList) {
    const qualification =
        qualificationtypeList &&
        qualificationtypeList.find(
            (qualification) =>
                qualification.qualificationtypecode == qualificationTypecode
        );

    if (!qualification) return qualificationTypecode;
    return qualification.qualificationtypename;
}

function getUniversity(universitycode, universityname) {
    const university = universityname && universityname.find(
        (university) => university.universityCode == universitycode
    );
    if (!university) return universitycode;
    return university.universityName;
}

function getSubject(subjectid, subjectname) {
    const subject =
        subjectname &&
        subjectname.find((subject) => subject.subjectId == subjectid);

    if (!subject) return "";
    return subject.subjectName
}
function getMobileMask(mobileno) {
    if (mobileno) {
        const mobilenumber = mobileno.toString();
        const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(2, 8), 'XXXXXX')
        return maskMobileNumber
    }
}
function getExServiceManDivision(exServiceManDivision) {
    let exServiceDivision
    if (exServiceManDivision == 'Air_Force') {
        exServiceDivision = "Air Force"
    } else if (exServiceManDivision == 'Gallantry_Award_winners') {
        exServiceDivision = "Gallantry Award winners of Territorial Army"
    }
    else {
        exServiceDivision = exServiceManDivision
    }
    return exServiceDivision
}
const getEligibility = (eligibility) => {
    let list = []
    eligibility.forEach((ele, index) => {
        if (ele && index === 0) {
            list.push('A')
        }
        if (ele && index === 1) {
            list.push('B')
        }
        if (ele && index === 2) {
            list.push('C')
        }
        if (ele && index === 3) {
            list.push('D')
        }
    })
    return list.toString()
}

function getQualificationNameCode(qualificationnamecode, qualificationnameList) {
    const qualification = qualificationnameList && qualificationnameList.find((qualification) =>
        qualification.qualificationNameCode == qualificationnamecode
    );
    if (!qualification) return qualificationnamecode;
    return qualification.qualificationName;
}
const ProfileDetails = () => {
    const { profileCreationAllLookupData, qualificationTypes, nameOfIndexingBodiesList, getPublicationArticleTypeList} = useSelector((state) => state.profile, shallowEqual);

    const { profileInfo } = useSelector(state => state.advertisement, shallowEqual);    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const [arraymModule , setarraymModule] = useState({})
    let [showPublicationTable, setShowPublicationTable] = useState(false);
    let count = 0;
    let publilcationTableCount = 0;

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "VIEW_PROFILE")[0])
    },[moduleList])

    useEffect(() => {    
      if (profileInfo && profileInfo.qualificationInfoList) {
        const hasPbQualification = profileInfo && profileInfo.qualificationInfoList.some(item => item.qualificationtypecode === "Pb");
        setShowPublicationTable(hasPbQualification)
      }
      }, [profileInfo]);

    if (profileInfo == null) {
        return <div>{profileDetailsLabel.PROFILE_NOT_LOADED}</div>;
    }
    const {
        requiredStates,
        requiredQualificationNameCode,
        requiredQualificationTypeCodes,
        requiredQualificationUniversityCodes,
        requiredSubject,
        requiredTaluka,
        requiredVillage,
        requiredDistricts,
    } = profileInfo;

    if (!profileInfo.personalInfoDto && profileInfo.personalInfo) {
        profileInfo.personalInfoDto = profileInfo.personalInfo;
    }
    const handleFromToDateSelection = (fromdate, todate) => {
        let expYears;
        let expMonths;
        let expDays;
        let difference = dateDiffereceExServiceMan(fromdate, todate);
        expYears = difference.years;
        expMonths = difference.months;
        expDays = difference.days;
        return "Years" + " " + expYears + ", " + "Months   " + " " + expMonths + ", " + "Days" + " " + expDays;
    }
    const handleFromToDateSelectionForExp = (fromdate, todate) => {
        let expYears;
        let expMonths;
        let expDays;
        let difference = dateDiffereceExServiceMan(fromdate, todate);
        expYears = difference.years;
        expMonths = difference.months;
        expDays = difference.days;
        return {
            expYears: expYears,
            expMonths : expMonths,
            expDays : expDays
        } 
    }

    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    (
        <div> 
            <div id="profileDetails-container">
                <div className="advertisement_details_Eligibility border border-light">
                    <div className="card">
                        <div className="card-header">
                            <span>
                                {profileDetailsLabel.SECOUND_PROFILE_HEADER}
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-5 pad_0">
                                    <div className="content-row">
                                        <h6>
                                            {profileDetailsLabel.FULLNAME_ENGLISH}
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.personalInfoDto
                                                    .englishFullName
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="content-row">
                                        <h6>
                                            {profileDetailsLabel.FULLNAME_DEVNAGARI}
                                        </h6>
                                        <span>
                                            {
                                                profileInfo.personalInfoDto
                                                    .devnagariFullName
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="col-md-2">
                                    <img
                                        className="img-profile"
                                        src={`data:image/png;base64,${profileInfo.photo}`}
                                        width="130" height="auto" />
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-5 pad_0">
                                            <div className="content-row">
                                                <h6 >{profileDetailsLabel.SURNAME}</h6>
                                                <span className="word-break mt-n1">
                                                    {profileInfo.personalInfoDto.surname}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.DATE_OF_BIRTH}</h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.dob ?
                                                    dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.dob))).format(commonLabels.DATE_FORMAT)
                                                    : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.MOTHER_TONGUE}</h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .motherTongueLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.FIRST_NAME}</h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.firstName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            <div className="content-row">
                                                <h6> {profileDetailsLabel.Email_ID} </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.emailId ? profileInfo.personalInfoDto.emailId.replace(profileInfo.personalInfoDto.emailId.substring(2, 12), 'XXXXXX') : ""}

                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pad_0">
                                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.motherTongueLookupId == '356' ?
                                                <div className="content-row">
                                                    <h6>{profileDetailsLabel.OTHER_LANGUAGES}</h6>
                                                    <span className="word-break">
                                                        {profileInfo.personalInfoDto.otherLanguages}
                                                    </span>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.MIDDLE_NAME}</h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.middleName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.MOBILE_NO}</h6>
                                                <span >
                                                    {getMobileMask(
                                                        profileInfo.personalInfoDto.mobileNumber
                                                    )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pad_0">
                                            <div className="content-row">
                                                <h6>{profileDetailsLabel.READ_MARATHI}</h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .flgMarathiRead
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pad_0">
                                            <div className="content-row">
                                                <h6>

                                                    {profileDetailsLabel.MOTHERS_NAME}
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.motherName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            <div className="content-row">
                                                <h6> {profileDetailsLabel.NATIONALITY} </h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .nationalityLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pad_0">
                                            <div className="content-row">
                                                <h6>

                                                    {profileDetailsLabel.SPEAK_MARATHI}
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .flgMarathiSpeak
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pad_0">
                                            <hr className="mar_top_bottom_8" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 pad_0">
                                            <div className="content-row">
                                                <h6> {profileDetailsLabel.GENDER} </h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .genderLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            <div className="content-row">
                                                <h6>
                                                    {profileDetailsLabel.WHETHER_MARRIED}
                                                </h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .maritalStatusLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 pad_0">
                                            <div className="content-row">
                                                <h6>

                                                    {profileDetailsLabel.WRITE_MARATHI}
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .flgMarathiWrite
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(Number(profileInfo.personalInfoDto.genderLookupId) === 322) ?
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="col-md-10 pad_0">
                                        <hr className="mar_top_bottom_8" />
                                        <div className="content-row">
                                            <h6>{profileDetailsLabel.FLG_TRANSGENDER_FEMALE_RADIO_BTN_LABEL}</h6>
                                            <span>
                                                {profileInfo.personalInfoDto.flgTransgenderFemale ? "Yes" : "No"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            <div className="row">
                                <div className="col-md-2"></div>
                                {/* <hr className="mar_top_bottom_8" /> */}
                                <div className="col-md-10 pad_0">
                                    <hr className="mar_top_bottom_8" />
                                    <div className="content-row">
                                        <h6 className="d-inline">{profileDetailsLabel.NAME_CHANGE_OR_UPDATE}</h6>
                                        <span>
                                            {profileInfo.personalInfoDto.flgNameChanged ? "Yes" : "No"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {profileInfo.personalInfoDto.flgNameChanged ?
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    {/* <hr className="mar_top_bottom_8" /> */}
                                    <div className="col-md-10 pad_0">
                                        <hr className="mar_top_bottom_8" />
                                        <div className="content-row">
                                            <h6> {profileDetailsLabel.CHANGE_OR_UPDATED_FULL_NAME_IN_ENGLISH}</h6>
                                            <span className="word-break">
                                                {profileInfo.personalInfoDto.changedEnglishName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                : ""}

                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.maritalStatusLookupId == '357' || profileInfo.personalInfoDto.maritalStatusLookupId == '359' ?
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="col-md-10 ps-0">
                                        <hr className="mar_top_bottom_8" />
                                        <div className="content-row">
                                            <h6 className="d-inline">{profileDetailsLabel.CANDIDATE_LIABLE}</h6>
                                            <span>
                                                {profileInfo.personalInfoDto.flgCandidateLiable ? "Yes" : "No"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }
                        </div>
                    </div>
                    <br />
                    {/* Adhar Details */}
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.FIRST_PROFILE_HEADER} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>{profileDetailsLabel.Are_YOU_HOLDING_AADHAR_CARD}</h6>
                                        <span>
                                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.aadhaarNo ?
                                                "Yes" : "No"}
                                        </span>
                                    </div>
                                    {profileInfo.personalInfoDto && profileInfo.personalInfoDto.aadhaarNo ?
                                        <div className="content-row clear-fix">
                                            <h6>{profileDetailsLabel.NAME_IN_ENGLISH}</h6>
                                            <span>
                                                {profileInfo.personalInfoDto.aadhaarNameInEnglish}
                                            </span>
                                        </div> : ""}
                                </div>
                                {profileInfo.personalInfoDto && profileInfo.personalInfoDto.aadhaarNo ?
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>{profileDetailsLabel.AADHAR_NO}</h6>
                                            <span>
                                                {profileInfo.personalInfoDto.aadhaarNo ? profileInfo.personalInfoDto.aadhaarNo.replace(profileInfo.personalInfoDto.aadhaarNo.substring(0, 8), 'XXXXXX') : ""}
                                            </span>
                                        </div>
                                    </div> : ""}
                            </div>
                        </div>
                    </div>
                    <br />
                    {/* physical mesurement */}
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.PHYSICAL_MESUREMENT} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="content-row clear-fix">
                                        <h6>{profileDetailsLabel.HEIGHT}</h6>
                                        <span>
                                            {profileInfo.personalInfoDto.height}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="content-row clear-fix">
                                        <h6>{profileDetailsLabel.WEIGHT}</h6>
                                        <span>
                                            {profileInfo.personalInfoDto.weight}
                                        </span>
                                    </div>
                                </div>
                                {profileInfo.personalInfoDto.chestDeflated ?
                                    <div className="col-md-3">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {profileDetailsLabel.CHEST_DEFLATED}
                                            </h6>
                                            <span>
                                                {
                                                    profileInfo.personalInfoDto
                                                        .chestDeflated
                                                }
                                            </span>
                                        </div>
                                    </div> : ""
                                }
                                {profileInfo.personalInfoDto.chestInflated ?
                                    <div className="col-md-3">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {profileDetailsLabel.CHEST_INFLATED}
                                            </h6>
                                            <span>
                                                {
                                                    profileInfo.personalInfoDto
                                                        .chestInflated
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <br />
                    {/* caste details */}
                    <div className="card">
                        <div className="card-header">
                            <span>
                                {profileDetailsLabel.CASTE_CATEGORY_DETAILS}
                            </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="content-row clear-fix">
                                        <h6>{profileDetailsLabel.CATEGORY}</h6>
                                        <span>
                                            {getLookupLabel(
                                                profileInfo.personalInfoDto
                                                    .categoryLookupId,
                                                profileCreationAllLookupData
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {profileInfo.personalInfoDto.categoryLookupId != "323" ?
                                    <>
                                        <div className="col-md-4 pad_0">
                                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.categoryLookupId  !='332'?
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.CASTE_CERTIFICATE_NUMBER
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .castCertificateNo
                                                    }
                                                </span>
                                            </div>:""}
                                        {profileInfo.personalInfoDto && profileInfo.personalInfoDto.categoryLookupId  =='332'&& profileInfo.personalInfoDto.ewsCertificateNumber ?
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.EWS_CASTE_CERTIFICATE_NUMBER
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .ewsCertificateNumber
                                                    }
                                                </span>
                                            </div>:""
                                        }
                                        </div>
                                        <div className="col-md-4">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {profileDetailsLabel.ISSUING_DISTRICT}
                                                </h6>
                                                <span>
                                                    {getDistrict(
                                                        profileInfo.personalInfoDto.ccIssuingDistrictLookupId,
                                                        requiredDistricts
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                    : ""
                                }
                            </div>
                            {profileInfo.personalInfoDto.categoryLookupId != "323" ?
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-8 pad_0">
                                        <hr className="mt-1 mb-0" />
                                        <div className="content-row clear-fix">
                                            <h6>{profileDetailsLabel.ISSUING_DATE}</h6>
                                            <span>
                                                {profileInfo.personalInfoDto
                                                    .castCertiIssuingDate
                                                    ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.castCertiIssuingDate))).format(commonLabels.DATE_FORMAT)
                                                    : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {profileInfo.personalInfoDto.categoryLookupId != "332" && profileInfo.personalInfoDto.categoryLookupId != "324" && profileInfo.personalInfoDto.categoryLookupId != "325" ?
                                <hr className="mar_top_bottom_8" />
                                : ""}
                            {profileInfo.personalInfoDto.categoryLookupId != "332" && profileInfo.personalInfoDto.categoryLookupId != "324" && profileInfo.personalInfoDto.categoryLookupId != "325" ?
                                <>
                                    <div className="row">
                                        <div className="col-md-4">
                                            {profileInfo.personalInfoDto.categoryLookupId !=323 || profileInfo.personalInfoDto.flgTransgenderFemale || (profileInfo.personalInfoDto.genderLookupId !=320 && profileInfo.personalInfoDto.genderLookupId !=322)?
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.DO_YOU_BELONG_TO_NON_CREAMY_LAYER
                                                    }
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.flgNcl
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>:""}
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            {profileInfo.personalInfoDto.nclCertificateNo ?
                                                <div className="content-row clear-fix">
                                                    <h6>{profileDetailsLabel.NCL_CERT_NO}</h6>
                                                    <span>
                                                        {
                                                            profileInfo.personalInfoDto
                                                                .nclCertificateNo
                                                        }
                                                    </span>
                                                </div> : ""}
                                        </div>
                                        <div className="col-md-4">
                                            {profileInfo.personalInfoDto.latestNclIssueDate ?
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {profileDetailsLabel.ISSUING_DATE_NCL}
                                                    </h6>
                                                    <span>
                                                        {profileInfo.personalInfoDto
                                                            .latestNclIssueDate
                                                            ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.latestNclIssueDate))).format(commonLabels.DATE_FORMAT)
                                                            : ""}
                                                    </span>
                                                </div> : ""}
                                        </div>
                                    </div>
                                    {/* {!profileInfo.personalInfoDto.flgNcl ?
                                        <div>
                                            {!profileInfo.personalInfoDto.flgNcl && profileInfo.personalInfoDto.genderLookupId ==321 ?
                                                <div className="content-row clear-fix pad_0">
                                                    <hr className="mar_top_bottom_8" />
                                                    &nbsp;&nbsp;<span className="errorText">* {profileDetailsLabel.NOT_CONSIDER_FOR_FEMALE_RESERVATION}</span>
                                                </div> : ""
                                            }
                                            {!profileInfo.personalInfoDto.flgNcl && profileInfo.personalInfoDto.genderLookupId ==322 && profileInfo.personalInfoDto.flgTransgenderFemale ?
                                                <div className="content-row clear-fix pad_0">
                                                     <hr className="mar_top_bottom_8" />
                                                    &nbsp;&nbsp;<span className="errorText">* {profileDetailsLabel.NOT_CONSIDER_FOR_FEMALE_RESERVATION}</span>
                                                </div> : ""
                                            }
                                            {profileInfo.personalInfoDto.categoryLookupId !=323 || profileInfo.personalInfoDto.flgTransgenderFemale || (profileInfo.personalInfoDto.genderLookupId !=320 && profileInfo.personalInfoDto.genderLookupId !=322)?
                                            <div className="content-row clear-fix">
                                                &nbsp;&nbsp;<span className="errorText text-break">{profileDetailsLabel.NON_CREAMY_LAYER_CERT_NOTE}</span>
                                            </div>:""}
                                        </div> : ""} */}

                                </>
                                : ""}
                            {profileInfo.personalInfoDto.categoryLookupId != "323" && profileInfo.personalInfoDto.categoryLookupId != "332" ?
                                <hr className="mar_top_bottom_8" />
                                : ""}
                            {profileInfo.personalInfoDto.categoryLookupId != "323" && profileInfo.personalInfoDto.categoryLookupId != "332" ?
                                <>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.DO_YOU_HAVE_CASTE_VALIDITY_CERTIFICATE
                                                    }
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .flgCastValidityCert
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 pad_0">
                                            {profileInfo.personalInfoDto.castValidityCertNo ?
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.CASTE_VALIDITY_CERTIFICATE_NO
                                                        }
                                                    </h6>
                                                    <span>
                                                        {
                                                            profileInfo.personalInfoDto
                                                                .castValidityCertNo
                                                        }
                                                    </span>
                                                </div> : ""}
                                        </div>
                                        <div className="col-md-4">
                                            {profileInfo.personalInfoDto.castValidityCertIssueDate ?
                                                <div className="content-row clear-fix">
                                                    <h6>{profileDetailsLabel.ISSUING_DATE}</h6>
                                                    <span>
                                                        {profileInfo.personalInfoDto
                                                            .castValidityCertIssueDate
                                                            ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.castValidityCertIssueDate))).format(commonLabels.DATE_FORMAT)
                                                            : ""}
                                                    </span>
                                                </div> : ""}
                                        </div>
                                    </div>
                                </>
                                : ""}
                        </div>

                    </div>
                    <br />
                    {/* Divyang details */}
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.Divyang_Details} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {profileDetailsLabel.ARE_YOU_DIVYANGJAN}
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto.flgDivyang
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang ?
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {profileDetailsLabel.DIVYANG_PERCENTAGE}
                                            </h6>
                                            <span>
                                                {
                                                    profileInfo.personalInfoDto
                                                        .divyangPercentage
                                                }
                                            </span>
                                        </div>
                                    </div> : ""}
                            </div>

                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.CATEGORY_OF_DISABILITIES
                                                    }
                                                </h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .typeOfDisabilityLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.DATE_OF_ISSUE
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto.sadmCertificateDate ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.sadmCertificateDate))).format(commonLabels.DATE_FORMAT) : ""
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.DISABILITIES_SUB_CATEGORIES
                                                    }
                                                </h6>
                                                {profileInfo.personalInfoDto && profileInfo.personalInfoDto.disabilitySubCategoriesLookupIds.map((ele, index) => {
                                                    if (ele) {
                                                        return (

                                                            <span>

                                                                {getLookupLabel(ele, profileCreationAllLookupData)}
                                                                {profileInfo.personalInfoDto && profileInfo.personalInfoDto.disabilitySubCategoriesLookupIds.length === index + 1 ?
                                                                    <span></span> : <span>,&nbsp;&nbsp;</span>}
                                                            </span>
                                                        )
                                                    }
                                                })

                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                : ""}
                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.TYPE_OF_DISABILITY_CERTIFICATE
                                                    }
                                                </h6>
                                                {profileInfo.personalInfoDto.typeDisabilityCertificate === 1 ?
                                                    <span>{profileDetailsLabel.SADM}</span>
                                                    : profileInfo.personalInfoDto.typeDisabilityCertificate === 2 ?
                                                        <span>{profileDetailsLabel.SWAVALAMBAN}</span>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.DISABILITY_CERTIFICATE_NUMBER
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto.sadmCertificateNumber
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                                {((profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang) && ((profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.udid) || (profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.udidEnrolmentNo))) ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {profileDetailsLabel.DO_YOU_POSSES_UNIQUE_DISABILITY_ID}
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto.udid
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </div>
                                        </div>
                                        {(profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.udid) &&
                                            <div className="col-md-6">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.UDID_NUMBER
                                                        }
                                                    </h6>
                                                    <span>
                                                        {
                                                            profileInfo.personalInfoDto.udid
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        {(profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.udidEnrolmentNo) &&
                                            <div className="col-md-6">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.UDID_ENROLLMENT_NUMBER
                                                        }
                                                    </h6>
                                                    <span>
                                                        {
                                                            profileInfo.personalInfoDto.udidEnrolmentNo
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <br />
                    {/* sports details */}
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.Sports_Details} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="col-md-4">
                                <div className="content-row clear-fix">
                                    <h6>
                                        {otherinformationLabel["ARE_YOU _MERITORIOUS_SPORTS_PERSON?"]}
                                    </h6>
                                    <span>
                                        {profileInfo.personalInfoDto
                                            .flgMetritoriousSportsman
                                            ? "Yes"
                                            : "No"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {profileInfo.personalInfoDto.flgMetritoriousSportsman ?
                            <div className="qualification-table qualificationTableCss">
                                <table className="table table-bordered" >
                                    <thead>
                                        <tr>
                                            {sportsInformationLable.sportsHeaderContent && sportsInformationLable.sportsHeaderContent.slice(0, -2).map((ele, index) =>
                                                <th scope="col" key={index} className="theadBgColor">{ele}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="f12">
                                        {profileInfo.personalInfoDto && profileInfo.personalInfoDto.meritoriousSportsmanDtoList.map((ele, index) => {
                                            if (ele) {
                                                return (
                                                    <tr key={index}>
                                                        <td className="w-10 word-break text-center">{index + 1}</td>
                                                        <td className="word-break">{getLookupLabel(ele.typeOfCompetitionLookupId, profileCreationAllLookupData)}</td>
                                                        <td className="word-break">{getLookupLabel(ele.nameOfCompetitionLookupId, profileCreationAllLookupData)}</td>
                                                        <td className="word-break">{getLookupLabel(ele.positionMedalLookupId, profileCreationAllLookupData)}</td>
                                                        <td className="word-break">{ele.tournamentStartDate ? dayjs(fromUTCDate(new Date(ele.tournamentStartDate))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                        <td className="word-break">{ele.tournamentEndDate ? dayjs(fromUTCDate(new Date(ele.tournamentEndDate))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                        <td className="word-break">{ele.certificateVerificationStatus === true ? "Yes" : "No"}</td>
                                                        <td className="word-break">{getLookupLabel(ele.sportsCertiVerificationCityLookupId, profileCreationAllLookupData)}</td>
                                                        <td className="word-break">{ele.sportsCertiReportNo}</td>
                                                        <td className="word-break">{ele.dateOfIssue ? dayjs(fromUTCDate(new Date(ele.dateOfIssue))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                        <td className="word-break">{ele.acknowledgementNumber}</td>
                                                        <td className="word-break">{ele.dateOfSubmission ? dayjs(fromUTCDate(new Date(ele.dateOfSubmission))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                        <td className="word-break">{getEligibility(ele.eligibleForGroup)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div> : ""}
                    </div>
                    <br />
                    {/* ex-serviceman details */}
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.Ex_service_Man} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                otherinformationLabel.DOES_EX_SERVICEMAN_OF_THE_REGULAR
                                            }
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto
                                                .flgExArmedReg
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                {profileInfo.personalInfoDto.flgExArmedReg ?
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    otherinformationLabel.DIVISION_OF_ARMED_FORCES
                                                }
                                            </h6>

                                            <span>
                                                {getExServiceManDivision(
                                                    profileInfo.personalInfoDto.exServiceManDivision
                                                )}
                                            </span>
                                        </div>
                                    </div> : ""}
                            </div>
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.DATE_OF_APPOINTMENT_IN_REGULAR_ARMED_FORCES
                                                    }
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .dateApptArmedRegular
                                                        ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.dateApptArmedRegular))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel[
                                                        "DATE_OF_RETIREMEN_(DISCHARGE)_IN_REGULAR_ARMED_FORCES"
                                                        ]
                                                    }
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .dateRetireArmedRegular
                                                        ?
                                                        dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.dateRetireArmedRegular)))
                                                        .format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.PERIOD_OF_SERVICE
                                                    }
                                                </h6>
                                                <span>
                                                    {handleFromToDateSelection(profileInfo.personalInfoDto
                                                        .dateApptArmedRegular, profileInfo.personalInfoDto
                                                        .dateRetireArmedRegular)
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.DISCHARGE_CERTIFICATE_NUMBER
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .dischargeCertificateNo
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.DATE_OF_DISCHARGE_FROM_ARMED_FORCES
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto.dateDischargeArmedRegular ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.dateDischargeArmedRegular))).format(commonLabels.DATE_FORMAT) : ""
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.THE_SERIAL_NUMBER_AUTHORITY_FOR_AGE_RELAXATION
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .ageRelaxCertiNo
                                                    }
                                                </span>
                                                <span className="float-end pe-5"> {profileDetailsLabel.DATE} &nbsp;
                                        {profileInfo.personalInfoDto
                                                        .ageRelaxCertiNo1
                                                        ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.ageRelaxCertiNo1))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.ARE_YOU_DISABILITY_PENSION_HOLDER
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .flgDisabilityPensionHolder ?
                                                            "Yes" : "No"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""}
                            {profileInfo.personalInfoDto.flgExArmedReg ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel.HAVE_YOU_JOIN_STATE_GOVT
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .flgJoinedStateGovt ?
                                                            "Yes" : "No"
                                                    }
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div> : ""}
                        </div>
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <span>
                                {
                                    profileDetailsLabel.Government_of_Maharashtra_Employee_Details
                                }
                            </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileDetailsLabel.ARE_YOU_EMPLOYEE_GOVERMENT
                                            }
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto
                                                .flgGomEmployee
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {profileInfo.personalInfoDto.flgGomEmployee ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    otherinformationLabel.Sevaarth_ID
                                                }
                                            </h6>
                                            <span>
                                                {profileInfo.personalInfoDto
                                                    .sevaarthId
                                                }
                                            </span>
                                        </div> : ""}
                                </div>
                            </div>
                            <hr className="mar_top_bottom_8" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileDetailsLabel.ARE_YOU_EMPLOYEE_BMC
                                            }
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto
                                                .flgBmcEmployee
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileDetailsLabel.ARE_YOU_EMPLOYEE_BEST
                                            }
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto
                                                .flgBestEmployee
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.Domicile_Details} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileDetailsLabel.Maharashtra_Domicile_Certificate
                                            }
                                        </h6>
                                        <span>
                                            {profileInfo.personalInfoDto
                                                .flgDomicile
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    {profileInfo.personalInfoDto.domicileIssuingDistrict ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {profileDetailsLabel.ISSUING_DISTRICT}
                                            </h6>
                                            <span>
                                                {getDistrict(
                                                    profileInfo.personalInfoDto
                                                        .domicileIssuingDistrict,
                                                    requiredDistricts
                                                )}

                                            </span>
                                        </div>
                                        : ""}
                                    {!profileInfo.personalInfoDto.flgDomicile && (profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang || profileInfo.personalInfoDto
                                                .flgExArmedReg) ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {profileDetailsLabel.FLG_BORDER_AREA_RADIO_BTN_LABEL}
                                            </h6>
                                            <span>
                                                {
                                                    profileInfo.personalInfoDto
                                                        .flgMaharashtraKarnatakBorderArea ?
                                                        "Yes" : "No"
                                                }
                                            </span>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                            <hr className="mar_top_bottom_8" />
                            <div className="row">
                                <div className="col-md-6 pe-0">
                                    {profileInfo.personalInfoDto.flgDomicile ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    profileDetailsLabel.DOMICILE_CERTIFICATE_NO
                                                }
                                            </h6>

                                            <span>
                                                {profileInfo.personalInfoDto
                                                    .flgDomicileCertificate ?
                                                    "Available" : "Not Available"
                                                }
                                            </span >
                                        </div>
                                        : ""}
                                    {profileInfo.personalInfoDto.borderDistrictLookupId ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    profileDetailsLabel.BORDER_AREA_DISTRICTS_LABEL
                                                }
                                            </h6>
                                            <span>
                                                {getLookupLabel(
                                                    profileInfo.personalInfoDto
                                                        .borderDistrictLookupId,
                                                    profileCreationAllLookupData
                                                )}
                                            </span>
                                        </div>
                                        : ""}
                                </div>
                                <div className="col-md-6">
                                    {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDomicileCertificate  && profileInfo.personalInfoDto.domicileCertificateNo?
                                        <div className="content-row clear-fix">
                                            <h6>{profileDetailsLabel.CERTIFICATE_NUMBER}</h6>
                                            <span>
                                                {
                                                    profileInfo.personalInfoDto
                                                        .domicileCertificateNo
                                                }
                                            </span>
                                        </div>
                                        : ""}

                                    {profileInfo.personalInfoDto.borderTalukaLookupId ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    profileDetailsLabel.BORDER_AREA_TALUKAS_LABEL
                                                }
                                            </h6>
                                            <span>
                                                {getLookupLabel(
                                                    profileInfo.personalInfoDto
                                                        .borderTalukaLookupId,
                                                    profileCreationAllLookupData
                                                )}
                                            </span>
                                        </div>
                                        : ""}
                                </div>
                            </div>


                            {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDomicile && profileInfo.personalInfoDto.domicileIssuingDate ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    profileDetailsLabel.DOMICILE_ISSUING_DATE
                                                }
                                            </h6>
                                            <span>
                                                {profileInfo.personalInfoDto
                                                    .domicileIssuingDate
                                                    ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.domicileIssuingDate))).format(commonLabels.DATE_FORMAT)
                                                    : ""}
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            : ""}



                            {profileInfo.personalInfoDto.borderVillageLookupId ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">

                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.BORDER_AREA_VILLAGES_LABEL
                                                    }
                                                </h6>
                                                <span>
                                                    {getLookupLabel(
                                                        profileInfo.personalInfoDto
                                                            .borderVillageLookupId,
                                                        profileCreationAllLookupData
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <br />
                </div>
                {profileInfo?.personalInfoDto?.isAdditionalReservation!='' ?
                    <div className="advertisement_details_Eligibility border border-light">
                        <div className="card">
                            <div className="card-header">
                                <span> {profileDetailsLabel.additional_reservation} </span>
                            </div>
                            <div className="card-body pad_top_bottom_8">
                                {profileInfo?.personalInfoDto?.isProjectAffected?
                                <>
                                        <div className="row">
                                                <div className="col-md-12">
                                                <h5>
                                                {
                                                    profileDetailsLabel.project_affected
                                                }
                                                </h5>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.certificate_number
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.certificateNoPa}
                                                    </span>
                                                 </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.issuing_date
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.issuingDatePa? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.issuingDatePa))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                    </span>
                                                 </div>
                                                </div>
                                        </div>
                                    </>
                                    :''
                                }

                                {profileInfo?.personalInfoDto?.isEarthquakeAffected?
                                <>
                                   <hr className="mar_top_bottom_8" />
                                        <div className="row">
                                                <div className="col-md-12">
                                                <h5>
                                                {
                                                    profileDetailsLabel.earthquake_affected
                                                }
                                                </h5>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.certificate_number
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.certificateNoEqa}
                                                    </span>
                                                 </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.issuing_date
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.issuingDateEqa? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.issuingDateEqa))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                    </span>
                                                 </div>
                                                </div>
                                        </div>
                                    </>
                                    :''
                                }
                                {profileInfo?.personalInfoDto?.isGraduateEmployee?
                                <>
                                   <hr className="mar_top_bottom_8" />
                                        <div className="row">
                                                <div className="col-md-12">
                                                <h5>
                                                {
                                                    profileDetailsLabel.graduate_employee
                                                }
                                                </h5>
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.employee_number
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.employmentNoGpte}
                                                    </span>
                                                 </div>
                                                </div>
                                                <div className="col-md-4">
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            profileDetailsLabel.issuing_date
                                                        }
                                                    </h6>
                                                    <span>
                                                        { profileInfo.personalInfoDto.issuingDateGpte? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.issuingDateGpte))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                    </span>
                                                 </div>
                                                </div>
                                        </div>
                                    </>
                                    :''
                                }
                               
                            </div>
                        </div>
                    </div>
                    :<div className="advertisement_details_Eligibility border border-light">
                    <div className="card">
                        <div className="card-header">
                            <span> {profileDetailsLabel.additional_reservation} </span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                    <div className="col-md-12">
                                    {profileDetailsLabel.additional_reservation}?  <strong>{profileDetailsLabel.NO}</strong> 
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                }
                {profileInfo.personalInfoDto ?
                    <div className="advertisement_details_Eligibility border border-light">
                        <div className="card">
                            <div className="card-header">
                                <span> {profileDetailsLabel.Orphan_Detail} </span>
                            </div>
                            <div className="card-body pad_top_bottom_8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    profileDetailsLabel.DO_YOU_WANT_TO_TAKE_ADVANTAGE_FOR_ORPHAN_RESERVATION
                                                }
                                            </h6>
                                            <span>
                                                {profileInfo.personalInfoDto
                                                    .flgOrphanReservation
                                                    ? "Yes"
                                                    : "No"}
                                            </span>
                                        </div>
                                    </div>
                                    {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgOrphanReservation ?
                                    <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileDetailsLabel.ORPHAN_TYPE
                                            }
                                        </h6>
                                        <span>
                                            {
                                                profileInfo.personalInfoDto
                                                    .orphanType
                                            }
                                        </span>
                                    </div>
                                    </div> : ""}
                                </div>
                                <hr className="mar_top_bottom_8" />
                                {profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgOrphanReservation ?
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.ORPHAN_CERTIFICATE_NO
                                                    }
                                                </h6>
                                                <span>
                                                    {
                                                        profileInfo.personalInfoDto
                                                            .orphanReservationCertiNo
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        profileDetailsLabel.ORPHAN_CERTIFICATE_ISSUE_DATE
                                                    }
                                                </h6>
                                                <span>
                                                    {profileInfo.personalInfoDto
                                                        .orphanCertiIssueDate
                                                        ? dayjs(fromUTCDate(new Date(profileInfo.personalInfoDto.orphanCertiIssueDate))).format(commonLabels.DATE_FORMAT)
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                    : ""}
                <div className="advertisement_details_Eligibility border border-light">
                    <label className="fw-bold">
                        {profileadressinformation.ADD_INFO}
                    </label>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <span>

                                        {
                                            profileadressinformation.PERMENANT_ADDRESS
                                        }
                                    </span>
                                </div>
                                <div className="card-body pad_top_bottom_8">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_1
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .permenantHouseNo
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_2
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .permenantBuildingName
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_3
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .permenantRoadOrPo
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.LANDMARK}</h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .permenantLandmark
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.STATE_UNION_TERRITORY
                                            }
                                        </h6>
                                        <span>
                                            {getStateName(
                                                profileInfo.addressInfo
                                                    .permenantStateLookupId,
                                                requiredStates
                                            )}

                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.DISTRICT}</h6>
                                        {profileInfo.addressInfo && profileInfo.addressInfo.permenantDistrictLookupId?
                                        <span className="word-break">
                                            {getDistrict(
                                                profileInfo.addressInfo
                                                    .permenantDistrictLookupId,
                                                requiredDistricts
                                            )}
                                        </span>:
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo.permenantDistrict
                                            }
                                        </span>
                                        }

                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.TALUKA}</h6>
                                        { profileInfo.addressInfo && profileInfo.addressInfo.permenantTalukaLookupId ?
                                        <span className="word-break">
                                            {getTaluka(
                                                profileInfo.addressInfo.
                                                    permenantTalukaLookupId,
                                                requiredTaluka
                                            )}
                                        </span>:
                                         <span className="word-break">
                                            {
                                                profileInfo.addressInfo.permenantTaluka
                                            }
                                        </span>    
                                        }
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {profileadressinformation.VILLAGE_CITY}
                                        </h6>
                                        {profileInfo.addressInfo && profileInfo.addressInfo.permenantvillageCityLookupId?
                                        <span className="word-break">
                                            {getVillage(
                                                profileInfo.addressInfo
                                                    .permenantvillageCityLookupId,
                                                requiredVillage
                                            )}
                                        </span>
                                        :
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo.permenantvillageCity
                                            }
                                        </span>
                                        }
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.PINCODE}</h6>
                                        <span>
                                            {profileInfo.addressInfo.permenantPin}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <span>
                                        {
                                            profileadressinformation.CORRESPONDANCE_ADDRESS
                                        }
                                    </span>
                                </div>
                                <div className="card-body pad_top_bottom_8">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_1
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .correspondenceHouseNo
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_2
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .correspondenceBuildingName
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.ADDRESS_LINE_3
                                            }
                                        </h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .correspondenceRoadOrPo
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.LANDMARK}</h6>
                                        <span className="word-break">
                                            {
                                                profileInfo.addressInfo
                                                    .correspondenceLandmark
                                            }
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                profileadressinformation.STATE_UNION_TERRITORY
                                            }
                                        </h6>
                                        <span>
                                            {getStateName(
                                                profileInfo.addressInfo
                                                    .correspondenceStateLookupId,
                                                requiredStates
                                            )}
                                        </span>
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.DISTRICT}</h6>
                                        {profileInfo.addressInfo && profileInfo.addressInfo.correspondenceDistrictLookupId?
                                        <span className="word-break">
                                            {getDistrict(
                                                profileInfo.addressInfo
                                                    .correspondenceDistrictLookupId,
                                                requiredDistricts
                                            )}
                                           </span>:
                                           <span className="word-break">
                                           {profileInfo.addressInfo.correspondenceDistrict}
                                          </span>
                                        }
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.TALUKA}</h6>
                                        {profileInfo.addressInfo && profileInfo.addressInfo.correspondenceTalukaLookupId?
                                        <span className="word-break">
                                            {getTaluka(
                                                profileInfo.addressInfo
                                                    .correspondenceTalukaLookupId,
                                                requiredTaluka
                                            )}
                                        </span>:
                                        <span className="word-break">
                                        {profileInfo.addressInfo.correspondenceTaluka}
                                    </span>
                                        }
                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {profileadressinformation.VILLAGE_CITY}
                                        </h6>
                                        {profileInfo.addressInfo && profileInfo.addressInfo.correspondenceVillageCityLookupId?
                                        <span className="word-break">
                                            {getVillage(
                                                profileInfo.addressInfo
                                                    .correspondenceVillageCityLookupId,
                                                requiredVillage
                                            )}
                                        </span>:
                                        <span className="word-break">
                                        {profileInfo.addressInfo.correspondenceVillage}
                                        </span>
                                        }

                                    </div>
                                    <div className="content-row clear-fix">
                                        <h6>{profileadressinformation.PINCODE}</h6>
                                        <span>
                                            {
                                                profileInfo.addressInfo
                                                    .correspondencePin
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* other information */}

                <div className="advertisement_details_Eligibility border border-light">
                    <label className="fw-bold">
                        {personalInformation.headerField[2].name}
                    </label>

                    <div className="card">
                        <div className="card-header">
                            <span>{profileDetailsLabel.OTHER_DETAILS_INFO_NOTE_1}</span>
                        </div>
                        <div className="card-body pad_top_bottom_8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                otherinformationLabel[
                                                "WHETHER_ANY_PROSECUTION_IS_PENDING_IN_ANY_COURT_OF_LAW_AGAINST_YOU?"
                                                ]
                                            }
                                        </h6>
                                    &ensp;
                                    <span>
                                            {profileInfo.otherInfo
                                                .flgProsecutionCourtLaw
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                otherinformationLabel[
                                                "WHETHER_ANY_DISCIPLINARY_ACTION_HAS_BEEN_INITIATED_BY_GOVERNMENT?"
                                                ]
                                            }
                                        </h6>
                                    &ensp;
                                    <span>
                                            {profileInfo.otherInfo
                                                .flgDispActionGovSemiGov
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <hr className="mar_top_bottom_8" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>

                                            {
                                                otherinformationLabel[
                                                "WHETHER_DISCIPLINARY_OR_ANY_OTHER_SIMILAR_ACTION_IS_INITIATED_INSTITUTION?"
                                                ]
                                            }
                                        </h6>
                                    &ensp;
                                    <span>
                                            {profileInfo.otherInfo
                                                .flgBarcouncilInstitution
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>

                                            {
                                                otherinformationLabel[
                                                "WAS_ANY_COURT_CASE_FILED_AGAINST_YOU?"
                                                ]
                                            }
                                        </h6>
                                        &ensp;
                                        <span>
                                            {profileInfo.otherInfo
                                                .flgCourtCaseFilled
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr className="mar_top_bottom_8" />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content-row clear-fix">
                                        <h6>
                                            {
                                                otherinformationLabel[
                                                "WHETHER_DEBARRED_OR_BLACK_LISTED_FOR_EXAMINATION_OR_ANY_OTHER_ORGANISATIONS?"
                                                ]
                                            }
                                        </h6>
                                    &ensp;
                                    <span>
                                            {profileInfo.otherInfo
                                                .flgDebaredBlockListExam
                                                ? "Yes"
                                                : "No"}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {profileInfo.otherInfo.flgDebaredBlockListExam ?
                                        <div className="content-row clear-fix">
                                            <h6>
                                                {
                                                    otherinformationLabel[
                                                    "DURATION"
                                                    ]
                                                }
                                            </h6>
                                        &ensp;
                                        {profileInfo.otherInfo.natureLookupId === 1 ?
                                                <span>{profileDetailsLabel.PERMANENT}</span>
                                                : profileInfo.otherInfo.natureLookupId === 2 ?
                                                    <span> {profileDetailsLabel.FOR_CERTAIN_PERIOD} </span>
                                                    : ""
                                            }
                                        </div>
                                        : ""}
                                </div>
                            </div>
                            {profileInfo.otherInfo.natureLookupId === 2 ?
                                <div>
                                    <hr className="mar_top_bottom_8" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="content-row clear-fix">
                                                <h6>
                                                    {
                                                        otherinformationLabel[
                                                        "FROM_DATE"
                                                        ]
                                                    }
                                                </h6>
                                    &ensp;
                                    <span>
                                                    {profileInfo.otherInfo
                                                        .debarredStartDate
                                                        ? dayjs(fromUTCDate(new Date(profileInfo.otherInfo.debarredStartDate))).format(commonLabels.DATE_FORMAT) : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {profileInfo.otherInfo.flgDebaredBlockListExam ?
                                                <div className="content-row clear-fix">
                                                    <h6>
                                                        {
                                                            otherinformationLabel[
                                                            "TO_DATE"
                                                            ]
                                                        }
                                                    </h6>
                                        &ensp;
                                        <span>
                                                        {profileInfo.otherInfo.debarredEndDate ? dayjs(fromUTCDate(new Date(profileInfo.otherInfo.debarredEndDate))).format(commonLabels.DATE_FORMAT) : ""}
                                                    </span>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }
                        </div>
                    </div>

                        <div className="card mt-3">
                            <div className="card-header">
                                <span>{profileDetailsLabel.OTHER_DETAILS_INFO_NOTE_2}</span>
                            </div>
                            <div className="card-body pad_top_bottom_8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>{otherinformationLabel.OTHER_INFO_NOTE_3_2_1}</h6>
                                        &ensp;
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <span>
                                                {profileInfo.otherInfo
                                                    .activities
                                                    ? profileInfo.otherInfo.activities
                                                    : ""}
                                        </span>
                                    </div>
                                </div>

                                <hr className="mar_top_bottom_8" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>{otherinformationLabel.OTHER_INFO_NOTE_3_2_2}</h6>
                                        &ensp;
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <span>
                                                {profileInfo.otherInfo
                                                    .distinctionLeadershipPosition
                                                    ? profileInfo.otherInfo.distinctionLeadershipPosition
                                                    : ""}
                                        </span>
                                    </div>
                                </div>

                                <hr className="mar_top_bottom_8" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-row clear-fix">
                                            <h6>{otherinformationLabel.OTHER_INFO_NOTE_3_2_3}</h6>
                                            &ensp;
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <span>
                                            {profileInfo.otherInfo
                                                .extraCurricular
                                                ? profileInfo.otherInfo.extraCurricular
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <br />
                </div>
                {/* qualification information */}
                <div className="advertisement_details_Eligibility border border-light">
                    <label className="fw-bold">
                        {personalInformation.headerField[3].name}
                    </label>
                    <div
                        className="qualification-table qualificationTableCss"
                    >
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {qualificationinformation.qualificationHeaderContent && qualificationinformation.qualificationHeaderContent.slice(0, -2).map((ele, index) =>
                                        <th scope="col" key={index} className="theadBgColor">{ele}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="f12">
                                {profileInfo && profileInfo.qualificationInfoList.sort((a, b) => {
                                    return qualificationTypes.findIndex((type) => type.qualificationtypecode === a.qualificationtypecode)
                                        - qualificationTypes.findIndex((type) => type.qualificationtypecode === b.qualificationtypecode);
                                }).map((ele, index) => {
                                    if (!ele.flgDeleted && (ele.qualificationtypecode !== "Pb")) {
                                        count++;
                                        return (
                                            <tr key={index}>
                                                <td className="w-10 word-break text-center">{count}</td>
                                                <td className="word-break">{getQualificationType(ele.qualificationtypecode,
                                                    requiredQualificationTypeCodes)}</td>
                                                <td className="word-break">{getQualificationNameCode(ele.qualificationnamecode,
                                                    requiredQualificationNameCode)}</td>
                                                <td className="word-break">{getSubject(ele.subjectLookupId, requiredSubject)}</td>
                                                <td  className="word-break">{getUniversity(ele.universitycode, requiredQualificationUniversityCodes)}</td>
                                                <td className="word-break">{ele.resultDate ? dayjs(fromUTCDate(new Date(ele.resultDate))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                <td className="word-break">{ele.attempts}</td>
                                                <td className="word-break">{ele.percentageGrade}</td>
                                                <td className="word-break">{ele.courseDurationMonths}</td>
                                                <td className="word-break">{getLookupLabel(ele.classGradeLookupId, profileCreationAllLookupData)}</td>
                                                <td className="word-break">{getLookupLabel(ele.modeLookupId, profileCreationAllLookupData)}</td>
                                                <td className="word-break">{ele.compulsorySubjects}</td>
                                                <td className="word-break">{ele.optionalSubjects}</td>
                                            </tr>
                                        )
                                    }
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    { showPublicationTable && 
                    <>
                    <hr/>
                    <label className="fw-bold">
                        {qualificationinformation.PUBLICATION_DETAILS}
                    </label>
                        <div className="qualification-table qualificationTableCss">
                            <table className="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        {qualificationinformation.publicationQualificationHeaderContent && qualificationinformation.publicationQualificationHeaderContent.slice(0, -2).map((ele, index) =>
                                            <th scope="col" key={index} className="theadBgColor">{ele}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="f12">
                                    {profileInfo && profileInfo.qualificationInfoList.sort((a, b) => {
                                        return qualificationTypes.findIndex((type) => type.qualificationtypecode === a.qualificationtypecode)
                                            - qualificationTypes.findIndex((type) => type.qualificationtypecode === b.qualificationtypecode);
                                    }).map((ele, index) => {
                                        if (!ele.flgDeleted && (ele.qualificationtypecode === "Pb")) {
                                            publilcationTableCount++
                                            return (
                                                <tr key={index}>
                                                    <td className="w-10 word-break text-center">{publilcationTableCount}</td>
                                                    <td className="word-break ">{getQualificationType(ele.qualificationtypecode,
                                                        requiredQualificationTypeCodes)}</td>
                                                    <td className="word-break ">{getQualificationNameCode(ele.qualificationnamecode,
                                                        requiredQualificationNameCode)}</td>
                                                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.indexingBodyLookupId && nameOfIndexingBodies((ele.publicationQualificationInfo.indexingBodyLookupId), nameOfIndexingBodiesList)}</td>
                                                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.articleTypeLookupId && articleType((ele.publicationQualificationInfo.articleTypeLookupId), getPublicationArticleTypeList)}</td>
                                                    <td className="word-break">{ele && flgPublicationFirstAuthor(((ele.flgPublicationFirstAuthor === true) || (ele.flgPublicationFirstAuthor === "true") ? true : (ele.flgPublicationFirstAuthor === false) || (ele.flgPublicationFirstAuthor === "false") ? false : ""), BOOLEAN_OPTION)}</td>
                                                    <td className="word-break">{ele && ele.publicationAuthorType && authorType((ele.publicationAuthorType), AUTHOR_TYPES)}</td>
                                                    <td className="word-break">{ele && ele.publicationJournalDetails}</td>
                                                    <td className="word-break">{ele && ele.publicationYear}</td>
                                                    <td className="word-break">{ele && ((ele.flgImpactIndex2Or0OrMore === true || ele.flgImpactIndex2Or0OrMore === "true") ? "Yes" : (ele.flgImpactIndex2Or0OrMore === false || ele.flgImpactIndex2Or0OrMore === "false") ? "No" : "")}</td>
                                                    <td className="word-break">{ele && ele.publicationPaperTitle}</td>
                                                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.publicationAcceptanceDate && dayjs(ele.publicationQualificationInfo.publicationAcceptanceDate).format(commonLabels.DATE_FORMAT)}</td>
                                                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.publicationPublishedDate && dayjs(ele.publicationQualificationInfo.publicationPublishedDate).format(commonLabels.DATE_FORMAT)}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                    }
                                </tbody>
                            </table>
                    </div>
                    </>}
                </div>
                {/* experience information */}
                <div className="advertisement_details_Eligibility border border-light">
                    <label className="fw-bold">
                        {personalInformation.headerField[4].name}
                    </label>
                    {profileInfo && profileInfo.isExperienced ?
                        <div className="qualification-table qualificationTableCss">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {experienceinformation.experienceHeaderContent.slice(0, -2).map(
                                            (ele, index) =>
                                                <th scope="col" key={index} className="theadBgColor">{ele}</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="f12">
                                    {profileInfo && profileInfo.experienceInfoList.map((ele, index) => {
                                        if (!ele.flgDeleted) {
                                            return (
                                                <tr key={index}>
                                                    <td className="w-10 word-break text-center">{index + 1}</td>
                                                    <td className="word-break">{ele.officeName}</td>
                                                    <td className="word-break">{ele.designation}</td>
                                                    <td className="word-break">{getLookupLabel(ele.apointmentNatureLookupId, profileCreationAllLookupData)}</td>
                                                    <td className="word-break">{getLookupLabel(ele.jobNatureLookupId, profileCreationAllLookupData)}</td>
                                                    <td className="word-break">{getDropdownOptionLabel(ele.fullTimeLookupId, FULLTIME_OPTION)}</td>
                                                    <td className="word-break">{ele.payScale}</td>
                                                    <td className="word-break">{ele.gradePay}</td>
                                                    <td className="word-break">{ele.monthlyGrossSalary}</td>
                                                    <td className="word-break">{ele.employmentFrom ? dayjs(fromUTCDate(new Date(ele.employmentFrom))).format(commonLabels.DATE_FORMAT) : ""}</td>
                                                    {ele.employmentTo &&
                                                        <td className="word-break">{ele.employmentTo ? dayjs(fromUTCDate(new Date(ele.employmentTo))).format(commonLabels.DATE_FORMAT) : dayjs().format(commonLabels.DATE_FORMAT)}</td>
                                                    }
                                                    {ele.employmentTo &&
                                                        <td className="word-break">{ ele.employmentTo ? ele.expYears : (handleFromToDateSelectionForExp(
                                                            (dayjs(fromUTCDate(new Date(ele.employmentFrom))).format()),
                                                            (dayjs().format())
                                                            ).expYears)}
                                                        </td>
                                                    }
                                                    {ele.employmentTo &&
                                                    <td className="word-break">{ele.employmentTo ? ele.expMonths :
                                                        (handleFromToDateSelectionForExp(
                                                            (dayjs(fromUTCDate(new Date(ele.employmentFrom))).format()),
                                                            (dayjs().format())
                                                        ).expMonths)}
                                                    </td>
                                                    }
                                                    {ele.employmentTo &&
                                                    <td className="word-break">{ele.employmentTo ? ele.expDays:
                                                        (handleFromToDateSelectionForExp(
                                                            (dayjs(fromUTCDate(new Date(ele.employmentFrom))).format()),
                                                            (dayjs().format())
                                                        ).expDays)}
                                                    </td>}
                                                    <td className="word-break">{getDropdownOptionLabel(ele.flgMpscSelection, BOOLEAN_OPTION)}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="card">
                            <div className="card-body pad_top_bottom_8">
                                <div className="content-row clear-fix">
                                    <h6>
                                        {experienceinformation.ARE_YOU_HAVING_EXPERIENCE}
                                    </h6>
                                    &ensp;
                                    <span>
                                        {profileInfo && profileInfo.isExperienced ? "Yes" : "No"}
                                    </span>
                                </div>
                            </div>
                        </div>

                    }
                </div>
            </div>
        </div>
    );
};

export default ProfileDetails;
