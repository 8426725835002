import React, { useState, useEffect } from "react";
import { Controller} from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json'
import { shallowEqual, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import { RESULTS } from "../../common/constants/dropdownOptions";
import { getLookOptionsForADropdown } from "../../helpers/LookupOptionHelper";
import {numberValidation,  percentage, numberRegex, otherQualificationRegex } from '../../common/constants/regex';
import numberformat from "../labels/serial_nos_en.json";
import dayjs from 'dayjs';
import _ from "lodash/fp";
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import Select from "react-select";

const maxlength = 100;
const StandardQualification = () => {
  let { profileCreationAllLookupData } = useSelector(state => state.profile, shallowEqual);
  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType, setQualificationState,
      register, handleSubmit, errors, qualificationState, control, currentEditQualificationIndex,
      isResultDateMandatory, onQualificationNameChange, handleResultTypeChange, qualificationNames,
      subjects, onStateChange, statesList, universities, flgSubjectDisable, flgStateDisable, flgUniversityDisable,
      qualificationtypecode } = useQualificationActions();

  const grades = getLookOptionsForADropdown("qualification_grade", profileCreationAllLookupData);
  const modes = getLookOptionsForADropdown("qualification_mode", profileCreationAllLookupData);
  const [disableUpdateBtnFlg, setDisableUpdateBtnFlg] = useState(false);

  useEffect(()=>{
    if(((qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue) && ((!qualificationState.optionalSubjects) || (qualificationState && qualificationState.optionalSubjects.length === 0)) && (qualificationState && qualificationState.qualificationnamecode !=="SSC")) || ((qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue) && ((!qualificationState.compulsorySubjects) || (qualificationState && qualificationState.compulsorySubjects.length === 0)) && (qualificationState && qualificationState.qualificationnamecode !=="SSC"))){
      setDisableUpdateBtnFlg(true);
    }else{
      setDisableUpdateBtnFlg(false)
    }
  },[qualificationState]);

  return (
    <form onSubmit={handleSubmit((e) => {handleQualificationInfoSubmit(e)})}>
      <div className="row">
        <QualificationTypeDropdown />
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.2"]}&nbsp;{qualificationinformation.NAME_OF_DEGREE}</label>
          <span className="redColor">*</span>
          {
            qualificationtypecode === "Other" ?
            <input type="text" className="form-control" defaultValue={qualificationState.qualificationnamecode} name ="qualificationnamecode" maxLength={maxlength} ref={register({ required: true, pattern: otherQualificationRegex })} onChange={onQualificationNameChange}/>
            :
            <select className="form-select" value={qualificationState.qualificationnamecode} name ="qualificationnamecode" ref={register({ required: true })} onInput={onQualificationNameChange}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {
                qualificationNames.sort((name1,name2) => { return name1.sortOrder-name2.sortOrder }).map((ele, i) => {
                  return <option key={i} value={ele.qualificationNameCode}>{ele.qualificationName}</option>
                })
              }
            </select>
          }
          {_.get("qualificationnamecode.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
           {_.get("qualificationnamecode.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.PLEASE_ENTER_VALID_VALUE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.3"]}&nbsp;{qualificationinformation.SUBJECT}</label>
          {
            qualificationtypecode === "Other" ?
            <div>
              <span className="redColor">*</span>
              <input type="text" className="form-control" defaultValue={qualificationState.otherSubject} maxLength={maxlength} name ="otherSubject" ref={register({ required: true, pattern: otherQualificationRegex })} onChange={handleQualificationChange} />
              {_.get("otherSubject.type", errors) === "required" && (
                <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("otherSubject.type", errors) === "pattern" && (
                <p className="errorText">{qualificationinformation.PLEASE_ENTER_VALID_SUBJECT_STREAM}</p>
              )}
            </div>
            :
            <select className="form-select" value={qualificationState.subjectLookupId} name ="subjectLookupId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgSubjectDisable}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {
                subjects.sort((sub1,sub2) => { return sub1.subjectName.localeCompare(sub2.subjectName) }).map((ele, i) => {
                  return <option key={i} value={ele.subjectId}>{ele.subjectName}</option>
                })
              }
            </select>
          }
        </div>
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.4"]}&nbsp;{qualificationinformation.STATE}</label>
          <select className="form-select" value={qualificationState.statecode} name ="statecode" ref={register({ required: false })} onInput={onStateChange} disabled={flgStateDisable}>
            <option key={0} value={999}>{qualificationinformation.SELECT_STATE}</option>
            {
              statesList.sort((a, b) => a.statename < b.statename ? - 1 : 1).map((ele, i) => {
                return <option key={i} value={ele.statecode}>{ele.statename}</option>
              })
            }
          </select>
        </div>
        <div className="form-group col-md-6 mb-3">
          <label className='float-start mb-0' for="universitycode">{numberformat["4.5"]}&nbsp; {qualificationinformation.BOARD_UNIVERSITY}</label>
          <select id="universitycode" className="form-control" value={qualificationState.universitycode} name ="universitycode" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgUniversityDisable}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              universities.sort((a, b) => a.universityName < b.universityName ? - 1 : 1).map((ele, i) => {
                return <option key={i} value={ele.universityCode}>{ele.universityName}</option>
              })
            }
          </select>
        </div>
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.6"]}&nbsp;{qualificationinformation.RESULT} </label>
          <span className="redColor">*</span>
          <select className="form-select" value={qualificationState.typeResult} name ="typeResult" ref={register({ required: true })} onInput={handleResultTypeChange}>
            {
              RESULTS.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("typeResult.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.7"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <Controller
            name ="resultDate"
            key= {qualificationState.resultDate ? "resultDate": "resultDate1"}
            defaultValue={qualificationState.resultDate? dayjs(qualificationState.resultDate).toDate(): ""}
            rules={{ required: isResultDateMandatory, validate: value => value <= new Date() }}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.resultDate? dayjs(qualificationState.resultDate).toDate(): fromUTCDate(value)}
                maxDate={new Date()}
                onChange={(e) => {setQualificationState({
                  ...qualificationState,
                  resultDate: toUTCDate(e)
                }); onChange(toUTCDate(e))}}
                disabled={!isResultDateMandatory}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
          {_.get("resultDate.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("resultDate.type", errors) === "validate" && (
					  <p className="errorText">{qualificationinformation.DATA_LTE_TODAY_MSG}</p>
				  )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className='float-start mb-0'>{numberformat["4.8"]}&nbsp; {qualificationinformation.ATTEMPTS}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" name ="attempts" maxLength={2}  ref={register({ required: isResultDateMandatory, pattern:numberValidation})} value={qualificationState.attempts ? qualificationState.attempts : ""} defaultValue={qualificationState.attempts} onChange={handleQualificationChange}/>
          {_.get("attempts.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
            {_.get("attempts.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Only_Number}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className='float-start mb-0'>{numberformat["4.9"]}&nbsp;{qualificationinformation.PERCENTAGE_GRADE}  {isResultDateMandatory ? <span className="redColor">*</span>: ""}</label>
        
          <input type="text" className="form-control" value={qualificationState.percentageGrade ? qualificationState.percentageGrade : ""} defaultValue={qualificationState.percentageGrade} name ="percentageGrade" maxLength={5} ref={register({ required: isResultDateMandatory, pattern:percentage })} onChange={handleQualificationChange}/>
          {_.get("percentageGrade.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("percentageGrade.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_PERCENTAGE_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className='float-start mb-0'>{numberformat["4.10"]}&nbsp;{qualificationinformation.COURSE_DURATION}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" maxLength={2} value={ qualificationState.courseDurationMonths ? qualificationState.courseDurationMonths :""} defaultValue={qualificationState.courseDurationMonths} name ="courseDurationMonths" ref={register({ required: isResultDateMandatory, pattern:numberRegex })} onChange={handleQualificationChange}/>
          {_.get("courseDurationMonths.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("courseDurationMonths.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_COURSE_DURATION_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className='float-start mb-0'>{numberformat["4.11"]}&nbsp;{qualificationinformation.CLASS_GRADE}</label>
          <select className="form-select" value={qualificationState.classGradeLookupId} name ="classGradeLookupId" ref={register({ required: false })} onInput={handleQualificationChange}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {grades && grades.sort((grade1, grade2) => { return grade1.label.localeCompare(grade2.label)})
            .map(ele =>
                <option key={ele.id} value={ele.id}>{ele.label}</option>
            )}
          </select>
        </div>
        <div className="col-md-3 mb-3 ">
          <label className='float-start mb-0'>{numberformat["4.12"]}&nbsp;{qualificationinformation.MODE}</label>
          <select className="form-select" value={qualificationState.modeLookupId ? qualificationState.modeLookupId : ""} defaultValue={qualificationState.modeLookupId} name ="modeLookupId" ref={register({ required: false })} onInput={handleQualificationChange}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {modes && modes.sort((mode1, mode2) => { return mode1.label.localeCompare(mode2.label)})
            .map(ele =>
                <option key={ele.id} value={ele.id}>{ele.label}</option>
            )}
          </select>
        </div>
        <div className="col-md-3 ">
          <label className="col-md-12 mb-0 me-2 ms-n2">{numberformat["4.13"]}&nbsp;{qualificationinformation.COMPULSORY_SUBJECTS}{((qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue) && (qualificationState && qualificationState.qualificationnamecode !== "SSC")) ? <span className="redColor">*</span>: ""}</label>
          <Select
            className="w-100"
            name="compulsorySubjects"
            isMulti={true}
            getOptionValue={(option) => option.subjectId}
            getOptionLabel={(option) => option.subjectName}
            value={qualificationState.compulsorySubjects ? qualificationState.compulsorySubjects : ""}
            onChange={(e) => { handleQualificationChange(e, "compulsorySubjects")}}
            isDisabled={flgSubjectDisable || (subjects.length === 0 ? true : false) || qualificationtypecode === "Other"}
            options={subjects.sort((sub1, sub2) => sub1.subjectName.localeCompare(sub2.subjectName))} 
          />
        </div>
        {qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue && 
        <div className="col-md-3 mb-1">
          <label className="col-md-12 mb-0 me-2 ms-n2">{qualificationinformation.COMPULSORY_SUBJECTS} {qualificationinformation.OLD}</label>
          <input type="text" className="form-control" defaultValue={qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue} name="compulsorySubjectsIsNumberFlgValue" disabled/>
        </div>
        }
        <div className="col-md-3">
          <label className="col-md-12 mb-0 me-2 ms-n2">{numberformat["4.14"]}&nbsp;{qualificationinformation.OPTIONAL_SUBJECTS}&nbsp;{((qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue) && (qualificationState && qualificationState.qualificationnamecode !== "SSC")) ? <span className="redColor">*</span>: ""}</label>
          <Select
            className="w-100"
            name="optionalSubjects"
            isMulti={true}
            getOptionValue={(option) => option.subjectId}
            getOptionLabel={(option) => option.subjectName}
            value={qualificationState.optionalSubjects ? qualificationState.optionalSubjects : ""}
            onChange={(e) => { handleQualificationChange(e, "optionalSubjects")}}
            isDisabled={flgSubjectDisable || (subjects.length === 0 ? true : false)|| qualificationtypecode === "Other"}
            options={subjects.sort((sub1, sub2) => sub1.subjectName.localeCompare(sub2.subjectName))} 
          />
        </div>
        {qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue && 
          <div className="col-md-3 mb-1">
            <label className="col-md-12 mb-0 me-2 ms-n2"> {qualificationinformation.OPTIONAL_SUBJECTS} {qualificationinformation.OLD}</label>
            <input type="text" className="form-control" defaultValue={qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue} name="optionalSubjectsIsNumberFlgValue" disabled/>
          </div>
        }
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
          <button type="submit" name="edit" className="btn btn-primary m-1"  onClick={() => {setActionType('edit')}} disabled={disableUpdateBtnFlg}>{qualificationinformation.UPDATE}</button>
            :
          <button type="submit" name="add" className="btn btn-primary m-1"  onClick={() => {setActionType('add')}}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default StandardQualification;
