/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../styles/my_account.css";
import { Modal } from 'react-bootstrap';
import myAccountLabels from "../labels/my_account_en.json";
import common from "../labels/common_en.json";
import { format } from 'date-fns';
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import { toastr } from "react-redux-toastr";
import { doObjectionRequestPayment } from "../actions/myAccountAction";
import { useDispatch } from "react-redux";

const ObjectionPaymentModal = (props) => {
    const dispatch = useDispatch();
    const [showObjectionPaymentRequestModal, setShowObjectionPaymentRequestModal] = useState(false);
    let [showOnlinePayment, setShowOnlinePayment] = useState(true);
    let [showSbi, setShowSbi] = useState(true);
    let [showBillDesk, setShowBillDesk] = useState(true);
    let [showEaseBuzz, setShowEaseBuzz] = useState(true);
    let [showCsc, setShowCsc] = useState(true);
    let [showQuickwallet, setShowQuickwallet] = useState(true);
    let [showChallan, setShowChallan] = useState(true);
    let [enablepay, setEnablepay] = useState(true);
    let [isFeesPaidFlg, setIsFeesPaidFlg] = useState(false);

    const { register, watch, formState } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        if (props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0) {
            let tempData = props.objReqData.objectionRequestsDtoList;
            let allFeesPaidFlgs = tempData && tempData.map(item => item.isFeesPaid);
            if (allFeesPaidFlgs && allFeesPaidFlgs.some(flag => (flag === false || flag === null || flag === undefined))) {
                setIsFeesPaidFlg(true);
            }

        }
    }, [props, props.objReqData, props.objReqData.objectionRequestsDtoList]);

    let requestHeaderField = [
        {
            'name': "Sr.No"
        },
        {
            'name': "Subject"
        },
        {
            'name': "Question No"
        },
        {
            'name': "Created At"
        },
        {
            'name': "Fees(Rs.)"
        },
        {
            'name': "Status"
        }
    ];

    const modalCancel = () => {
        props.setShowPaymentModal(false)
    }

    const handlePayNowClick = () => {
        setShowObjectionPaymentRequestModal(true);

        const enabledPaymentMethods = JSON.parse(props && props.objReqData && props.objReqData.advertisementConfig && props.objReqData.advertisementConfig.enabledPaymentMethods)
        const disabledPaymentMethods = JSON.parse(props && props.objReqData && props.objReqData.advertisementConfig && props.objReqData.advertisementConfig.disabledPaymentMethods)

        if (enabledPaymentMethods !== null && disabledPaymentMethods !== null) {
            toastr.error(common.PAYMENT_METHODS_NULL_ERROR)
            setEnablepay(false);
        }

        if (enabledPaymentMethods !== null) {
            setShowChallan(enabledPaymentMethods.includes("challan"));
            setShowOnlinePayment(enabledPaymentMethods.includes("sbiepay") ||
                enabledPaymentMethods.includes("billdesk") ||
                enabledPaymentMethods.includes("csc") ||
                enabledPaymentMethods.includes("quikwallet") ||
                enabledPaymentMethods.includes("easebuzz"));
            setShowSbi(enabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(enabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(enabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(enabledPaymentMethods.includes("quikwallet"));
            setShowCsc(enabledPaymentMethods.includes("csc"));
        }

        if (disabledPaymentMethods !== null) {
            setShowChallan(!disabledPaymentMethods.includes("challan"));
            setShowOnlinePayment(!disabledPaymentMethods.includes("sbiepay") ||
                !disabledPaymentMethods.includes("billdesk") ||
                !disabledPaymentMethods.includes("csc") ||
                !disabledPaymentMethods.includes("quikwallet") ||
                !disabledPaymentMethods.includes("easebuzz"));
            setShowSbi(!disabledPaymentMethods.includes("sbiepay"));
            setShowBillDesk(!disabledPaymentMethods.includes("billdesk"));
            setShowEaseBuzz(!disabledPaymentMethods.includes("easebuzz"));
            setShowQuickwallet(!disabledPaymentMethods.includes("quikwallet"));
            setShowCsc(!disabledPaymentMethods.includes("csc"));
        }
    };

    const processToObjectionPayment = (paymenttype) => {
        let data = {}
        data.paymentLocationTab = "my_account";
        data.paymentBank = watch("paymentBank");

        let requestBody = {};
        let objectionRequestsList = props && props.objReqData && props.objReqData.objectionRequestsDtoList;
        const idArray = objectionRequestsList && objectionRequestsList.map(item => item.id);

        requestBody.objReqIds = idArray;
        requestBody.fees = calculateTotal();

        let objectionReqId = props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0 && props.objReqData.objectionRequestsDtoList[0].id;
        dispatch(doObjectionRequestPayment(objectionReqId, paymenttype, data, requestBody));
        setShowObjectionPaymentRequestModal(false)
        setShowChallan(true)
        setShowOnlinePayment(true);
        setShowSbi(true);
        setShowBillDesk(true);
        setShowEaseBuzz(true);
        setShowQuickwallet(true);
        setShowCsc(true);
    };

    const calculateTotal = () => {
        let objectionRequestsList = props && props.objReqData && props.objReqData.objectionRequestsDtoList;
        let advertisementConfigList = props && props.objReqData && props.objReqData.advertisementConfig;
        const totalFees = objectionRequestsList && objectionRequestsList.reduce((sum, item) => {
            if (!item.isFeesPaid) {
                return sum + item.fees;
            }
            return sum;
        }, 0);
        const UpdatedFees = totalFees + advertisementConfigList.objectionServiceCharges
        return UpdatedFees;
    };

    return (
        <>
            {(props.showPaymentModal || showObjectionPaymentRequestModal) && <div className="blur-backdrop"></div>}
            <form className="ObjectionForm mt-5">
                <div>
                    <Modal show={props.showPaymentModal} onHide={modalCancel} backdrop='static' size="xl">
                        <Modal.Header closeButton className="custom-modal-header">
                            <Modal.Title>{myAccountLabels.OBJECTION_DETAILS}</Modal.Title>
                        </Modal.Header>
                        <div>
                            <Modal.Body>
                                <div className="col-sm-12 col-md-12 col-lg-12 float-start p-0">
                                    <div className="tab-content border border-light" id="robjectionMainContent">
                                        <div id="ObjectionRequestSec" role="tabpanel" aria-labelledby="profile-tab" className="bg-white custom-box-shadow">
                                            <div className="border border-light text-start w-100 p-4">
                                                <div className="row ">
                                                    <div className="col-sm-12 col-md-12 col-lg-12" >
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {requestHeaderField.map((ele, index) =>
                                                                        <th scope="col" key={index} className="theadBgColor">{ele.name} </th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="small">
                                                                {props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0 ? props.objReqData.objectionRequestsDtoList.map((ele, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td className="w-10 word-break" > {++index}</td>
                                                                                <td className="word-break" >{ele.examSubjectName}</td>
                                                                                <td className="word-break" >{ele.candidateSetQuestionSrno}</td>
                                                                                <td className="word-break" >{format(new Date(ele.createdAt), common.SMALL_SIZE_DATE_FORMAT)}</td>
                                                                                <td className="word-break" >{ele.fees}</td>
                                                                                <td className="word-break" >{ele.isFeesPaid === true ? <span className="text-success">{myAccountLabels.FEES_PAID}</span> : <span className="text-danger">{myAccountLabels.FEES_NOT_PAID}</span>}</td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan="11" className="text-center">{common.NO_DATA_TO_DISPLAY}</td>
                                                                    </tr>
                                                                }
                                                                {isFeesPaidFlg && props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0 && (
                                                                    <tr>
                                                                        <td colSpan="4" className="text-end fw-bold">{common.SERVICE_CHARGE}</td>
                                                                        <td>{props.objReqData.advertisementConfig.objectionServiceCharges}</td>
                                                                    </tr>
                                                                )}
                                                                {isFeesPaidFlg && props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0 && (
                                                                    <tr>
                                                                        <td colSpan="4" className="text-end fw-bold">{common.TOTAL}</td>
                                                                        <td>{calculateTotal()}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn btn-primary" disabled={((props && props.objReqData && props.objReqData.objectionRequestsDtoList && props.objReqData.objectionRequestsDtoList.length > 0 && isFeesPaidFlg) ? false : true)} onClick={handlePayNowClick}>{myAccountLabels.PAY_NOW}</button>
                            <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div>
                    <Modal show={showObjectionPaymentRequestModal}
                        onHide={() => { setShowObjectionPaymentRequestModal(false); setShowChallan(true); setShowOnlinePayment(true); setShowSbi(true); setShowBillDesk(true); setShowEaseBuzz(true); setShowQuickwallet(true) }} backdrop='static'>
                        <form>
                            <Modal.Header closeButton className="custom-modal-header">
                                <Modal.Title> {myAccountLabels.SELECT_PAYMENT_MODE} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        {showOnlinePayment ?
                                            <div className="input-group">
                                                <label className="position-relative">
                                                    <input type="radio" className="radio" name="paymenttype" value="online_payment" disabled={props && props.objReqData && props.objReqData.advertisementConfig && dayjs(props.objReqData.advertisementConfig.onlinePaymentEndDate).isBefore(new Date(), 'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Online_Payment}
                                                </label>
                                                <span className="ms-3">{common.ONLINE_PAYMENT_NOTE} &nbsp;
                                                    {props && props.objReqData && props.objReqData.advertisementConfig && props.objReqData.advertisementConfig.onlinePaymentEndDate ? dayjs(props.objReqData.advertisementConfig.onlinePaymentEndDate).format(common.DATE_FORMAT) : ""}
                                                </span>
                                            </div> : ""}
                                        <br />
                                        {watch("paymenttype") === 'online_payment' ?
                                            <div className="ps-4">
                                                {showSbi ?
                                                    <div className="input-group">
                                                        <label className="position-relative">
                                                            <input type="radio" className="radio" name="paymentBank" value="sbi" ref={register({ required: true })} />&nbsp;{common.SBI}
                                                        </label>
                                                    </div> : ""}
                                                {showBillDesk ?
                                                    <div className="input-group">
                                                        <label className="position-relative">
                                                            <input type="radio" className="radio" name="paymentBank" value="billdesk" ref={register({ required: true })} />&nbsp;{common.BILL_DESK}
                                                        </label>
                                                    </div> : ""}
                                                {showCsc ?
                                                    <div className="input-group">
                                                        <label className="position-relative">
                                                            <input type="radio" className="radio" name="paymentBank" value="csc" ref={register({ required: true })} />&nbsp; {common.CSC}
                                                        </label>
                                                    </div> : ""}
                                                {showEaseBuzz ?
                                                    <div className="input-group">
                                                        <label className="position-relative">
                                                            <input type="radio" className="radio" name="paymentBank" value="easebuzz" ref={register({ required: true })} />&nbsp;{common.EASEBUZZ}
                                                        </label>
                                                    </div> : ""}
                                                {showQuickwallet ?
                                                    <div className="input-group">
                                                        <label className="position-relative">
                                                            <input type="radio" className="radio" name="paymentBank" value="quickPayWallet" ref={register({ required: true })} />&nbsp; {common.QUICK_WALLET}
                                                        </label>
                                                    </div> : ""}
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={!(formState.isValid && enablepay)}
                                    onClick={() => { processToObjectionPayment(watch("paymenttype")) }}
                                >
                                    {common.OK}
                                </button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            </form>
        </>
    );
}
export default ObjectionPaymentModal;