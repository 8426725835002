import { LOGOUT_CLEAR_USER_SESSION, SET_USER_SESSION, SET_API_REPO_VERSION, SET_SECURITY_ANSWERS_FOR_LOGIN, SET_SECURITY_QUESTIONS_FOR_LOGIN, SET_MODULE_LIST, SET_LOG_OUT_OTP_DATA, GET_LOGIN_STATUS} from "../../common/constants/ActionTypes";

const initialState = {
    accessToken: "",
    apiRepoVersion: '',
    questions : {},
    securityAnswerMap : {},
    logoutOtpData : "",
    moduleList : []
}

export default function loginReducer(state = initialState, action) {
    switch(action.type) {
        case SET_USER_SESSION:
            return {
                ...state,
                accessToken: action.payload
            }
        case LOGOUT_CLEAR_USER_SESSION:
            return {
                ...state,
                accessToken: ""
            }
        case SET_API_REPO_VERSION:
            return {
                ...state,
                apiRepoVersion: action.payload
            }
        case SET_SECURITY_ANSWERS_FOR_LOGIN:
                return {
                    ...state,
                    securityAnswerMap: action.payload
                }  
        case SET_SECURITY_QUESTIONS_FOR_LOGIN:
                return {
                    ...state,
                    questions: action.payload
                }  
        case  SET_MODULE_LIST:
            return {
                ...state,
                moduleList: action.payload
            }   
        case  SET_LOG_OUT_OTP_DATA:
            return {
                ...state,
                logoutOtpData: action.payload
            }     
            case GET_LOGIN_STATUS:
                return{
                    ...state,
                 loginStatus:action.payload
                }                         
        default:
            return state;
    }
}