/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import '../../styles/forgot_password.css';
import ForgotPasswordJson from "../labels/forgot_password_en.json";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash/fp";
import { email, mobile, forgotUserNameRegex } from "../../common/constants/regex";
import { enableOTPButton, checkModuleEnableDisable } from "../../common/actions/commonActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forgotLogin } from "../actions/forgotPasswordAction";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import { toastr } from 'react-redux-toastr';
import ModuleHide from "../../common/components/ModuleHide";
import profile from "../../candidate_profile_creation/labels/personal_information_en.json";
import "../../styles/candidate_nav.css";
import "../../styles/advertisementTable.css";
import commonLabels from "../../my_account/labels/common_en.json";
import { useNavigate } from 'react-router-dom';

const userLabels = {
    "migrated_username": "Username",
    "email": "Email ID",
    "mobile": "Mobile Number"
}

function ForgotUserName(props) {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, errors, control, setValue } = useForm({
        mode: "onChange"
    });

    const dispatch = useDispatch();
    let { userLoginData } = useSelector(state => state.forgotPasswordReducer, shallowEqual);
    let { moduleDetails, isApiCallForModule} = useSelector(state => state.common, shallowEqual);
    const [isShowToaster, showToaster] = useState(false);

    useEffect(()=> {
        setValue('usertype', 'migrated_username');
    },[])

    useEffect(()=>{
        if(!isApiCallForModule){
            dispatch(checkModuleEnableDisable("FRGT_LOGIN"));
        }},[isApiCallForModule]);

    useEffect(()=> {
        if(isShowToaster && userLoginData && userLoginData.length > 0){
        try {
            let firstOutput;
            let secoundOutput;
            userLoginData.map((ele, index) => {
                if(index === 0){
                    firstOutput = ele
                }else{
                    secoundOutput = ele
                }
            })
            let message = <div className="confirmMessage"> 
              <p>{userLabels[firstOutput.outputType] }: {firstOutput.output ? firstOutput.output : "Not Available"}</p>
              <p>{userLabels[secoundOutput.outputType]}: {secoundOutput.output ? secoundOutput.output : "Not Available"}</p>
              {watch("usertype") === 'migrated_username'?  <p>{ForgotPasswordJson.NOTE} {ForgotPasswordJson.NOTE_MESSAGE}</p> : ""}
            </div>
            const toastrConfirmOptions = {
                closeOnShadowClick: true,
                removeOnHover: true,
              };
            toastr.confirm(message,toastrConfirmOptions, {
                onOk: async () => {
                    setValue('usertype', '',{shouldValidate: false });
                    setValue('userNameText', '', {shouldValidate: false });
                    setValue('emailId', '', {shouldValidate: false });
                    setValue('mobileNo', '', {shouldValidate: false });
                    setValue('dob', '', {shouldValidate: false });
                    setValue('mothername', '',{shouldValidate: false });
                    showToaster(false)
                    setValue('usertype', 'migrated_username');
                },
                oncancel:async() => {
                }
            });
          }
          catch (err) {
            toastr.error(profile.SOMETHING_WENT_WRONG_ERROR);
          }
        }
    },[userLoginData])
  
    const onSubmit = (data) => {
        dispatch(forgotLogin(data))
        showToaster(true)
    }

    const reDirectToLogIn = () => {
        // props.history.push("/candidate/login");
        navigate("/candidate/login")
        dispatch(enableOTPButton('login'))
    }

    return moduleDetails.isEnabled == false ?  < ModuleHide details={moduleDetails}/> :
    (
        <form onSubmit={handleSubmit(onSubmit)} className="row mt50 mb100">
            <div className="col-md-3"></div>
            <div className="col-md-6 forgotPassTabCss" >
                <div className="text-center">
                    <span className="bold-text">{ForgotPasswordJson.Forgot_User_Name}</span>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center m-2" >
                        <label className="position-relative top12 right30 me-2">
                            <input type="radio" className="radio" name="usertype" value="migrated_username" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Username}
                        </label>
                        <label className="position-relative top12 right30 me-2">
                            <input type="radio" className="radio" name="usertype" value="email" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Email_ID}
                        </label>
                        <label className="position-relative top12 right30 me-2">
                            <input type="radio" className="radio"   name="usertype" value="mobile" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Mobile_Number}
                        </label>
                    </div>
                </div>
                {watch("usertype") === 'migrated_username' ?
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Username}<span className="errorText">*</span></label>
                    <input className="form-control" id="registrationFormControlInput1" type="text" name="userNameText" ref={register({ required: true, pattern: forgotUserNameRegex })} />
                    {_.get("userNameText.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.USER_NAME_REQUIRED}</p>
                    )}
                    {_.get("userNameText.type", errors) === "pattern" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.Valid_USER_NAME}</p>
                    )}
                </div>:""}
                {watch("usertype") === 'email' ?
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Email_ID}<span className="errorText">*</span></label>
                    <input className="form-control" id="registrationFormControlInput1" type="text" name="emailId" ref={register({ required: true, pattern: email })} />
                    {_.get("emailId.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.ONLY_EMAIL_REQUIRED}</p>
                    )}
                    {_.get("emailId.type", errors) === "pattern" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.EMAIL_PATTERN}</p>
                    )}
                </div> :""}
                {watch("usertype") === 'mobile' ?
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Mobile_Number}<span className="errorText">*</span></label>
                    <input className="form-control" id="registrationFormControlInput1" type="text" name="mobileNo" ref={register({ required: true, pattern: mobile })} />
                    {_.get("mobileNo.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MOBILE_REQ}</p>
                    )}
                    {_.get("mobileNo.type", errors) === "pattern" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MOBILE_PATTERN}</p>
                    )}
                </div>:""}
                <div >
                    <div className="row">
                        <div className="col-md-6">
                            <label className="LeftText">{ForgotPasswordJson.Date_of_Birth}<span className="errorText">*</span></label>
                        </div>
                    </div>
                    <div className="row ms-0 w-100">
                        <Controller
                            control={control}
                            name="dob"
                            id="registrationFormControlInput7"
                            rules={{ required: true  }}
                            render={({ onChange, value }) =>
                                <DatePicker className="form-control" 
                                    selected={fromUTCDate(value)}
                                    onChange={(e) => {
                                        onChange(toUTCDate(e))
                                    }}
                                    dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    placeholderText={commonLabels.DATE_FORMAT} />
                            }
                        />
                    </div>
                    {_.get("dob.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.DOB_REQUIRED}</p>
                    )}
                </div>
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Mothers_Name}<span className="errorText">*</span></label>
                    <input className="form-control" type="text" name="mothername" ref={register({ required: true, pattern: forgotUserNameRegex })} />
                    {_.get("mothername.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.Mothers_Name_REQUIRED}</p>
                    )}
                    {_.get("mothername.type", errors) === "pattern" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.Valid_Mothers_Name}</p>
                    )}
                </div>
                <div className="buttonAlign">
                    <button type="submit" disabled={props.typeOfOtp === 'Email'} className="btn btn-primary me-1">{ForgotPasswordJson.Submit}</button>
                     <button type="button" className="btn btn-primary" onClick={reDirectToLogIn}>{ForgotPasswordJson.CANCEL}</button>
                </div>
            </div>
        </form>)
}
export default ForgotUserName;