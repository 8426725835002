/* eslint-disable */
import React, { useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import login from "../labels/login_en.json";
import { logOutUserOtp, verificationOfLogoutOTP } from "../actions/sessionActions";
import "../../styles/advertisementTable.css";

const ForcefullyLogoutModal = ({forceFulLogout, setForceFulLogout, forceFulLogoutErrorMessage, setForceFulLogoutErrorMessage,usernameForLogin,setUsernameForLogin}) => {
    const dispatch = useDispatch();
    let [forceFulLogoutOtp, setForceFulLogoutOtp] = useState(false);
    const { logoutOtpData } = useSelector(state => state.loginReducer, shallowEqual);
    const { register, errors, handleSubmit } = useForm({
        mode: 'onChange',
    });

    return (
        <form  className="ForcefullyLogoutForm mt50">
            <div>
            { forceFulLogoutOtp==false ? 
            <Modal show={forceFulLogout} onHide={() => { setForceFulLogout(false); setForceFulLogoutErrorMessage(false); setForceFulLogoutOtp(false);}} backdrop='static'>
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title>{login.FORCEFUL_LOGOUT}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{forceFulLogoutErrorMessage}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => { setForceFulLogout(false);setForceFulLogoutErrorMessage(false);setUsernameForLogin(false); }}>{login.CANCEL_BUTTON}</button>                 
                    <button type="button" className="btn btn-primary" onClick={() => { setForceFulLogout(true);setForceFulLogoutErrorMessage(true);setForceFulLogoutOtp(true);dispatch(logOutUserOtp(usernameForLogin));}}>{login.LOGOUT_OTHER_DEVICE_BUTTON}</button> 
                </Modal.Footer>
            </Modal> 
            : 
            <Modal show={forceFulLogout} onHide={() => { setForceFulLogout(false); setForceFulLogoutErrorMessage(false);setForceFulLogoutOtp(false);setUsernameForLogin(false);}} backdrop='static'>
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>{login.FORCEFUL_LOGOUT_OTP}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                        <label className="LeftText">{login.MOBILE_OTP_INPUT_LABEL}<span className="text-danger"> *</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2" />
                            <div className="input-group-text">
                                <button className="btn btn-secondary f12 border_radius" type="button" id="button-addon2" onClick={handleSubmit((data) => {dispatch(verificationOfLogoutOTP(data,logoutOtpData))})} >{login.VERIFY_OTP_BUTTON}</button>
                            </div>
                        </div>
                        {_.get("OTP_email.type", errors) === "required" && (
                            <p className="errorText">{login.validationMessage.OTP_REQ}</p>
                        )}
                </div> 
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={() => {setForceFulLogoutOtp(false); setForceFulLogout(false);setForceFulLogoutErrorMessage(false);setUsernameForLogin(false); }}>{login.CANCEL_BUTTON}</button>
            </Modal.Footer>
            </Modal>
            }
            </div>
        </form>
)
}

export default ForcefullyLogoutModal;