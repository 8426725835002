/* eslint-disable */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import '../../styles/registration.css';
import '../../styles/profile.css'
import RegistrationJson from "../labels/registration_en.json";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash/fp";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { mobile, numberValidation, securityQuestionRegex } from '../../common/constants/regex'
import { getSecurityQuestions, registerCandidate } from "../actions/registrationActions";
import ModuleHide from "../../common/components/ModuleHide";
import "react-datepicker/dist/react-datepicker.css";
import { email } from "../../common/constants/regex";
import { generateCaptcha, generateOTP, verificationOfOTP, enableOTPButton, checkModuleEnableDisable} from "../../common/actions/commonActions";
import PasswordMeter from "../../common/components/PasswordMeter";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import "../../styles/advertisementTable.css";
import "../../styles/home.css";
import commonLabels from "../../my_account/labels/common_en.json";
import { OAS_API_BASE_URL } from "../../configuration";
import { useNavigate } from 'react-router-dom';

function Registration(props) {
  const { register, handleSubmit, watch, formState, errors, control, setError, setValue } = useForm({
    mode: "onChange"
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSecurityQuestions());
    dispatch(generateCaptcha());
  }, []);

  const questions = useSelector(state => state.registration.securityQuestions);
  const captchaId = useSelector(state => state.common.captchaId);
  const [confirmPasswordFlag, setConfirmPasswordFlag] = useState(false)
  const [otpDisableForEmail, setotpDisableForEmail] = useState(false)
  const [otpDisableForMobile, setotpDisableForMobile] = useState(false)
  const [selectedtype,setSelectedType]=useState();
  let { emailOTPResponse, mobileOTPResponse, moduleDetails, isApiCallForModule, mobileNoAlreadyExistFlg, emailIdAlreadyExistFlg} = useSelector(state => state.common, shallowEqual);
  const verifyOTPFlagRegistrationEmail = useSelector(state => state.common.verifyOTPFlagRegistrationEmail)
  const verifyOTPFlagRegistrationMobile = useSelector(state => state.common.verifyOTPFlagRegistrationMobile)
  const passwordEntered = watch("password");
  const userName = watch("userName");
  const mobileNumber = watch("mobileNumber");
  const {emailOtpData,mobileOtpData} = useSelector(state=> state.common,shallowEqual)
  let enteredEmailOtp = watch("otp_email");
  let enteredMobileOtp = watch("otp_mobile");
  const currentDomain = window.location.hostname;
  const matchingBaseUrl = OAS_API_BASE_URL.find(item => item.key === currentDomain)?.baseUrl;
  const captchaImageUrl = `${matchingBaseUrl}/oas/api/v1/otps/${captchaId}`;

  useEffect(()=>{
    if(!isApiCallForModule){
        dispatch(checkModuleEnableDisable("USER_REGISTRATION"));
    }},[isApiCallForModule]);
    
  const startTimerMail = parseInt(emailOtpData.expiryDuration);
  const startTimerMob = parseInt(mobileOtpData.expiryDuration);

  useEffect(()=>{
    startTimeCount(selectedtype);
  },[emailOtpData])
  useEffect(()=>{
    startTimeCount(selectedtype);
  },[mobileOtpData])
  
  useEffect(()=>{
    if(mobileNoAlreadyExistFlg){
        setotpDisableForMobile(false);
    }
  },[mobileNoAlreadyExistFlg]);
  
  useEffect(()=>{
    if(emailIdAlreadyExistFlg){
        setotpDisableForEmail(false);
    }
  },[emailIdAlreadyExistFlg]);

    const startTimeCount = (type) => {
      if(type === "email"){
        let start = startTimerMail;
        function displayTime() {
            let end = start - 1;
            start = end;
            let element = document.getElementById('emailTimer');
            if (element) {
                element.textContent = start;
            }
            if (start == 0 ) {
              clearInterval(createClock);
              setotpDisableForEmail(false);
            }
        }
        const createClock = setInterval(displayTime, 1000); 
      };

      if(type === "mobile"){
        let start = startTimerMob;
        function displayTime() {
            let end = start - 1;
            start = end;
            let element = document.getElementById('mobileTimer');
            if (element) {
                element.textContent = start;
            }
            if (start == 0 ) {
              clearInterval(createClock);
              setotpDisableForMobile(false);
            }
        }
        const createClock = setInterval(displayTime, 1000);     
      }     
    }
  
  const getOTP = (email, mobile, type) => {
    dispatch(generateOTP({
      "email": email,
      "mobile": mobile,
      "type": type,
      "otpFor": "register",
      "getOtpOnlyFor": "email",
      "isForgotPassword": false
    }))
    if(email != ""){
      dispatch(enableOTPButton('registration-email'))
      setValue("otp_email", "", {shouldDirty: true})
      setotpDisableForEmail(true)
      setSelectedType(type); 
    } 
    if(mobile != ""){  
      dispatch(enableOTPButton('registration-mobileNumber'))
      setValue("otp_mobile", "", {shouldDirty: true})
      setotpDisableForMobile(true)
      setSelectedType(type);
    }
    setValue("password", "", {shouldDirty: true})
    setValue("confirmPassword", "", {shouldDirty: true})
  }

  const checkValidity = (evt) => {
    if (evt.target.value === watch('password')) {
      setConfirmPasswordFlag(false)
    } else {
      setConfirmPasswordFlag(true)
    }
  };

  const verificationOTP = () => {
    let reqBody = {}
    if(enteredEmailOtp){
      reqBody.username = watch('userName')
      reqBody.enteredOtp = enteredEmailOtp
      reqBody.otpId = emailOTPResponse.id
      reqBody.otpType = emailOTPResponse.type
      dispatch(verificationOfOTP(reqBody, 'registration-email'))
    }else{
      setError("otp_email", {type: "required"});
    }
  }

  const verificationOfOTPOnMobile = () => {
    let reqBody = {}
    if(enteredMobileOtp){
      reqBody.username = watch('mobileNumber')
      reqBody.enteredOtp = enteredMobileOtp
      reqBody.otpId = mobileOTPResponse.id
      reqBody.otpType = mobileOTPResponse.type
      dispatch(verificationOfOTP(reqBody, 'registration-mobileNumber'))
    }else{
      setError("otp_mobile", {type: "required"});
    }
  };
  
  return moduleDetails.isEnabled == false ?  < ModuleHide details={moduleDetails}/> :
  (
    <form onSubmit={handleSubmit((data) => { dispatch(registerCandidate(data, props, captchaId, emailOTPResponse, mobileOTPResponse, enteredEmailOtp, enteredMobileOtp, navigate)) })} className="mt-3">
      <div className="container">
        <div className="row mb35">
          <div className="col-md-2"></div>
          <div className="col-md-8 border border-light bg-white p-3">
            <div className="text-center p-4">
              <span className="bold-text">{RegistrationJson.Register}</span>
            </div>
            <div className="col-md-12 background-field">
              <span className="errorText text-primary ms-3" >{RegistrationJson.FIELD_MANDATORY_NOTE}</span>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="LeftText">{RegistrationJson["Username_(Email-ID)"]}<span className="errorText">*</span></label>
                <div className="input-group">
                  <input className="form-control" id="registrationFormControlInput1" type="text" name="userName" ref={register({ required: true, maxLength: 320, pattern: email })} />
                  <div className="input-group-text">
                  <button className="btn btn-secondary f12 rounded-0" type="button" id="button-addon2" onClick={() => { getOTP(userName, "", "email") }} 
                  disabled={(_.get("userName.type", errors) ? true : false) || otpDisableForEmail }>{RegistrationJson.Get_OTP}</button>
                  </div>
                </div>
                {_.get("userName.type", errors) === "required" && (
                  <p className="errorText">{RegistrationJson.validationMessage.EMAIL_REQUIRED}</p>
                )}
                {_.get("userName.type", errors) === "maxLength" && (
                  <p className="errorText">{RegistrationJson.validationMessage.EMAIL_MAX_LENGTH}</p>
                )}
                {_.get("userName.type", errors) === "pattern" && (
                  <p className="errorText">{RegistrationJson.validationMessage.EMAIL_PATTERN}</p>
                )}
                {otpDisableForEmail && verifyOTPFlagRegistrationEmail == false? <p className="Note">{RegistrationJson.OTP_NOTE} <span id="emailTimer"></span> {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE} <span id="emailTimer"></span> {RegistrationJson.SECONDS}</p>}
              </div>

              <div className="form-group col-md-6">
                <label className="LeftText">{RegistrationJson.Mobile_Number}<span className="errorText">*</span></label>
                <div className="input-group">
                  <input type="text" className="form-control" id="registrationFormControlInput2" name="mobileNumber" ref={register({ required: true, pattern: mobile })} />
                  <div className="input-group-text">
                    <button className="btn btn-secondary f12 rounded-0" type="button" id="button-addon2" onClick={() => { getOTP("", mobileNumber, "mobile") }} disabled={(_.get("mobileNumber.type", errors) ? true : false) || otpDisableForMobile} >{RegistrationJson.Get_OTP}</button>
                  </div>
                </div>

                {_.get("mobileNumber.type", errors) === "required" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MOBILE_REQ}</p>
                )}
                {_.get("mobileNumber.type", errors) === "pattern" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MOBILE_MAX_LENGTH_WARNING}</p>
                )}
                {otpDisableForMobile && verifyOTPFlagRegistrationMobile == false ? <p className="Note">{RegistrationJson.OTP_NOTE} <span id="mobileTimer"></span> {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE} <span id="mobileTimer"></span> {RegistrationJson.SECONDS}</p>}
              </div>
              <div className="form-group col-md-6">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label className="LeftText">{RegistrationJson.EMAIL_OTP_INPUT_LABEL}<span className="text-danger">*</span></label>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="" name="otp_email" id="registrationFormControlInput3" maxLength={6} ref={register({ required: true, maxLength: 6 })} aria-label="emailotp" aria-describedby="button-addon2" />
                      <div className="input-group-text">
                        <button className="btn btn-secondary f11 rounded-0" type="button" id="button-addon2" onClick={verificationOTP} disabled={verifyOTPFlagRegistrationEmail}>{RegistrationJson.VERIFY_OTP}</button>
                      </div>
                    </div>
                    {_.get("otp_email.type", errors) === "required" && (
                      <p className="errorText">{RegistrationJson.validationMessage.OTP_REQ}</p>
                    )}
                    {_.get("otp_email.type", errors) === "maxLength" && (
                      <p className="errorText">{RegistrationJson.validationMessage.OTP_MAX_LENGTH_WARNING}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <label className="LeftText">{RegistrationJson.MOBILE_OTP_INPUT_LABEL}<span className="text-danger">*</span></label>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="" name="otp_mobile" id="registrationFormControlInput4" maxLength={6} ref={register({ required: true, maxLength: 6 })} aria-label="mobileotp" aria-describedby="button-addon2" />
                      <div className="input-group-text">
                        <button className="btn btn-secondary f11 rounded-0" type="button" id="button-addon2" onClick={verificationOfOTPOnMobile} disabled={verifyOTPFlagRegistrationMobile}>{RegistrationJson.VERIFY_OTP}</button>
                      </div>
                    </div>
                    {_.get("otp_mobile.type", errors) === "required" && (
                      <p className="errorText">{RegistrationJson.validationMessage.OTP_REQ}</p>
                    )}
                    {_.get("otp_mobile.type", errors) === "maxLength" && (
                      <p className="errorText">{RegistrationJson.validationMessage.OTP_MAX_LENGTH_WARNING}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="LeftText">{RegistrationJson.Password}<span className="errorText">*</span></label>
                <input type="password" className="form-control" id="registrationFormControlInput5" name="password" disabled={!verifyOTPFlagRegistrationMobile && !verifyOTPFlagRegistrationMobile}
                  ref={register({
                    required: true, minLength: 8, maxLength: 15,
                    validate: {
                      oneCapitalLetter: (value) => /(?![.\n])(?=.*[A-Z]).*$/.test(value),
                      oneNumber: (value) => /((?=.*\d)|(?=.*\W+)).*$/.test(value),
                      oneSpecialCharacter: (value) => /(?=.*[!@#\$%\^&\*])(?=.{8,}$)/.test(value),
                    }
                  })} />
                {_.get("password.type", errors) === "required" && (
                  <p className="errorText">{RegistrationJson.validationMessage.PASSWORD_REQ}</p>
                )}
                {_.get("password.type", errors) === "minLength" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MIN_LENGTH_VALIDATION}</p>
                )}
                {_.get("password.type", errors) === "maxLength" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MAX_LENGTH_VALIDATION}</p>
                )}
                {errors.password?.type === "oneCapitalLetter" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MIN_ONE_CAPITAL_LETTER}</p>
                )}
                {errors.password?.type === "oneNumber" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MIN_ONE_NUMBER}</p>
                )}
                {errors.password?.type === "oneSpecialCharacter" && (
                  <p className="errorText">{RegistrationJson.validationMessage.MIN_ONE_SPECIAL_CHAR}</p>
                )}
                <PasswordMeter password={passwordEntered} />
              </div>
              <div className="col-md-6">
                <label className="LeftText">{RegistrationJson.Confirm_Password}<span className="errorText">*</span></label>
                <input type="password" className="form-control" id="registrationFormControlInput6" name="confirmPassword"  disabled={!verifyOTPFlagRegistrationMobile && !verifyOTPFlagRegistrationMobile} ref={register({ required: true, maxLength: 15 })} onChange={checkValidity} />
                {_.get("confirmPassword.type", errors) === "required" && (
                  <p className="errorText">{RegistrationJson.validationMessage.Confirm_Password_is_required}</p>
                )}
                {confirmPasswordFlag && _.get("confirmPassword.type", errors) != "required" ? <p className="errorText">{RegistrationJson.NEW_PASS_AND_CONFIRM_PASS_NOTE}</p> : ''}
              </div>
              <div className="form-group col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label className="LeftText">{RegistrationJson.Date_of_Birth}<span className="errorText">*</span></label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      control={control}
                      name="dob"
                      id="registrationFormControlInput7"
                      rules={{ required: true }}
                      render={({ onChange, value }) =>
                        <DatePicker className="form-control"
                          maxDate={new Date()}
                          selected={fromUTCDate(value)}
                          onChange={(e) => {
                            onChange(toUTCDate(e))
                          }}
                          dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={commonLabels.DATE_FORMAT} />
                      }
                    />
                    {
                      errors.dob && errors.dob.type === "validate" && (
                        <p className="errorText">{RegistrationJson.AGE_NOTE}</p>
                      )
                    }
                    {_.get("dob.type", errors) === "required" && (
                      <p className="errorText">{RegistrationJson.validationMessage.DOB_REQUIRED}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 background-field">
                <span className="errorText text-primary">{RegistrationJson.SECURITY_QUESTIONS_NOTE}</span>
              </div>
              <div className="form-group col-md-12">
                {Object.entries(questions).sort().map(([key, value]) => {
                  return (
                    <div className="row form-group" key={key}>
                      <div className="col-md-6 d-flex align-items-center">
                        <label>{value}<span className="errorText">*</span></label>
                      </div>
                      {key === 'SQ3' ?
                      <div className="col-md-4 otherFormMarginBottom">
                        <input type="text" className="form-control" name={key} id="sq" ref={register({required: true})} />
                        {_.get(key + ".type", errors) === "required" && (
                          <p className="errorText">{RegistrationJson.validationMessage.FIELD_REQ}</p>
                        )}
                      </div>: ""}
                      {key === 'SQ4' ?
                      <div className="col-md-4 otherFormMarginBottom">
                        <input type="text" className="form-control" maxLength={4} name={key} id="sq" ref={register({required: true, pattern:numberValidation})} />
                        {_.get(key + ".type", errors) === "required" && (
                          <p className="errorText">{RegistrationJson.validationMessage.FIELD_REQ}</p>
                        )}
                         {_.get(key + ".type", errors) === "pattern" && (
                          <p className="errorText">{RegistrationJson.validationMessage.PLEASE_ENTER_VALID_INPUT}</p>
                        )}
                      </div>: ""}
                      {key === ('SQ1') || key === ('SQ2') || key === ('SQ5') || key === ('SQ6')?
                      <div className="col-md-4 otherFormMarginBottom">
                        <input type="text" className="form-control" name={key} id="sq" ref={register({required: true, pattern: securityQuestionRegex})} />
                        {_.get(key + ".type", errors) === "required" && (
                          <p className="errorText">{RegistrationJson.validationMessage.FIELD_REQ}</p>
                        )}
                         {_.get(key + ".type", errors) === "pattern" && (
                          <p className="errorText">{RegistrationJson.validationMessage.PLEASE_ENTER_VALID_INPUT}</p>
                        )}
                      </div>: ""}
                    </div>)
                }
                )}
              </div>
            </div>
            { verifyOTPFlagRegistrationEmail && verifyOTPFlagRegistrationMobile ?  
            <div className="row d-flex justify-content-center">
              <div className="form-group col-md-6 background-field">
                {(captchaId !== 0 && matchingBaseUrl) ? <img src={captchaImageUrl} id="registrationFormControlInput8" /> : (captchaId !== 0) ? <img src={`/oas/api/v1/otps/${captchaId}`} id="registrationFormControlInput8" /> : ""}
                <span className="ms-3"> <i className="fa fa-refresh fa-lg cursor-pointer" onClick={() => { dispatch(generateCaptcha()) }} /></span>
                <label className="LeftText">{RegistrationJson.CAPTCHA_ENTER_COMMAND}<span className="errorText">*</span></label>
                <input type="text" className="form-control" id="registrationFormControlInput9" name="enteredCaptcha" autoComplete="off" ref={register({ required: true })} />
                {_.get("enteredCaptcha.type", errors) === "required" && (
                  <p className="errorText">{RegistrationJson.validationMessage.FIELD_REQ}</p>
                )}
              </div>
            </div> : "" }
            <div className="d-flex justify-content-center">
              <button type="submit" disabled={!formState.isValid} className="btn btn-primary rounded-0">{RegistrationJson.Register}</button>
            </div>
            <hr className="ml15 mr15"></hr>
            <div>
              <div className="d-flex justify-content-center text-dark">{RegistrationJson.AN_ACCOUNT_EXISTS_Q}</div>
              <div className="d-flex justify-content-center bold-text" >
                <Link to="/candidate/login" className="textDecorationCss">{RegistrationJson.Login}</Link></div>
            </div>
          </div>
          <div className="col-md-2 border border-light" >
          </div>
        </div>
      </div>
    </form>
  );
}
export default Registration;