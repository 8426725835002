/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../registration_login/actions/sessionActions";
import "../../styles/home.css";
import { getGuidelineLinks, checkModuleEnableDisable } from "../../common/actions/commonActions";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import forgotPasswordLabels from "../../registration_login/labels/forgot_password_en.json";
import "../../styles/candidate_nav.css";
import { useNavigate } from 'react-router-dom';

const Home = (props) => {
  const navigate = useNavigate();
  let accessToken = useSelector((state) => state.loginReducer.accessToken);
  const session = JSON.parse(sessionStorage.getItem("session"));
  const guidelinesLinks = useSelector((state) => state.common.guidelineLinks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuidelineLinks());
  }, []);

  if (accessToken || (session && session.jwtResponse.token)) {
    return (
      <div>
        <h6 className="d-flex justify-content-center bold-text">
          {RegistrationJson.YOU_ARE_LOGGED_IN}&nbsp;
          <Link
            onClick={() => {
              dispatch(logout(props, true, navigate));
            }}
          >
            {forgotPasswordLabels.LOGOUT}
          </Link>
          &nbsp;{RegistrationJson.TO_VIEW_PAGE_NOTE}&nbsp;
          <Link to="/candidate/main">{RegistrationJson.APPLICATION}</Link>.
        </h6>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="mpscHelpLineDivCss">
            <p className="bold-text text-center">
              {RegistrationJson.MPSE_HELP_LINE}
            </p>
            <p className="mpscHelpLineParaCss"></p>
            <p className="ml7">
              <img
                src="/icons/phone.png"
                className="icons w5"
              ></img>{" "}
              &nbsp;{RegistrationJson.MPSC_HELP_LINE_NOS}
            </p>
            <p className="ml7">
              <img
                src="/icons/email.png"
                className="icons w5"
              ></img>{" "}
              &nbsp;{RegistrationJson.MPSC_HELP_LINE_EMAIL}{" "}
            </p>
            <div className="mt45">
              {RegistrationJson.SUPPORT_TIMING}<br /> {RegistrationJson.MON_TO_FRI_TIME}
              <br /> {RegistrationJson.SAT_TO_SUN_TIME}
            </div>
          </div>
        </div>

        <div
          className="col-md-5 mpscHelpLineDivCss">
          <div className="bold-text text-center">
            {RegistrationJson.GUIELINES_FOR_CANDIDATE}{" "}
          </div>
          <p className="mpscHelpLineParaCss"></p>
          <div className="bounce">
            {
              <p className="lh30">
                {guidelinesLinks &&
                  guidelinesLinks.map((ele, index) => (
                    <span key={index}>
                      <img src="/icons/file-pdf-regular.svg" className="icons w3"></img>
                      &nbsp;
                      <a href={ele.href} target="_blank" className="textDecorationCss">
                        {ele.label}
                      </a>
                      <br />
                    </span>
                  ))}
              </p>
            }
          </div>
        </div>
        <div className="col-md-3">
          <div className="bold-text text-center mpscHelpLineDivCss">
              {RegistrationJson.REGISTRATION}
            <p className="mpscHelpLineParaCss"></p>
            <img src="/icons/registration.jpg" className="icons w30"></img>
            <div className="col-md-12 mt-2">
              <Link to="/candidate/registration" className="f15 textDecorationCss" onClick={()=>{dispatch(checkModuleEnableDisable("USER_REGISTRATION",true))}}>
                {RegistrationJson.NEW_USER_REGISTRATION}
              </Link>
            </div>
            <div className="col-md-12 mt-2">
              <Link to="/candidate/forgotPassword" className="f15 textDecorationCss" onClick={()=>{dispatch(checkModuleEnableDisable("FRGT_RESET_PWD",true))}}>
                {RegistrationJson.FORGOT_OR_RESET_PASSWORD}
              </Link>
            </div>
            <div className="col-md-12 mt-2">
              <Link to="/candidate/forgotEmailMobile" className="f15 textDecorationCss" onClick={()=>{dispatch(checkModuleEnableDisable("FRGT_RESET_EMAIL_MOBILE",true))}}>
                {RegistrationJson.FORGOT_OR_RESET_EMAIL_ID_AND_MOBILE_NUMBER}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
