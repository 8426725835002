/* eslint-disable */
import { toastr } from "react-redux-toastr";
import {SET_VERIFY_DETAILS, SET_SECURITY_ANSWERS_FOR_RESET_EMAIL} from "../../common/constants/ActionTypes";
import {VERIFY_CANDIDATES, UPDATEEMAILMOBILE } from "../../common/constants/endpoints";
import API from "../../helpers/API";
import { enableOTPButton } from "../../common/actions/commonActions";
import { hideLoader, showLoader } from '../../common/actions/commonActions';
import RegistrationJson from "../../registration_login/labels/registration_en.json";

export const setForgotEmailAndMobile = ()=>{
    return async(dispatch)=>{
    dispatch({
        payload: false,
        type: SET_VERIFY_DETAILS,
    });
    }
};

export const verifyCandidateDetails = (requestBody)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
      try {
        response = await API.post(VERIFY_CANDIDATES,requestBody);
        dispatch({
            payload: response.data && response.data.validateStatus,
            type: SET_VERIFY_DETAILS,
        });
        dispatch({
            payload : response.data && response.data.securityAnswerList,
            type : SET_SECURITY_ANSWERS_FOR_RESET_EMAIL
        })
        if(response.data && !response.data.validateStatus){
            toastr.error(response.data.message);
        }
        if(response.data && response.data.validateStatus){
        toastr.success(response.data.message);
        }
        if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch(error){
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }    
    }
};

export const updateEmailAndMobile = (props,requestBody, navigate)=>{
    return async(dispatch)=>{
        dispatch(showLoader());
    let response;
      try {
        response = await API.patch(UPDATEEMAILMOBILE,requestBody);
        dispatch(setForgotEmailAndMobile(false))
        if(requestBody.updateType=='mobile'){
            toastr.success(RegistrationJson.MOBILE_NO_CHANGE_SUCCESSFULLY_NOTE);
        }
        if(requestBody.updateType=='email'){
            toastr.success(RegistrationJson.EMAIL_ID_CHANGE_SUCCESSFULLY_NOTE);
        }
        if(response!=null)
            {
                dispatch(hideLoader());
            }
        // props.history.push("/candidate/login");
        navigate("/candidate/login")
        dispatch(enableOTPButton('login'))
        } catch(error){
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }    
    }
};