/* eslint-disable */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import '../../styles/forgot_password.css';
import '../../styles/profile.css'
import _ from "lodash/fp";
import ForgotPasswordJson from "../labels/forgot_password_en.json";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { emailRegex, mobileRegex } from "../../common/constants/regex";
import DatePicker from "react-datepicker";
import { generateOTPForgotPassword, verificationOfOTP, enableOTPButton, checkModuleEnableDisable } from "../../common/actions/commonActions";
import { submitForgotPassword } from "../actions/forgotPasswordAction";
import ResetPassword from "./ResetPassword";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import ModuleHide from "../../common/components/ModuleHide";
import RegistrationJson from "../labels/registration_en.json";
import "../../styles/candidate_nav.css";
import "../../styles/advertisementTable.css";
import commonLabels from "../../my_account/labels/common_en.json";
import { useNavigate } from 'react-router-dom';

function ForgotPassword(props) {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, errors, control, setError, setValue } = useForm({
        mode: "onChange"
    });
    const verifyOTPFlag = useSelector(state => state.common.verifyOTPFlagForgotPassword)
    const dispatch = useDispatch();
    let { emailOTPResponse, mobileOTPResponse, userNameOTPResponse, moduleDetails, isApiCallForModule} = useSelector(state => state.common, shallowEqual);
    let isShowResetPassword = useSelector(state => state.forgotPasswordReducer.isShowResetPassword)
    const {emailOtpData,mobileOtpData,userNameOtpData} = useSelector(state=> state.common,shallowEqual)
    const startTimerMail = parseInt(emailOtpData.expiryDuration);
    const startTimerMob = parseInt(mobileOtpData.expiryDuration);
    const startTimerUserName = parseInt(userNameOtpData.expiryDuration);
    const [otpDisable, setotpDisable] = useState(false)
    const [emailTimer, setEmailTimer] = useState(0);
    const [selectedtype,setSelectedType]=useState();

    let otpIdResponse = "";
    let otpTypeResponse = "";

    const onSubmit = (data) => {
        let userName;
        if (watch("usertype") === "migrated_username") {
            userName = watch("username")
        } else if (watch("usertype") === "mobile") {
            userName = watch("mobileNo")
        } else if (watch("usertype") === "email") {
            userName = watch("emailId")
        }
        if (emailOTPResponse.id != "" && emailOTPResponse.type != "") {
            dispatch(submitForgotPassword(data, emailOTPResponse, userName))
        }
        if (mobileOTPResponse.id != "" && mobileOTPResponse.type != "") {
            dispatch(submitForgotPassword(data, mobileOTPResponse, userName))
        }
        if (userNameOTPResponse.id != "" && userNameOTPResponse.type != "") {
            dispatch(submitForgotPassword(data, userNameOTPResponse, userName))
        }
        dispatch(enableOTPButton('login'))
    }

    useEffect(() => {
        setValue('usertype', 'migrated_username');
    }, [])

    useEffect(()=>{
        if(!isApiCallForModule){
            dispatch(checkModuleEnableDisable("FRGT_RESET_PWD"));
        }},[isApiCallForModule]);

    useEffect(()=>{
        startTime(selectedtype);
        },[emailOtpData])
        useEffect(()=>{
        startTime(selectedtype);
        },[mobileOtpData])
        useEffect(()=>{
        startTime(selectedtype);
        },[userNameOtpData])

    const startTime =(otpType) =>{
        if(otpType==="emailId"){
        let start = startTimerMail;
        function displayTime() {
            let end = start - 1;
            start = end;
            setEmailTimer(start);
            if (start == 0 ) {
                clearInterval(createClock);
                setotpDisable(false);
            } 
        }
        const createClock = setInterval(displayTime, 1000);   
    }else if(otpType==="username"){
        let start = startTimerUserName;
        function displayTime() {
            let end = start - 1;
            start = end;
            setEmailTimer(start);
            if (start == 0 ) {
                clearInterval(createClock);
                setotpDisable(false);
            } 
        }
        const createClock = setInterval(displayTime, 1000);
    }else if(otpType==="mobileNo"){
        let start = startTimerMob;
        function displayTime() {
            let end = start - 1;
            start = end;
            setEmailTimer(start);
            if (start == 0 ) {
                setotpDisable(false);
                clearInterval(createClock);
            } 
        }
        const createClock = setInterval(displayTime, 1000);
    }
    }

    const getOTP = (otpType) => {
        if (watch("username")) {
            dispatch(generateOTPForgotPassword({
                "username": watch("username").trim(),
                "email": "",
                "mobile": "",
                "type": "username",
                "otpFor": "frgtpass",
                "isForgotPassword": true
            }))
            setotpDisable(true)
            setSelectedType(otpType);
        } else if (watch("emailId")) {
            dispatch(generateOTPForgotPassword({
                "username": "",
                "email": watch("emailId").trim(),
                "mobile": "",
                "type": "email",
                "otpFor": "frgtpass",
                "isForgotPassword": true
            }))
            setotpDisable(true)
            setSelectedType(otpType);
        } else if (watch("mobileNo")) {
            dispatch(generateOTPForgotPassword({
                "username": "",
                "email": "",
                "mobile": watch("mobileNo").trim(),
                "type": "mobile",
                "otpFor": "frgtpass",
                "isForgotPassword": true
            }))
            setotpDisable(true)
            setSelectedType(otpType);
        } else {
            setError(otpType.toString(), { type: "required" });
        }
        setValue("OTP_email", "", { shouldDirty: true })
    }

    const reDirectToLogIn = () => {
        // props.history.push("/candidate/login");
        navigate("/candidate/login")
        dispatch(enableOTPButton('login'))
    }

    const verificationOTP = () => {
        if (watch("OTP_email")) {
            if (emailOTPResponse.id != "" && emailOTPResponse.type != "") {
                otpIdResponse = emailOTPResponse.id
                otpTypeResponse = emailOTPResponse.type
            }
            if (mobileOTPResponse.id != "" && mobileOTPResponse.type != "") {
                otpIdResponse = mobileOTPResponse.id
                otpTypeResponse = mobileOTPResponse.type
            }
            if (userNameOTPResponse.id != "" && userNameOTPResponse.type != "") {
                otpIdResponse = userNameOTPResponse.id
                otpTypeResponse = userNameOTPResponse.type
            }
            let userName;
            if (watch("usertype") === "migrated_username") {
                userName = watch("username")
            } else if (watch("usertype") === "mobile") {
                userName = watch("mobileNo")
            } else if (watch("usertype") === "email") {
                userName = watch("emailId")
            }

            let reqBody = {
                "username": userName.trim(),
                "otpId": otpIdResponse,
                "otpType": otpTypeResponse,
                "enteredOtp": watch("OTP_email")
            }
            dispatch(verificationOfOTP(reqBody, 'forgotPassword'))
        } else {
            setError("OTP_email", { type: "required" });
        }
    }

    return moduleDetails.isEnabled == false ?  < ModuleHide details={moduleDetails}/> :
    (<div>
        {!isShowResetPassword ?
            <form onSubmit={handleSubmit(onSubmit)} className="row mt50 mb100">
                <div className="col-md-3"></div>
                <div className="col-md-6 forgotPassTabCss">
                    <div className="text-center">
                        <span className="bold-text">{ForgotPasswordJson.Forgot_Password_TITLE}</span>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center m-2">
                            <label className="position-relative top12 right30 me-2">
                                <input type="radio" className="radio" name="usertype" value="migrated_username" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Username}
                            </label>
                            <label className="position-relative top12 right30 me-2">
                                <input type="radio" className="radio" name="usertype" value="email" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Email_ID}
                            </label>
                            <label className="position-relative top12 right30 me-2">
                                <input type="radio" className="radio" name="usertype" value="mobile" ref={register({ required: false })} />&nbsp;{ForgotPasswordJson.Mobile_Number}
                            </label>
                        </div>
                    </div>
                    {watch("usertype") === 'migrated_username' ?
                        <div>
                            <label className="LeftText">{ForgotPasswordJson.Username}<span className="errorText">*</span></label>
                            <div className="input-group">
                                <input className="form-control" id="registrationFormControlInput1" type="text" name="username" ref={register({ required: true })} />
                                <div className="input-group-text">
                                    <button className="btn btn-secondary f12 ps-4 border_radius" type="button" id="button-addon2" onClick={() => getOTP("username")} disabled={otpDisable}>{RegistrationJson.Get_OTP}</button>
                                </div>
                            </div>
                            {_.get("username.type", errors) === "required" && (
                                <p className="errorText">{ForgotPasswordJson.validationMessage.USER_NAME_REQUIRED}</p>
                            )}
                            { otpDisable && verifyOTPFlag == false ? <p className="Note">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> }
                        </div> : ""}
                    {watch("usertype") === 'email' ?
                        <div>
                            <label className="LeftText">{ForgotPasswordJson.Email_ID}<span className="errorText">*</span></label>
                            <div className="input-group">
                                <input className="form-control" id="registrationFormControlInput1" type="text" name="emailId" ref={register({ required: true, pattern: emailRegex })} />
                                <div className="input-group-text">
                                    <button className="btn btn-secondary f12 ps-4 border_radius" type="button" id="button-addon2" onClick={() => getOTP("emailId")} disabled={otpDisable || (_.get("emailId.type", errors) === "pattern")}>{RegistrationJson.Get_OTP}</button>
                                </div>
                            </div>
                            {_.get("emailId.type", errors) === "required" && (
                                <p className="errorText">{ForgotPasswordJson.validationMessage.ONLY_EMAIL_REQUIRED}</p>
                            )}
                            {_.get("emailId.type", errors) === "pattern" && (
                                <p className="errorText">{ForgotPasswordJson.validationMessage.EMAIL_PATTERN}</p>
                            )}
                            { otpDisable && verifyOTPFlag == false ? <p className="Note">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> }
                        </div> : ""}
                    {watch("usertype") === 'mobile' ?
                        <div>
                            <label className="LeftText">{ForgotPasswordJson.Mobile_Number}<span className="errorText">*</span></label>
                            <div className="input-group">
                                <input className="form-control" id="registrationFormControlInput1" type="text" name="mobileNo" ref={register({ required: true, pattern: mobileRegex })} />
                                <div className="input-group-text">
                                    <button className="btn btn-secondary f12 ps-4 border_radius" type="button" id="button-addon2" onClick={() => getOTP("mobileNo")} disabled={otpDisable || (_.get("mobileNo.type", errors) === "pattern")}>{RegistrationJson.Get_OTP}</button>
                                </div>
                            </div>
                            {_.get("mobileNo.type", errors) === "required" && (
                                <p className="errorText">{ForgotPasswordJson.validationMessage.MOBILE_REQ}</p>
                            )}
                            {_.get("mobileNo.type", errors) === "pattern" && (
                                <p className="errorText">{ForgotPasswordJson.validationMessage.MOBILE_PATTERN}</p>
                            )}
                            { otpDisable && verifyOTPFlag == false ? <p className="Note">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> : <p className="Note d-none">{RegistrationJson.OTP_NOTE}  {emailTimer} {RegistrationJson.SECONDS}</p> }
                        </div> : ""}
                    <div>
                    <label className="LeftText">{ForgotPasswordJson.EMAIL_MOBILE_INPUT_LABEL}<span className="errorText">*</span></label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2" />
                            <div className="input-group-text">
                                <button className="btn btn-secondary f12 border_radius" type="button" id="button-addon2" onClick={verificationOTP} disabled={verifyOTPFlag}>{RegistrationJson.VERIFY_OTP}</button>
                            </div>
                        </div>
                        {_.get("OTP_email.type", errors) === "required" && (
                            <p className="errorText">{ForgotPasswordJson.validationMessage.OTP_REQ}</p>
                        )}
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="LeftText">{ForgotPasswordJson.Date_of_Birth}<span className="errorText"> *</span></label>
                            </div>
                        </div>
                        <div className="row ms-0 pe-2">
                            <Controller
                                control={control}
                                name="DateOfBirt"
                                id="registrationFormControlInput7"
                                rules={{ required: true }}
                                render={({ onChange, value }) =>
                                    <DatePicker className="form-control" maxDate={new Date()} selected={fromUTCDate(value)}
                                        onChange={(e) => {
                                            onChange(toUTCDate(e))
                                        }} dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                                        showMonthDropdown
                                        showYearDropdown
                                        placeholderText={commonLabels.DATE_FORMAT} />}
                            />
                        </div>
                        {_.get("DateOfBirt.type", errors) === "required" && (
                            <p className="errorText">{ForgotPasswordJson.validationMessage.DOB_REQUIRED}</p>
                        )}
                    </div>
                    <br></br>
                    <div className="buttonAlign">
                        <button type="submit" className="btn btn-primary me-1" disabled={!verifyOTPFlag}>{ForgotPasswordJson.Submit}</button>
                        <button type="button" className="btn btn-primary" onClick={reDirectToLogIn}>{ForgotPasswordJson.CANCEL}</button>
                    </div>
                </div>
            </form> : <ResetPassword {...props} />}
    </div>
    );
}
export default ForgotPassword;