/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import common from "../labels/common_en.json"
import { Modal } from 'react-bootstrap';
import PasswordMeter from "../../common/components/PasswordMeter";
import { setChangePasswordFlg ,ChangePasswordRequest} from "../actions/myAccountAction";
import ModuleHide from "../../common/components/ModuleHide";
import { useNavigate } from 'react-router-dom';

function ChangePassword(props) {
    const { register, watch, errors,formState, handleSubmit } = useForm({
        mode: 'onChange',
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { changePasswordModalFlg } = useSelector(state => state.myaccount, shallowEqual);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const passwordEntered = watch("password");
    const confirmpassword = watch('confirm_password');
    const [confirmPasswordFlag, setConfirmPasswordFlag] = useState(false)
    const [passwordFlag, setPasswordFlag] = useState(false)
    const [currntPasswordFlag, setCurrentPasswordFlag] = useState(true)
    const [arraymModule , setarraymModule] = useState({})

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "CHANGE_PWD")[0])
    },[moduleList])

    const session = JSON.parse(sessionStorage.getItem("session"));
    let profileId = session.profileId;
    let userId = session.userId;

    const modalCancel = () => {
        dispatch(setChangePasswordFlg(false))
        setConfirmPasswordFlag(false)
        setPasswordFlag(false)
        setCurrentPasswordFlag(true)
    }

    const checkValidity = (evt) => {
        if (evt.target.value === watch('password')) {
            setConfirmPasswordFlag(false)
        } else {
            setConfirmPasswordFlag(true)
        }
        if (evt.target.value === watch('confirm_password')) {
            setPasswordFlag(false)
        } else {
            setPasswordFlag(true)
        }
    };

    const checkValidityForSamepasswaord = (evt) => {
        checkValidity(evt);
        if (evt.target.value === watch('currentpassword')) {
            setCurrentPasswordFlag(false)
        } else {
            setCurrentPasswordFlag(true)
        }
    };

    const onSubmit = (data) => {
        if(!confirmPasswordFlag && currntPasswordFlag && !passwordFlag ){
            let reqBody = {
                oldPassword: data.currentpassword,
                newPassword: data.password,
                userId:userId,
                profileId:profileId,
            }
            dispatch(ChangePasswordRequest(reqBody,props, navigate))
        }
    };

    return (
        <form  className="ChangePasswordForm mt50">
            <div>
                <Modal show={changePasswordModalFlg} onHide={modalCancel} backdrop='static'>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title>{common.CHANGEPwd}</Modal.Title>
                    </Modal.Header>
                    {arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
                    <div>
                        <Modal.Body>
                            <div className="form-group">
                                <label className="LeftText">{common.Old_Password}<span className="errorText"> *</span></label>
                                <input className="form-control" id="registrationFormControlInput1" type="password" name="currentpassword" ref={register({ required: true })}  />
                                {_.get("current_password.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.PASSWORD_REQ}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Password}<span className="errorText">*</span></label>
                                <input type="password" className="form-control" id="registrationFormControlInput5" name="password"
                                    ref={register({
                                        required: true, minLength: 8, maxLength: 15,
                                        validate: {
                                            oneCapitalLetter: (value) => /(?![.\n])(?=.*[A-Z]).*$/.test(value),
                                            oneNumber: (value) => /((?=.*\d)|(?=.*\W+)).*$/.test(value),
                                            oneSpecialCharacter: (value) => /(?=.*[!@#\$%\^&\*])(?=.{8,}$)/.test(value),
                                        }
                                    })}   onChange={checkValidityForSamepasswaord}/>
                                {_.get("password.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.PASSWORD_REQ}</p>
                                )}
                                {_.get("password.type", errors) === "minLength" && (
                                    <p className="errorText">{common.validationMessage.MIN_LENGTH_VALIDATION}</p>
                                )}
                                {_.get("password.type", errors) === "maxLength" && (
                                    <p className="errorText">{common.validationMessage.MAX_LENGTH_VALIDATION}</p>
                                )}
                                {errors.password?.type === "oneCapitalLetter" && (
                                    <p className="errorText">{common.validationMessage.MIN_ONE_CAPITAL_LETTER}</p>
                                )}
                                {errors.password?.type === "oneNumber" && (
                                    <p className="errorText">{common.validationMessage.MIN_ONE_NUMBER}</p>
                                )}
                                {errors.password?.type === "oneSpecialCharacter" && (
                                    <p className="errorText">{common.validationMessage.MIN_ONE_SPECIAL_CHAR}</p>
                                )}
                                {!currntPasswordFlag && _.get("password.type", errors) != "required" ? <p className="errorText">{common.OLD_PASS_AND_NEW_PASS_SHOULD_NOT_BE_SAME}</p> : ''}
                                {passwordFlag && confirmpassword && _.get("password.type", errors) != "required" ? <p className="errorText">{common.NEW_PASS_AND_CONFIRM_PASS_SHOULD_BE_SAME}</p> : ''}
                                <PasswordMeter password={passwordEntered} />
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Confirm_Password}<span className="errorText"> *</span></label>

                                <input className="form-control" id="registrationFormControlInput2" type="password" name="confirm_password" ref={register({ required: true })}
                                    onChange={checkValidity}
                                />
                                {_.get("confirm_password.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.CONFIRM_PASSWORD_REQUIRED}</p>
                                )}
                                {confirmPasswordFlag && _.get("confirm_password.type", errors) != "required" ? <p className="errorText">{common.NEW_PASS_AND_CONFIRM_PASS_SHOULD_BE_SAME}</p> : ''}
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button"  disabled={!formState.isValid } className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })}>{common.CONFIRM}</button>
                            <button type="button" className="btn btn-secondary" onClick={modalCancel}>{common.CANCEL}</button>
                        </Modal.Footer>
                    </div> }
                </Modal>
            </div>
        </form>
    );
}
export default ChangePassword;