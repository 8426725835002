/* eslint-disable */
import '../../styles/application_header.css';
import HomePageNav from './HomePageNav';

export default function ApplicationHeader(props) {
    return (
        <div>
          <nav className="navbar navbar-expand-xxl navbar-dark bg-color-first row"></nav>
            <div className="row" >
              <img src="/logos/banner.png" width="100%" className='mpscImgCss'/>
            </div>
          <HomePageNav {...props}/>
        </div>
    )
};