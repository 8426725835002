import React from "react";
import { Modal } from 'react-bootstrap';
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

const DocumentList = (props) => {
    const docData = props.docData
    const text = docData.replace(/(?:\\[rn])+/g, " ").split(".")
    const arrayOfText = [...text]

    const cancelModal = () => {
        props.setshowDocList(false)
    }

    return (
        <div>
            <Modal size="md" show={props.showModal} onHide={cancelModal} aria-labelledby="example-modal-sizes-title-lg" backdrop='static'>
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title>{advertisementDetailsLabel.DOCUMENT_LIST}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {arrayOfText.map((ele, i) => (
                        <div key={i}>
                            {ele.includes(':-') ? <p >{ele}</p> :
                                <p >{ele}.</p>}
                        </div>
                    ))}
                </Modal.Body>

                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelModal}>{commonLabels.OK}</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DocumentList;
