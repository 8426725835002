/* eslint-disable */
import React, { useState } from "react";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import { Modal } from 'react-bootstrap';
import common from "../labels/common_en.json"
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { optingOutOtp, verificationOfOTP, optOutFromApplication} from "../actions/myAccountAction";
import "../../styles/advertisementTable.css";

function OptingOutModal(props) {
    const { register, errors, watch, setError, formState } = useForm({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const { profileUpdateEmailAndMobileOtpResponse, verifyEmailOtpFlag, verifyMobileOtpFlag } = useSelector(state => state.myaccount, shallowEqual);
    const session = JSON.parse(sessionStorage.getItem("session"));
    const emailId = session && session.emailId;
    const mobileNumber = session && session.mobileNumber;

    function getMobileMask(mobileno) {
        if (mobileno) {
            const mobilenumber = mobileno.toString();
            const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(3, 8), 'XXXXXX')
            return maskMobileNumber
        }
    };

    const verificationEmailOTP = (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele) => {
            if (ele.type === type) {
                otpid = ele.id;
                otptype = ele.type
            }
        })
        if (watch("OTP_email") && otptype === 'email') {
            reqBody = {
                "username": emailId,
                "otpId": otpid,
                "otpType": otptype,
                "enteredOtp": watch("OTP_email")
            }
            dispatch(verificationOfOTP(reqBody, 'email'))
        } else {
            setError("OTP_email", { type: "required" });
        }
    };

    const verificationMobileOTP = (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele) => {
            if (ele.type === type) {
                otpid = ele.id;
                otptype = ele.type
            }
        })
        if (watch("OTP_mobile") && otptype === 'mobile') {
            reqBody = {
                "username": emailId,
                "otpId": otpid,
                "otpType": otptype,
                "enteredOtp": watch("OTP_mobile")
            }
            dispatch(verificationOfOTP(reqBody, 'mobile'))
        } else {
            setError("OTP_mobile", { type: "required" });
        }
    };

    const modalCancel = () => {
        props.setOptOutModal(false);
        setShowVerifyModal(false);
    };

    const handleConfirmModalClick = () => {
        props.setOptOutModal(false);
        setShowVerifyModal(true);
        dispatch(optingOutOtp())
    };

    const HideVerifyModal = () => {
        setShowVerifyModal(false);
    };

    const handleSubmitClick = () => {
        let appNo = props.applicationNo;
        dispatch(optOutFromApplication(appNo,profileUpdateEmailAndMobileOtpResponse));
        setShowVerifyModal(false);
        props.setOptOutModal(false);
    };

    return (
        <form className="MarksheetModel mt50">
            <div>
                <Modal centered show={props.showModal} onHide={modalCancel} backdrop='static'>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title> {common.OPT_OUT} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label> {common.EXIT_FROM_APPLICATION} </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={modalCancel}> {common.CANCEL} </button>
                        <button className="btn btn-primary" onClick={handleConfirmModalClick} disabled={(props && props.isReadOnlyOptingOutStage)}>{common.CONFIRM} </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={showVerifyModal} onHide={HideVerifyModal} backdrop='static' centered>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title> {common.OPT_OUT} </Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div>
                                <span className="Note-color"> {common.UPDATE_PROFILE_NOTE} </span>
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Email_OTP} {'('}{emailId ? emailId.replace(emailId.substring(2, 12), 'XXXXXX') : ""}{')'}<span className="text-danger"> *</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2" disabled={verifyEmailOtpFlag} />
                                    <div className="input-group-text">
                                        <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { verificationEmailOTP('email') }} disabled={verifyEmailOtpFlag}>{common.VERIFY_OTP}</button>
                                    </div>

                                </div>
                                {_.get("OTP_email.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Mobile_OTP} {getMobileMask('(' + mobileNumber + ')')}<span className="text-danger"> *</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" maxLength={6} name="OTP_mobile" ref={register({ required: true })} aria-label="mobileotp" aria-describedby="button-addon2" disabled={verifyMobileOtpFlag} />
                                    <div className="input-group-text">
                                        <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { verificationMobileOTP('mobile') }} disabled={verifyMobileOtpFlag}>{common.VERIFY_OTP}</button>
                                    </div>
                                </div>
                                {_.get("OTP_mobile.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={HideVerifyModal} >{common.CANCEL}</button>
                            <button className="btn btn-primary" disabled={(!(verifyMobileOtpFlag && verifyEmailOtpFlag && formState.isValid))} onClick={handleSubmitClick}>{common.CONFIRM} </button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </form>
    );
}
export default OptingOutModal;