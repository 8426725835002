/* eslint-disable */
import React, { useEffect } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { objectionModuleCandidateHeaders } from "../labels/common_en.json";
import { useForm } from "react-hook-form";
import Labels from '../../candidate_profile_creation/labels/adress_information_en.json';
import _ from "lodash/fp";
import { dropdownDataObjReq, addObjectionReq, editObjectionReq, getObjReqDataById, getObjectionQuestions, getObjReqData} from "../actions/myAccountAction";
import Objection from "./Objection";
import { GET_OBJ_REQ_DATA } from "../../common/constants/ActionTypes";
import "../../styles/objection_req.css";
import myAccountLabel from "../labels/my_account_en.json";
import commonLabels from "../labels/common_en.json";
import { toastr } from "react-redux-toastr";
import Select from "react-select";

const AddObjection = (props) => {
  const dispatch = useDispatch();
  const MAX_FILE_SIZE = 200 * 1024;
  const { register, handleSubmit, errors, formState} = useForm({
    mode: "onChange"
  });

  const options = Array.from({ length: 4 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));

  const { dropDownObjReq, applicationDetails, objectionQuestionsList, objReqDataById} = useSelector((state) => state.myaccount, shallowEqual);
  useEffect(() => {
    const advertisementDetails = applicationDetails.advertisementStages.find(
      ele => ele.stage?.code === "ANSWER_KEY_QUESTION_OBJECTION"
    );
    if (advertisementDetails) {
      const advertisementId = advertisementDetails.advertisementId;
      const stageId = advertisementDetails.stage.id;
      dispatch(dropdownDataObjReq(advertisementId, stageId));
    }
  }, []);

  useEffect(()=>{ 
    const advid = applicationDetails.advertisementStages.find(
      ele => ele.stage?.code === "ANSWER_KEY_QUESTION_OBJECTION"
    );
    if(props.examId && props.examPaperSet && props.addObjection && advid && props.examSubjectId) {
      const advertisementId = advid.advertisementId;
      dispatch(getObjectionQuestions(props.examPaperSet,props.examId, props.examSubjectId, advertisementId))
    }
  },[props.examId, props.examPaperSet, applicationDetails])
  
  const getAdvertisementId = () => {
    const advertisementDetails = applicationDetails.advertisementStages.find(
      ele => ele.stage?.code === "ANSWER_KEY_QUESTION_OBJECTION"
    );
    if (advertisementDetails) {
      return advertisementDetails.advertisementId;
    }
    return '';
  };

  const onRefDoc = (e) => {
    const file = e.target.files[0];
    if(file){
      if (file.size > MAX_FILE_SIZE) {
      toastr.error(myAccountLabel.FILE_SIZE_200_KB);
      e.target.value = null;
      return
      };
      if (!file.type.startsWith('image/')) {
        toastr.error(myAccountLabel.FILE_TYPE_IMAGE);
        e.target.value = null;
        return;
      }
      else {
        props.setRefDoc(file);
      }
    }
  };

  const onRefDocChange = (e) => {
    const file = e.target.files[0];
    if(file){
      if (file.size > MAX_FILE_SIZE) {
      toastr.error(myAccountLabel.FILE_SIZE_200_KB);
      e.target.value = null;
      return
      };
      if (!file.type.startsWith('image/')) {
        toastr.error(myAccountLabel.FILE_TYPE_IMAGE);
        e.target.value = null;
        return;
      }
      else {
        props.setRefDoc2(file);
      }
    }
  };

  const handleExamSubjectCode = (subId) => {
    let SubjectCode = dropDownObjReq && dropDownObjReq.examSubjectList && dropDownObjReq.examSubjectList.length > 0 && dropDownObjReq.examSubjectList.find((e) =>{
        if (e.id == subId) {
            return e.code;
        }
    })
    return SubjectCode.code;
};

const handleExamSubjectName = (subId) => {
  let SubjectName = dropDownObjReq && dropDownObjReq.examSubjectList && dropDownObjReq.examSubjectList.length > 0 && dropDownObjReq.examSubjectList.find((e) =>{
      if (e.id == subId) {
          return e.name;
      }
  })
  return SubjectName.name;
};

  const onSubmit = () => {
    const advertisementId = getAdvertisementId(applicationDetails);
    const selectedExamId = props.examId;
    const selectedExamSubId = props.examSubjectId
    const selectedExamSubCode = handleExamSubjectCode(props.examSubjectId);
    const examSubjectName = handleExamSubjectName(props.examSubjectId);
    const prSetId = props.examPaperSet;
    const objectionTypeId = props.objectionType;   
    if (props.addObjection && props.addObjection.Mode === 'Add') {
      let reqBody = {
        "objectionRequestsDto": {
          "applicationId": applicationDetails.application.id,
          "advtId": advertisementId,
          "examId": selectedExamId,
          "examSubjectId": selectedExamSubId,
          "examPaperSet": prSetId,
          "objectionType": objectionTypeId,
          "candidateSetQuestionSrno": props.candidateSetQuestionSerialNumber,
          "candidateAnswer": props.candidateAnswer,
          "answerInAnswerKey": props.answerInAnswerKey,
          "objectionRemark": props.objectionRemark,
          "referenceBook": props.refBook,
          "authorName": props.authorName,
          "applicationNo": applicationDetails.application.applicationNo,
          "examSubjectCode": selectedExamSubCode,
          "referenceDocumentFile": props.refDoc,
          "referenceDocumentFile2": props.refDoc2,
          "examSubjectName":examSubjectName,
        }
      }
      dispatch(addObjectionReq(reqBody, props.setAddObjection));
    } else if (props.addObjection && props.addObjection.Mode === 'Edit') {
      let reqBody = {
        "objectionRequestsDto": {
          "id": props.addObjection.id,
          "applicationId": applicationDetails.application.id,
          "advtId": advertisementId,
          "examId": selectedExamId,
          "examSubjectId": selectedExamSubId,
          "examPaperSet": prSetId,
          "objectionType": objectionTypeId,
          "candidateSetQuestionSrno": props.candidateSetQuestionSerialNumber,
          "candidateAnswer": props.candidateAnswer,
          "answerInAnswerKey": props.answerInAnswerKey,
          "objectionRemark": props.objectionRemark,
          "referenceBook": props.refBook,
          "authorName": props.authorName,
          "applicationNo": applicationDetails.application.applicationNo,
          "examSubjectCode": selectedExamSubCode,
          "referenceDocumentFile": props.refDoc,
          "referenceDocumentFile2": props.refDoc2,
          "examSubjectName":examSubjectName,
        }
      }
      dispatch(editObjectionReq(props.addObjection.id, reqBody, props.setAddObjection));
    }
    props.setAddObjection({"Mode":false});
    delete objReqDataById.objectionRequestsDto;
  };

  const onCancelButton = () => {
    props.setExamId(''); props.setExamSubjectId(''); props.setExamPaperSet(''); props.setPaymentRefNum('');
    props.setObjectionType('');
    props.setCandidateSetQuestionSerialNumber('');
    props.setCandidateAnswer('');
    props.setAnswerInAnswerKey('');
    props.setMasterQueSrNum('');
    props.setMasterAnswerKey('');
    props.setObjectionRemark('');
    props.setRefBook('');
    props.setAuthorName('');
    props.setRefDoc('');
    props.setRefDoc2('');
    props.setAddObjection({"Mode":false});
    delete objReqDataById.objectionRequestsDto;
    dispatch(getObjReqData(applicationDetails.application.id));
  };

  const handleExamIdChange = (e) => {
    const selectedExamId = e.target.value;
    props.setExamId(selectedExamId);
  };

  useEffect(() => {
    if (props.addObjection && props.addObjection.Mode === 'Add') {
      props.setExamSubjectId('');
      props.setExamId('');
      props.setExamPaperSet('');
      props.setPaymentRefNum('');
      props.setObjectionType('');
      props.setCandidateSetQuestionSerialNumber('');
      props.setCandidateAnswer('');
      props.setAnswerInAnswerKey('');
      props.setMasterQueSrNum('');
      props.setMasterAnswerKey('');
      props.setObjectionRemark('');
      props.setRefBook('');
      props.setAuthorName('');
      props.setRefDoc('');
      props.setRefDoc2('');
    }
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && dispatch(getObjReqDataById(props.addObjection.id));
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setApplicationId(applicationDetails.application.id);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setAdvertisementId(getAdvertisementId(applicationDetails));
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setExamSubjectId(props.addObjection.examSubjectId);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setExamId(props.addObjection.examId);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setExamPaperSet(props.addObjection.examPaperSet);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setObjectionType(props.addObjection.objectionType);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setCandidateSetQuestionSerialNumber(props.addObjection.candidateSetQuestionSerialNumber);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setCandidateAnswer(props.addObjection.candidateAnswer);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setAnswerInAnswerKey(props.addObjection.answerInAnswerKey);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setPaymentRefNum(props.addObjection.paymentRefNum);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setMasterQueSrNum(props.addObjection.masterQueSrNum);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setMasterAnswerKey(props.addObjection.masterAnswerKey);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setObjectionRemark(props.addObjection.objectionRemark);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setRefBook(props.addObjection.refBook);
    props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 && props.setAuthorName(props.addObjection.authorName);

    dispatch({
      type: GET_OBJ_REQ_DATA,
      payload: {}
    })
  }, [props.addObjection])

  const handleExamPaperSetChange = (e) => {
      props.setExamPaperSet(e.target.value);
      delete objectionQuestionsList.objectionQuestions;
  };

  return ( (props.addObjection && props.addObjection.Mode === false) ||  props.Mode === false) ? (
    <div>
      <Objection
        setAddObjection={props.setAddObjection}
      />
    </div>) : (
    <div>
      <div className="d-inline-grid">
        <form>
          <div className="border border-light text-center w-100">
            <div className='form-group'>
              <div className='row align-items-center'>
                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.EXAM_NAME}<span className="redColor">*</span></label>
                  <select name="examId" id=""
                    value={props.examId}
                    className='form-control'
                    onInput={handleExamIdChange}
                    ref={register({ required: true })}>
                    <option key={0} value="">{myAccountLabel.SELECT}</option>
                    {dropDownObjReq && dropDownObjReq.examsList && dropDownObjReq.examsList.map((exam) => (
                      <option key={exam.id} value={exam.id}>
                        {exam.name}
                      </option>
                    ))}
                  </select>
                  {_.get("examId.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.EXAM_SUBJECT_NAME}<span className="redColor">*</span></label>
                    <select 
                      name="examSubjectId"
                      value={props.examSubjectId}
                      className='form-control'
                      onInput={(e) => {props.setExamSubjectId(e.target.value); }}
                      ref={register({ required: true })}>
                      <option key={0} value="">{myAccountLabel.SELECT}</option>
                      {dropDownObjReq && dropDownObjReq.examSubjectList && dropDownObjReq.examSubjectList.map((exam) => (
                        <option key={exam.id} value={exam.id}>
                          {exam.name}
                        </option>
                      ))}
                    </select>
                  {_.get("examSubjectId.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.EXAM_PAPER_SET}<span className="redColor">*</span></label>
                    <select
                      name="examPaperSet"
                      value={props.examPaperSet}
                      className='form-control'
                      onInput={(e) => { handleExamPaperSetChange(e)}} ref={register({ required: true })}>
                      <option key={0} value="">{myAccountLabel.SELECT}</option>
                      {dropDownObjReq && dropDownObjReq.examPaperSetList && dropDownObjReq.examPaperSetList.map((paperSet) => (
                        <option key={paperSet.id}value={paperSet.id}>
                          {paperSet.label}
                        </option>
                      ))}
                    </select>
                  {_.get("examPaperSet.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.OBJ_TYPE}<span className="redColor">*</span></label>
                  <select
                    name="objectionType"
                    className='form-control'
                    value={props.objectionType}
                    onInput={(e) => { props.setObjectionType(e.target.value); }} ref={register({ required: true })}>
                    <option key={0} value="">{myAccountLabel.SELECT}</option>
                    {dropDownObjReq && dropDownObjReq.objectionTypeList && dropDownObjReq.objectionTypeList.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                  {_.get("objectionType.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
        
                <div className='col-md-4'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.CANDIDATE_SET_QUESTION_SR_NUM}<span className="redColor">*</span></label>
                    <select
                      name="candidateSetQuestionSerialNumber"
                      className='form-control mt-1'
                      value={props.candidateSetQuestionSerialNumber}
                      onInput={(e) => { props.setCandidateSetQuestionSerialNumber(e.target.value); }} ref={register({ required: true })}>
                      <option key={0} value="">{myAccountLabel.SELECT}</option>
                      {objectionQuestionsList && objectionQuestionsList.objectionQuestions && JSON.parse(objectionQuestionsList.objectionQuestions) && JSON.parse(objectionQuestionsList.objectionQuestions).map((questionId, index) => (
                      <option key={index + 1} value={questionId}>
                        {questionId}
                      </option>
                    ))}
                    </select>
                  {_.get("candidateSetQuestionSerialNumber.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.CORRECT_ANSWER_AS_PER_ANSWER_KEY}<span className="redColor">*</span></label>
                    <select
                      name="answerInAnswerKey"
                      value={props.answerInAnswerKey}
                      className='form-control'
                      onInput={(e) => { props.setAnswerInAnswerKey(e.target.value); }} ref={register({ required: true })}>
                      <option key={0} value="">{myAccountLabel.SELECT}</option>
                      {Array.from({ length: 4 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  {_.get("answerInAnswerKey.type", errors) === "required" && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                <div className='col-md-4 mt-2'>
                  <div className="d-flex flex-column">
                    <label className="float-start fw-bold text-start">
                      {objectionModuleCandidateHeaders.CANDIDATE_ANS}
                      <span className="redColor">*</span>
                    </label>
                    <Select
                      name="candidateAnswer"
                      value={options.filter((option) => props.candidateAnswer.includes(option.value))}
                      options={options}
                      isMulti
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map((option) => option.value);
                        props.setCandidateAnswer(selectedValues);
                      }}
                    />
                  </div>
                  {errors.candidateAnswer?.type === 'required' && (
                    <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.QBJ_REMARK}<span className="redColor">*</span></label>
                    <textarea 
                      name="objectionRemark"
                      type="text"
                      className="form-control"
                      value={props.objectionRemark}
                      ref={register({ required: true })}
                      onChange={(e) => { props.setObjectionRemark(e.target.value) }}
                    />
                    {_.get("objectionRemark.type", errors) === "required" && (
                      <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.REF_BOOK}<span className="redColor">*</span></label>
                    <textarea 
                      name="refBook"
                      type="text"
                      className="form-control"
                      value={props.refBook}
                      ref={register({ required: true })}
                      onChange={(e) => { props.setRefBook(e.target.value) }}
                    />
                    {_.get("refBook.type", errors) === "required" && (
                      <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div>

                <div className='col-md-4 mt-2'>
                  <label className="float-start fw-bold">{objectionModuleCandidateHeaders.AUTH_NAME}<span className="redColor">*</span></label>
                    <input 
                      name="authorName"
                      type="text"
                      className="form-control"
                      value={props.authorName}
                      ref={register({ required: true })}
                      onChange={(e) => { props.setAuthorName(e.target.value) }}
                    />
                    {_.get("authorName.type", errors) === "required" && (
                      <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div>

                {(props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 ) || (objReqDataById && objReqDataById.objectionRequestsDto && objReqDataById.objectionRequestsDto.objectionReqRefDoc )?
                    <div className='col-md-4 mt-2'>
                        <label className="float-start fw-bold">{objectionModuleCandidateHeaders.REF_DOC}</label>
                        <input 
                          name="file"
                          className="float-start"
                          type="file"
                          accept="image/*"
                          ref={register({ required: false })}
                          onChange={(e) => { onRefDoc(e) }}
                        />
                    </div>
                  :
                      <div className='col-md-4 mt-2'>
                        <label className="float-start fw-bold">{objectionModuleCandidateHeaders.REF_DOC}<span className="redColor custom-font-size" >{objectionModuleCandidateHeaders.REF_DOC_MES}*</span></label>
                        <input 
                          name="file"
                          className="float-start"
                          type="file"
                          accept="image/*"
                          ref={register({ required: true })}
                          onChange={(e) => { onRefDoc(e) }}
                        />
                        {_.get("file.type", errors) === "required" && (
                          <p className="errorText float-start">{Labels.MANDATORY_FIELD_MESSAGE}</p>
                        )}
                      </div>
                }
                
                {(props.addObjection && props.addObjection.Mode === 'Edit' && props.addObjection.id > 0 ) || (objReqDataById && objReqDataById.objectionRequestsDto && objReqDataById.objectionRequestsDto.objectionReqRefDoc )?
                    <div className='col-md-4 mt-2'>
                        <label className="float-start fw-bold">{objectionModuleCandidateHeaders.REF_DOC}</label>
                        <input 
                          name="referenceDocumentFile2"
                          className="float-start"
                          type="file"
                          accept="image/*"
                          onChange={(e) => { onRefDocChange(e) }}
                        />
                    </div>
                  :
                  <div className='col-md-4 mt-2'>
                    <label className="float-start fw-bold">{objectionModuleCandidateHeaders.REF_DOC}<span className="redColor custom-font-size" >{objectionModuleCandidateHeaders.REF_DOC_MES}</span></label>
                      <input 
                        name="referenceDocumentFile2"
                        className="float-start"
                        type="file"
                        accept="image/*"
                        onChange={(e) => { onRefDocChange(e) }}
                      />
                  </div>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="col-md-4 offset-md-8 mt-1 d-flex justify-content-end">
        { props.addObjection.Mode === 'Add' ?
          <button className="btn btn-primary" type="submit" onClick={handleSubmit(() => { onSubmit(); })} disabled={!formState.isValid || (!props.candidateAnswer) || (props.candidateAnswer.length === 0)}>{commonLabels.SUBMIT}</button>
          : 
          <button className="btn btn-primary" type="submit" onClick={handleSubmit(() => { onSubmit(); })} disabled={(!props.candidateAnswer) || (props.candidateAnswer.length === 0)}>{commonLabels.SUBMIT}</button>
        }&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" className="btn btn-secondary" onClick={() => onCancelButton()}>{commonLabels.CANCEL}</button>
      </div>
    </div>
  )
}
export default AddObjection;