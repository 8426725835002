/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import common from "../labels/common_en.json";
import commonLabels from "../../application_management/labels/common_en.json";
import { Modal } from 'react-bootstrap';
import { downloadMarksheet } from "../actions/myAccountAction";
import ModuleHide from "../../common/components/ModuleHide";
import { DOWNLOADMARKSHEET } from "../../common/constants/endpoints";

function MarkSheetModal(props) {
    const dispatch = useDispatch();
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token
    const { marksheetList } = useSelector(state => state.myaccount, shallowEqual);
    const [arraymModule, setarraymModule] = useState({})
    const [selectedId, setSelectedId] = useState(marksheetList[0] && marksheetList[0]);

    useEffect(() => {
        if (marksheetList.length > 0) {
            setSelectedId(marksheetList[0] && marksheetList[0])
        }
    }, [marksheetList])

    const setName = (id) => {
        setSelectedId(id)
    }

    const downloadMarkSheet = (id) => {
        dispatch(downloadMarksheet(id))
    }

    const modalCancel = () => {
        props.setshowMarksheetListModal(false)
    }

    return (
        <form className="MarksheetModel mt50">
            <div>
                <Modal show={props.showModal} onHide={modalCancel} backdrop='static' size="xl">
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title>{common.GET_MARK_STATEMENT}</Modal.Title>
                    </Modal.Header>
                    {arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule} /> :
                        <div>
                            <Modal.Body>
                                <div className="row">
                                    <div className="content-row clear-fix ms-3" >
                                        <h6>{common.objectionModuleCandidateHeaders.ADVT_ID}</h6> <span>{props && props.applData && props.applData.advertisement && props.applData.advertisement.advertisementNo} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <h6>{common.objectionModuleCandidateHeaders.APPLICATION_ID}.</h6> <span>{props && props.applData && props.applData.applicationNo} </span>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <ul className="row top-nav">
                                            {marksheetList && marksheetList.map((ele) =>
                                                <li className="nav-item marksheetModalCss" key={ele.applicationMarksId}>
                                                    <a className={selectedId && selectedId.applicationMarksId === ele.applicationMarksId ? "nav-link active" : "nav-link bg-profile-tabs"} value="home" id="home-tab" onClick={() => setName(ele)} role="tab" aria-controls="home" aria-selected="false">
                                                        <span>{ele.marksheetName}</span>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-md-9">
                                        {selectedId && selectedId.applicationMarksId ?
                                            <>
                                                <div className="content-row clear-fix">
                                                    <h6>{selectedId && selectedId.marksheetName}</h6>
                                                    <h6 className="ml56">{common.STAGE} {selectedId && selectedId.stage}</h6>
                                                    <button className="btn btn-primary btn-sm float-end" onClick={() => { downloadMarkSheet(selectedId.applicationMarksId) }} disabled={(props && props.isReadOnlyResultMarkSheetStage)}>{commonLabels.Download_PDF}</button>
                                                </div>
                                                <div>
                                                    <iframe src={`${DOWNLOADMARKSHEET}?applicationMarksId=${selectedId && selectedId.applicationMarksId}&authorization=Bearer+${token}`} className="downloadMarksheetTemplateCss" />
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>
                            </Modal.Footer>
                        </div>}
                </Modal>
            </div>
        </form>
    );
}
export default MarkSheetModal;