/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/candidate_nav.css";
import "../../styles/advertisementTable.css";
import {getAdvertisements,setShowAdvertisementDetails} from "../actions/advertisementAction";
import AdvertisementDetails from "./AdvertisementDetails";
import dayjs from "dayjs";
import ModuleHide from "../../common/components/ModuleHide";
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

const Advertisement = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAdvertisements());
    }, []);

    const { advertisementList, showAdvertisementDetails } = useSelector(
        (state) => state.advertisement,
        shallowEqual
    );
    const { profileStatus } = useSelector(state => state.profile, shallowEqual);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const [arraymModule , setarraymModule] = useState({})

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "ONLINE_APPLICATION_TAB")[0])
    },[moduleList])

    const showDetails = (advertisementId) => {
        dispatch(setShowAdvertisementDetails(true, advertisementId));
    };

    const sortAlphaNum = (a, b) => ((a.advertisementNo + a.year)).localeCompare((b.advertisementNo + b.year), 'en', { numeric: true })

    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    showAdvertisementDetails ? (
        <AdvertisementDetails />
    ) : profileStatus ? (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="main-box">
                    <p>{advertisementDetailsLabel.ONLINE_APPLICATION}</p>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="w12 theadBgColor">{advertisementDetailsLabel.PUBLISHED_ON}</th>
                                <th className="w12 theadBgColor">{advertisementDetailsLabel.ADVT_NO_AND_YEAR}</th>
                                <th className="w-25 theadBgColor">{advertisementDetailsLabel.ADVT_NAME}</th>
                                <th className="w-25 theadBgColor">
                                    {advertisementDetailsLabel.ADVT_NAME}({advertisementDetailsLabel.DEVANAGIRI})
                                </th>
                                <th className="w10 theadBgColor"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {advertisementList.sort(sortAlphaNum).map((advertisement) => (
                                <tr key={advertisement.id}>
                                    <td className="word-break">{advertisement.createdAt ? dayjs(advertisement.applnStartDate).format(commonLabels.DATE_FORMAT):""}</td>
                                    <td className="word-break">{advertisement.advertisementNo} / {advertisement.year}</td>
                                    <td className="word-break">{advertisement.name}</td>
                                    <td className="word-break">{advertisement.nameDvng}</td>
                                    <td className=" w-10  text-center">
                                        <button type="button"  className=" btn btn-outline-primary btn-sm py-0"  onClick={() =>showDetails(advertisement.id)}>
                                            {commonLabels.VIEW}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ):(
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="main-box py-3">
                    <p className="Note-color text-center">
                        {advertisementDetailsLabel.ADVT_NOTE}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Advertisement;
