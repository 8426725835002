/* eslint-disable */
import API from '../../helpers/API';
import { hideLoader, showLoader } from "../../common/actions/commonActions";
import { SET_REGISTRATION_RESPONSE, SET_SECURITY_QUESTIONS, SET_SECURITY_QUESTIONS_FORGOT_PASSWORD, SET_SECURITY_QUESTIONS_FOR_RESET_EMAIL,SET_SECURITY_QUESTIONS_FOR_LOGIN  } from '../../common/constants/ActionTypes';
import { REGISTRATION, SECURITY_QUESTIONS } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';
import RegistrationJson from "../labels/registration_en.json";

export const getSecurityQuestions = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.get(SECURITY_QUESTIONS);
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if(response && response.data) {
                dispatch({
                    payload: response.data,
                    type: SET_SECURITY_QUESTIONS
                });
                dispatch({
                    payload: response.data,
                    type: SET_SECURITY_QUESTIONS_FORGOT_PASSWORD
                });
                dispatch({
                    payload: response.data,
                    type : SET_SECURITY_QUESTIONS_FOR_RESET_EMAIL
                })
                dispatch({
                    payload: response.data,
                    type : SET_SECURITY_QUESTIONS_FOR_LOGIN
                })
            }
        } catch (error) {
            dispatch(hideLoader());
            toastr.error(RegistrationJson.SECURITY_QUE_ERROR, error.message);
        }
    }
};

export const registerCandidate = (newCandidateDetails, props, captchaId, emailOTPResponse, mobileOTPResponse, enteredEmailOtp,enteredMobileOtp, navigate) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let securityAnswersDto = {};
            let sq_keys = Object.keys(newCandidateDetails).filter((key) => {
                return key.includes("SQ");
            })
            sq_keys.map((sq_key) => {
                securityAnswersDto[sq_key] = newCandidateDetails[sq_key]
            })
            let request = {
                "email": newCandidateDetails.userName,
                "password": newCandidateDetails.confirmPassword,
                "mobile": newCandidateDetails.mobileNumber,
                "dob": newCandidateDetails.dob,
                "userType": "Candidate",
                "securityAnswersDto": securityAnswersDto,
                "captchaId": captchaId,
                "enteredCaptcha": newCandidateDetails.enteredCaptcha,
                "emailOtpId": emailOTPResponse.id,
                "enteredEmailOtp": enteredEmailOtp,
                "mobileOtpId": mobileOTPResponse.id,
                "enteredMobileOtp":enteredMobileOtp
            }
            if(newCandidateDetails.dob){
                var today = new Date();
                  var nowyear = today.getFullYear();
                  var nowmonth = today.getMonth();
                  var nowday = today.getDate();
                  var birth = new Date(newCandidateDetails.dob);
                  var birthyear = birth.getFullYear();
                  var birthmonth = birth.getMonth();
                  var birthday = birth.getDate();
                  var age = nowyear - birthyear;
                  var age_month = nowmonth - birthmonth;
                  var age_day = nowday - birthday;
                  if (age_month < 0 || (age_month == 0 && age_day < 0)) {
                    age = parseInt(age) - 1;
                }
                if (( age < 18 && age_month <= 0 && age_day <= 0) || age < 18) {
                      dispatch(hideLoader());
                 toastr.error(RegistrationJson.AGE_NOTE);
                   return false;
                }
              }
            let password = newCandidateDetails.password;
            let  confirmpassword = newCandidateDetails.confirmPassword;
            if (password !== confirmpassword) {
                toastr.error(RegistrationJson.NEW_PASS_AND_CONFIRM_PASS_NOTE);
            } 
            else {
                let response = await API.post(REGISTRATION, request);
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
                if(response) {
                    // props.history.push("/candidate/login");
                    navigate("/candidate/login")
                    toastr.success(RegistrationJson.USER_REGISTER_SUCCESSFULLY_NOTE);
                    dispatch({
                        payload: true,
                        type: SET_REGISTRATION_RESPONSE
                    });
                }
        }
        } catch (error) {
            dispatch(hideLoader());
            if(error.response && error.response.data) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error(RegistrationJson.LOGIN_FAILED, error.message);
            }
        }
    }
};