/* eslint-disable */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BelowSSCQualification from "./BelowSSCQualification";
import StandardQualification from "./StandardQualification";
import GraduationPGQualification from "./GraduationPGQualification";
import MPhilPhDEtcQualification from "./MPhilPhDEtcQualification";
import PublicationsQualification from "./PublicationsQualification";
import CertificationsQualification from "./CertificationsQualification";
import PhDGuideQualifiation from "./PhDGuideQualifiation";
import DisabledQualificationForm from "./DisabledQualificationForm";
import QualificationTabTable from "./QualificationTabTable";
import { getQualificationDetails, saveQualificationsList } from "../actions/profileAction";
import qualificationinformation from '../labels/qualification_information_en.json';
import common from "../labels/common_en.json";
import { Modal } from 'react-bootstrap';
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/advertisementTable.css";

const renderSelectedQualificationTypeForm = (qualificationTypeCodeInput) => {
  switch(qualificationTypeCodeInput) {
    case "Select": 
      return <DisabledQualificationForm />
    case "BSSC": 
      return <BelowSSCQualification />
    case "SSC": 
      return <StandardQualification />
    case "HSC": 
      return <StandardQualification />
    case "D": 
      return <StandardQualification />
    case "G": 
      return <GraduationPGQualification />
    case "PG Diploma": 
      return <StandardQualification />
    case "PG": 
      return <GraduationPGQualification />
    case "M.Phill": 
      return <MPhilPhDEtcQualification />
    case "PHD": 
      return <MPhilPhDEtcQualification />
    case "NET/SLET/SET": 
      return <MPhilPhDEtcQualification />
    case "Pb": 
      return <PublicationsQualification />
    case "Fs": 
      return <StandardQualification />
    case "Cf": 
      return <CertificationsQualification />
    case "YCMOU": 
      return <StandardQualification />
    case "NIOS": 
      return <StandardQualification />
    case "Associate": 
      return <StandardQualification />
    case "AssociateMem": 
      return <StandardQualification />
    case "Ph.D Guide": 
      return <PhDGuideQualifiation />
    case "Other": 
      return <StandardQualification />
    case "MEMB": 
      return <StandardQualification />
  }
}

function QualificationInformation() {
  let { qualificationtypecode, qualificationToBeEdited, qualificationsList } = useSelector(state => state.profile, shallowEqual);
  let [qualificationtypecodeLocal, setQualificationtypecodeLocal] = useState(qualificationtypecode);
  const dispatch = useDispatch();
  let [showModal, setShowModal] = useState(false);
  const isDisableAllTabSave = useSelector(state => state.profile.isDisableAllTabSave);

  useEffect(() => {
    dispatch(getQualificationDetails());
  }, [])

  useEffect(() =>{
    setQualificationtypecodeLocal(qualificationToBeEdited.qualificationtypecode);
  }, [qualificationToBeEdited]);

  useEffect(() =>{
    setQualificationtypecodeLocal(qualificationtypecode);
  }, [qualificationtypecode]);

  const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
  const [arraymModule , setarraymModule] = useState({})

  useEffect(()=>{
      setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "QUALIFICATION_INFO")[0])
  },[moduleList])

  const modalConfirm = () => {
    const updatedQualificationsList = qualificationsList && qualificationsList.length > 0 && qualificationsList.filter(item => {
      const hasValues = (
        item.indexingBodyLookupId ||
        item.articleTypeLookupId ||
        item.publicationAcceptanceDate ||
        item.publicationPublishedDate
      );
      return !hasValues;
    });
    dispatch(saveQualificationsList(qualificationsList));
    setShowModal(false);
  }

  return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
  (
    <div className="p-4 f12">
        {/* <div className="p-1 mb16"> */}
          <h5 className="float-start m-1">
              {qualificationinformation.QUALIFICATION_DETAILS}
          </h5>
        {/* </div> */}
        <br/>
        <hr/>
      {
        /** qualificationInformationResponse would be used to populate the QualificationTabTable 
         * If Edit button is pressed in the Table row then the relevant form with the relevant
         * information should get re-rendered.
        */
        <QualificationTabTable/>
      }
      <br/>
      <span className="text-primary">{qualificationinformation.MANDATORY_EDUCATION_WARNING}</span><hr/>
      {
        renderSelectedQualificationTypeForm(qualificationtypecodeLocal)
      }
      <p className="paraCss"></p>
      <div className="text-center">
        <button type="button" className="btn btn-primary m-1"  onClick={() => { setShowModal(true) }} disabled={isDisableAllTabSave}>{common.SAVE}</button>
      </div>
      <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop='static'>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{qualificationinformation.SAVE_QUALIFICATION_MODAL_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{qualificationinformation.SAVE_QUALIFICATION_CONFIRMATION_MESSAGE}</label>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => { setShowModal(false) }}>{common.CANCEL}</button>
          <button className="btn btn-primary" onClick={modalConfirm}>{common.CONFIRM}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default QualificationInformation;
