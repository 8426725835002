/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ApplicationHeader from './ApplicationHeader2';
import Login from '../../registration_login/components/Login';
import Home from '../../common/components/Home';
import Registration from '../../registration_login/components/Registration';
import CandidateContainer from './CandidateContainer';
import NotFound from './NotFound';
import ForgotPassword from '../../registration_login/components/ForgotPassword';
import ForgotUserName from '../../registration_login/components/ForgotUserName';
import Loader from './Loader';
import ApplicationFooter from './ApplicationFooter';
import SessionTimeOut from './SessionTimeOut';
import { useDispatch } from 'react-redux';
import API from '../../helpers/API';
import { hideLoader, showLoader } from '../actions/commonActions';
import { isLoaderApplicable } from '../../helpers/util';
import ForgotEmailandMobile from '../../home/components/ForgotEmailandMobile';

const AppLayout = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        API.interceptors.request.use(async (request) => {
            if (isLoaderApplicable(request.url)) {
                dispatch(showLoader());
            }
            return request;
        });

        API.interceptors.response.use(
            async (response) => {
                dispatch(hideLoader());
                return response;
            },
            async (error) => {
                dispatch(hideLoader());
                throw error;
            }
        );
    }, [dispatch]);

    return (
        <div className="container-fluid">
            <ApplicationHeader {...props} />
            {/* <BrowserRouter> */}
                <Routes>
                    <Route path="/candidate" element={<Home />} />
                    <Route path="/candidate/login" element={<Login />} />
                    <Route path="/candidate/registration" element={<Registration />} />
                    <Route path="/candidate/main" element={<CandidateContainer />} />
                    <Route path="/candidate/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/candidate/forgotEmailMobile" element={<ForgotEmailandMobile />} />
                    <Route path="/candidate/forgotlogin" element={<ForgotUserName />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            {/* </BrowserRouter> */}
            <ApplicationFooter />
            <Loader />
            <SessionTimeOut {...props} />
        </div>
    );
};

export default AppLayout;


// import React, { useState, useEffect } from 'react';
// import { Switch, Route, BrowserRouter } from 'react-router-dom';
// import ApplicationHeader from './ApplicationHeader2';
// import Login from '../../registration_login/components/Login';
// import Home from '../../common/components/Home';
// import Registration from '../../registration_login/components/Registration';
// import CandidateContainer from './CandidateContainer';
// import NotFound from './NotFound';
// import ForgotPassword from '../../registration_login/components/ForgotPassword';
// import ForgotUserName from '../../registration_login/components/ForgotUserName';
// import Loader from './Loader';
// import ApplicationFooter from './ApplicationFooter';
// import SessionTimeOut from './SessionTimeOut';
// import { useDispatch } from 'react-redux';
// import API from '../../helpers/API';
// import { hideLoader, showLoader } from '../actions/commonActions';
// import { isLoaderApplicable } from '../../helpers/util';
// import ForgotEmailandMobile from '../../home/components/ForgotEmailandMobile';

// const commonRoutes =
//     <Switch>
//         <Route exact path="/candidate" component={Home} />
//         <Route path="/candidate/login" component={Login} />
//         <Route path="/candidate/registration" component={Registration} />
//         <Route path="/candidate/main" component={CandidateContainer} />
//         <Route path="/candidate/forgotPassword" component={ForgotPassword} />
//         <Route path="/candidate/forgotEmailMobile" component={ForgotEmailandMobile} />   
//         <Route path="/candidate/forgotlogin" component={ForgotUserName} />
//         <Route component={NotFound} />
//     </Switch>

// const AppLayout = (props) => {
//     const [routes] = useState(commonRoutes);
//     window.addEventListener("popstate", e => {
//         props.history.go(1);
//     });

//     const dispatch = useDispatch();
//     useEffect(() => {
//         API.interceptors.request.use(async (request) => {
//             if(isLoaderApplicable(request.url)) {
//                 dispatch(showLoader());
//             }
//             dispatch(hideLoader());
//             return request;
//         });
//         API.interceptors.response.use(async (response) => {
//             dispatch(hideLoader());
//             return response;
//         }, async (error) => {
//             dispatch(hideLoader());
//             throw error;
//         });
//     }, [])

//     return (
//         <div className="container-fluid">
//             <ApplicationHeader {...props}/>
//             <BrowserRouter>
//                 {routes}
//             </BrowserRouter>
//             <ApplicationFooter/>
//             <Loader/>
//             <SessionTimeOut {...props}/>
//         </div>
//     )
// };

// export default AppLayout;