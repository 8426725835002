import API from '../../helpers/API';
import { hideLoader, showLoader } from "../../common/actions/commonActions";
import { SET_FORGOT_PASSWORD_STATE, SET_USER_LOGIN_DATA } from '../../common/constants/ActionTypes';
import { FORGETPASSWORD, RESETPASSWORD, FORGOT_LOGIN } from '../../common/constants/endpoints';
import { toastr } from 'react-redux-toastr';
import ForgotPasswordJson from "../labels/forgot_password_en.json";

export const submitForgotPassword = (data, otpDetails, userName) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let reqBody;
        try {
            reqBody = {
                "email": userName,
                "dob": data.DateOfBirt,
                "emailOtpId": otpDetails.id,
                "enteredEmailOtp": data.OTP_email,
                "type": otpDetails.type,
            }
            let response = await API.post(FORGETPASSWORD, reqBody);
            let submitData = {
                flgSubmit:false,
                submitDetails:reqBody
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if(response && response.data) {
              
                submitData.flgSubmit = response.data.isValidForgotPswdUser
                if(response.data.securityAnswerList != null){
                    submitData.securityAnswerList = response.data.securityAnswerList 
                }else{
                    submitData.securityAnswerList = {} 
                }
                dispatch({
                    payload: submitData,
                    type: SET_FORGOT_PASSWORD_STATE
                });
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = ""
            if(error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(ForgotPasswordJson.DETAILS_NOT_MATCH_NOTE, errorMessage);
        }
    }
};

export const resetPassword = (data, props, navigate) => {
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.post(RESETPASSWORD, data);
            let submitData = {
                flgSubmit:false,
                submitDetails:{}
            }
            dispatch({
                payload: submitData,
                type: SET_FORGOT_PASSWORD_STATE
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
            if(response.status === 200) {
                toastr.success(ForgotPasswordJson.RE_LOGIN_NOTE);
                // props.history.push("/candidate/login");
                navigate("/candidate/login")
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = ""
            if(error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(ForgotPasswordJson.PASS_NOT_UPDATED_NOTE, errorMessage);
        }
    }
};

export const forgotLogin = (req) => {
    let reqBody = {
        usertype: "",
        userid: "",
        dob: "",
        mothername: ""
    }
    reqBody.usertype = req.usertype;
    reqBody.dob = req.dob;
    reqBody.mothername = req.mothername;
    if (req.usertype === "migrated_username") {
        reqBody.userid = req.userNameText.trim();;
    } else if (req.usertype === "email") {
        reqBody.userid = req.emailId.trim();;
    } else {
        reqBody.userid = req.mobileNo.trim();;
    }
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            let response = await API.post(FORGOT_LOGIN, reqBody);
            dispatch({
                payload: response.data,
                type: SET_USER_LOGIN_DATA
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = ""
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = error.message;
            }
            toastr.error(ForgotPasswordJson.DETAILS_NOT_MATCH_NOTE, errorMessage);
        }
    }
}

export const hideResetPasswordScreen = () => {
    let resetState = {
        isShowResetPassword: false,
        forgetPasswordDetails: ""
    }
    return async (dispatch) => {
        dispatch({
            payload: resetState,
            type: SET_FORGOT_PASSWORD_STATE
        })
    }
}



