/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setProfileCreationDropdown, getAllQualificationTypes, getAllDisabilities} from "../../candidate_profile_creation/actions/profileAction";
import ProfileDetails from "../../application_management/components/ProfileDetails";
import { getSupportProfile } from "../actions/supportActions";
import commonLabels from "../../my_account/labels/common_en.json";

export default function SupportProfileDisplay() {
  const [apiKey, setApiKey] = useState('');
  const [username, setUsername] = useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "apiKey":
        setApiKey(value);
        break;
      case "username":
        setUsername(value);
        break;
    }
  }

  const handleSubmit = () => {
    dispatch(getSupportProfile(apiKey, username));
  }

  useEffect(() => {
    dispatch(setProfileCreationDropdown());
    dispatch(getAllDisabilities());
    dispatch(getAllQualificationTypes());
  }, [dispatch])

  return (
    <div className="container">
      <h3 className="mt-2 mb-2">{commonLabels.PROFILE_DISPLAY_SUPPORT_PAGE}</h3>
      <div className="row">
        <div className="col-md-4 text-end">
            {commonLabels.API_KEY}
        </div>
        <div className="col-md-4">
          <input type="password" className="form-control" name="apiKey"
            value={apiKey} onChange={handleChange} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4 text-end">
            {commonLabels.CANDIDATE_EMAIL_OR_MOBILE_NO}
        </div>
        <div className="col-md-4">
          <input type="text" className="form-control" name="username"
            value={username} onChange={handleChange} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4 text-end">
          <button className="btn btn-primary" onClick={handleSubmit}>{commonLabels.SUBMIT}</button>
        </div>
      </div>
      <ProfileDetails />
    </div>
  )
}
