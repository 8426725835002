import React from "react";
import qualificationinformation from '../labels/qualification_information_en.json'
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import numberformat from "../labels/serial_nos_en.json";

const DisabledQualificationForm = () => {
  return (
    <form>
      <div className="row">
        <QualificationTypeDropdown />
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.NAME_OF_DEGREE}</label>
          <select className="form-select" name ="qualificationnamecode" disabled/>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.3"]}&nbsp;{qualificationinformation.SUBJECT}</label>
          <select className="form-select" name ="subjectLookupId" disabled/>
        </div>
        {/** Following div is to displayed conditionally */}
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.STATE}</label>
          <select className="form-select" name ="statecode" disabled/>
        </div>
        <div className="form-group col-md-6">
          <label className="float-start mb-0" htmlFor="universitycode">{numberformat["4.5"]}&nbsp;{qualificationinformation.BOARD_UNIVERSITY}</label>
          <select id="universitycode" className="form-control"  name ="universitycode" disabled/>
        </div>
        <div className="col-md-3">
          <label className="float-start mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.RESULT} </label>
          <select className="form-select" name ="typeResult" disabled/>
        </div>
        <div className="col-md-3" >
          <label className="float-start mb-0">{numberformat["4.7"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          <DatePicker
                defaultValue={new Date()}
                className="form-control"
                disabled />
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.8"]}&nbsp;{qualificationinformation.ATTEMPTS}</label>
          <input type="text" className="form-control" name ="attempts" disabled></input>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.9"]}&nbsp;{qualificationinformation.PERCENTAGE_GRADE}</label>
          <input type="text" className="form-control"  name ="percentageGrade" disabled></input>
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.10"]}&nbsp;{qualificationinformation.COURSE_DURATION}</label>
          <input type="text" className="form-control" name ="courseDurationMonths" disabled></input>
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.11"]}&nbsp;{qualificationinformation.CLASS_GRADE}</label>
          <select className="form-select" name ="classGradeLookupId" disabled/>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.12"]}&nbsp;{qualificationinformation.MODE}</label>
          <select className="form-select" name ="modeLookupId" disabled/>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.13"]}&nbsp;{qualificationinformation.COMPULSORY_SUBJECTS}</label>
          <select type="text" className="form-control" name ="compulsorySubjects" disabled></select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-start mb-0">{numberformat["4.14"]}&nbsp;{qualificationinformation.OPTIONAL_SUBJECTS}</label>
          <select type="text" className="form-control" name ="optionalSubjects" disabled></select>
        </div>
      </div>
    </form>
  );
}
export default DisabledQualificationForm;
