/* eslint-disable */
import React, { useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import common from "../labels/common_en.json";
import 'jspdf-autotable';
import { manageObjReq, getObjReqData} from "../actions/myAccountAction";
import "../../styles/admit_card.css";
import { Modal } from 'react-bootstrap';
import AddObjection from "./AddObjection";
import myAccountLabel from "../labels/my_account_en.json";
import ObjectionPaymentModal from "./ObjectionPaymentModal";

const Objection = ({ setShwModal, advId, isReadOnlyObjectionAnswerKeyStage}) => {
    const dispatch = useDispatch();
    const { objReqData } = useSelector((state) => state.myaccount, shallowEqual);
    let [addObjection, setAddObjection] = useState({ "Mode": false, 'applicationId': '', 'advertisementId': '', 'examName': '', 'examId': '', 'examSubjectId': '', 'examPaperSet': '', 'paymentRefNum': '', 'objectionType': '', 'candidateSetQuestionSerialNumber': '', 'candidateAnswer': '', 'masterQueSrNum': '', 'masterAnswerKey': '', "objectionRemark": '', "refBook": '', "authorName": '', "refDoc": '', "refDoc2":''});
    let [applicationId, setApplicationId] = useState('');
    let [advertisementId, setAdvertisementId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    let [examSubjectId, setExamSubjectId] = useState('');
    let [examId, setExamId] = useState('');
    let [examName, setExamName] = useState('');
    let [examPaperSet, setExamPaperSet] = useState('');
    let [paymentRefNum, setPaymentRefNum] = useState("");
    let [objectionType, setObjectionType] = useState('');
    let [candidateSetQuestionSerialNumber, setCandidateSetQuestionSerialNumber] = useState('');
    let [candidateAnswer, setCandidateAnswer] = useState('');
    let [answerInAnswerKey, setAnswerInAnswerKey] = useState('');
    let [masterQueSrNum, setMasterQueSrNum] = useState('');
    let [masterAnswerKey, setMasterAnswerKey] = useState('');
    let [objectionRemark, setObjectionRemark] = useState('');
    let [refBook, setRefBook] = useState('');
    let [authorName, setAuthorName] = useState('');
    let [refDoc, setRefDoc] = useState('');
    let [refDoc2, setRefDoc2] = useState('');
    let [showPaymentModal, setShowPaymentModal] = useState(false);

    const deleteReq = (id, applicationId) => {
        let reqbody = { 'id': id, 'isDeleted': true };
        dispatch(manageObjReq(reqbody, applicationId));
    };

    const handleAddButtonClick = () => {
        setIsOpen(true);
        setAddObjection({ "Mode": "Add" });
    };

    const handleEditButtonClick = (value) => {
        setAddObjection({
            "Mode": "Edit",
            "id": value.id,
            "applicationId": value.applicationId,
            "advertisementId": value.advtId,
            "examName": value.examName,
            "examId": value.examId,
            "examSubjectId": value.examSubjectId,
            "examPaperSet": value.examPaperSet,
            "objectionType": value.objectionType,
            "candidateSetQuestionSerialNumber": value.candidateSetQuestionSrno,
            "candidateAnswer": value.candidateAnswer,
            "answerInAnswerKey": value.answerInAnswerKey,
            "objectionRemark": value.objectionRemark,
            "refBook": value.referenceBook,
            "authorName": value.authorName,
            "refDoc": '',
            "refDoc2":''
        })
        setApplicationId(value.applicationId);
        setAdvertisementId(value.advtId);
        setExamSubjectId(value.examSubjectId);
        setExamId(value.examId);
        setExamName(value.examName);
        setExamPaperSet(value.examPaperSet)
        setObjectionType(value.objectionType)
        setCandidateSetQuestionSerialNumber(value.candidateSetQuestionSrno);
        setCandidateAnswer(value.candidateAnswer);
        setAnswerInAnswerKey(value.answerInAnswerKey)
        setObjectionRemark(value.objectionRemark);
        setRefBook(value.referenceBook);
        setAuthorName(value.authorName);
        setRefDoc('');
        setRefDoc2("");
    };

    const objectionModuleCandidateHeaders = [
        "Sr. No.",
        "Exam Name",
        "Exam Subject Name",
        "Exam Paper Set",
        "Objection Type",
        "Candidate Set Question Serial Number",
        "Correct Answer as per Answer Key",
        "Candidate Answer",
        "Objection Remark",
        "Reference Book",
        "Author Name",
        "Action",
    ];

    const handlePaymentClick = () => {
        setShowPaymentModal(true);
        dispatch(getObjReqData(advId))
    };

    return addObjection.Mode !== false ? (
        <div>
            <Modal backdrop='static' size="xl" show={true} onHide={() => setShwModal(false)}>
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title>{myAccountLabel.OBJECTION_REQUEST}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddObjection addObjection={addObjection}
                        setAddObjection={setAddObjection}
                        applicationId={applicationId}
                        advertisementId={advertisementId}
                        examId={examId}
                        examName={examName}
                        examSubjectId={examSubjectId}
                        examPaperSet={examPaperSet}
                        paymentRefNum={paymentRefNum}
                        objectionType={objectionType}
                        candidateSetQuestionSerialNumber={candidateSetQuestionSerialNumber}
                        candidateAnswer={candidateAnswer}
                        answerInAnswerKey={answerInAnswerKey}
                        masterQueSrNum={masterQueSrNum}
                        masterAnswerKey={masterAnswerKey}
                        objectionRemark={objectionRemark}
                        refBook={refBook}
                        authorName={authorName}
                        refDoc={refDoc}
                        refDoc2={refDoc2}
                        setApplicationId={setApplicationId}
                        setAdvertisementId={setAdvertisementId}
                        setExamId={setExamId}
                        setExamName={setExamName}
                        setExamSubjectId={setExamSubjectId}
                        setExamPaperSet={setExamPaperSet}
                        setPaymentRefNum={setPaymentRefNum}
                        setObjectionType={setObjectionType}
                        setCandidateSetQuestionSerialNumber={setCandidateSetQuestionSerialNumber}
                        setCandidateAnswer={setCandidateAnswer}
                        setAnswerInAnswerKey={setAnswerInAnswerKey}
                        setMasterQueSrNum={setMasterQueSrNum}
                        setMasterAnswerKey={setMasterAnswerKey}
                        setObjectionRemark={setObjectionRemark}
                        setRefBook={setRefBook}
                        setAuthorName={setAuthorName}
                        setRefDoc={setRefDoc} 
                        setRefDoc2={setRefDoc2}
                        />
                </Modal.Body>
            </Modal>
        </div>) : (
        <div>
            <div>
                <Modal backdrop='static' size="xl" show={true} onHide={() => setShwModal(false)}>
                    <Modal.Header closeButton className="custom-modal-header">
                        <Modal.Title>{myAccountLabel.OBJECTION_REQUEST}</Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div className="text-end me-4">
                                <button className="btn btn-primary" onClick={handleAddButtonClick} disabled={isReadOnlyObjectionAnswerKeyStage}>{myAccountLabel.ADD_OBJECTION_REQUEST}</button>
                            </div>
                            <div>
                                { isOpen && <AddObjection {...addObjection} />}
                            </div>
                            <div className="max-height-600 overflow-auto">
                                <table>
                                    <thead>
                                        <tr>
                                            {objectionModuleCandidateHeaders.map((ele, i) => {
                                                return <th className="common text-center theadBgColor" scope="col" key={i}>{ele}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objReqData && objReqData.objectionRequestsDtoList && objReqData.objectionRequestsDtoList.length > 0 ?  objReqData.objectionRequestsDtoList.map((element, id) => {
                                            if (element) {
                                                const tableCounter = id + 1;
                                                const isFeesPaid = element.isFeesPaid;
                                                const disableIconsClass = (isFeesPaid || isReadOnlyObjectionAnswerKeyStage) ? "disabled-icon" : "";
                                                return (
                                                    <React.Fragment key={id}>
                                                        <tr >
                                                            <td className="text-center">{tableCounter}</td>
                                                            <td>{element?.examName}</td>
                                                            <td>{element?.examSubjectName}</td>
                                                            <td className="text-center">{element?.examPaperSetName}</td>
                                                            <td className="text-center">{element?.objectionTypeName}</td>
                                                            <td className="text-center">{element?.candidateSetQuestionSrno}</td>
                                                            <td className="text-center">{element?.answerInAnswerKey}</td>
                                                            <td className="text-center">{element?.candidateAnswer}</td>
                                                            <td>{element?.objectionRemark}</td>
                                                            <td>{element?.referenceBook}</td>
                                                            <td>{element?.authorName}</td>
                                                            <td className="col-1">
                                                                <i
                                                                    role="button"
                                                                    data-toggle="tooltip"
                                                                    title="Edit"
                                                                    className={`text-primary fa fa-edit ${disableIconsClass}`}
                                                                    onClick={(!isFeesPaid && !isReadOnlyObjectionAnswerKeyStage) ? (e) => { handleEditButtonClick(element) } : null}
                                                                ></i>
                                                                &nbsp;&nbsp;
                                                                <i
                                                                    role="button"
                                                                    data-toggle="tooltip"
                                                                    title="Delete"
                                                                    className={`text-danger fa fa-trash ${disableIconsClass}`}
                                                                    onClick={(!isFeesPaid && !isReadOnlyObjectionAnswerKeyStage) ? () => { deleteReq(element.id, element.applicationId) } : null}
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                        })
                                        :
                                        <tr>
                                         <td colSpan="11" className="text-center">{common.NO_DATA_TO_DISPLAY}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary ms-4" onClick={handlePaymentClick} disabled={(objReqData && objReqData.objectionRequestsDtoList && objReqData.objectionRequestsDtoList.length > 0 ? false : true) || isReadOnlyObjectionAnswerKeyStage} >{myAccountLabel.PAYMENT}</button>
                            </div>
                            { 
                                showPaymentModal && 
                                    <ObjectionPaymentModal 
                                        showPaymentModal = {showPaymentModal}
                                        setShowPaymentModal = {setShowPaymentModal}
                                        objReqData = {objReqData}
                                    />
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary me-4"  onClick={() => setShwModal(false)}>{common.CANCEL}</button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default Objection;