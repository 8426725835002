/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import '../../styles/forgot_password.css';
import _ from "lodash/fp";
import ForgotPasswordJson from "../labels/forgot_password_en.json";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { logout, saveSecurityAnswerForLogin } from "../../registration_login/actions/sessionActions";
import { getSecurityQuestions } from "../actions/registrationActions";
import { securityQuestionRegex , numberValidation } from "../../common/constants/regex"
import {  setSecurityAnswerForLogin } from "../../registration_login/actions/sessionActions";
import profile from "../../candidate_profile_creation/labels/personal_information_en.json";
import "../../styles/candidate_nav.css";
import "../../styles/advertisementTable.css";
import { useNavigate } from 'react-router-dom';

function SecurityQuestion(props) {
    const { register, handleSubmit, errors, formState} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate();

    let { questions, securityAnswerMap } = useSelector(state => state.loginReducer, shallowEqual);
    const dispatch = useDispatch();
    const [securityAnswer, setSecurityAnswer ]= useState(new Map())
   
    useEffect(() => {
        dispatch(getSecurityQuestions());
        dispatch(setSecurityAnswerForLogin());
    }, [])

    useEffect(() => {
        if(securityAnswerMap){
            let securityAnswer = new Map(Object.entries(securityAnswerMap));
            setSecurityAnswer(securityAnswer);
        }
    }, [securityAnswerMap])

    const onSubmit = (data) => {
            let formDataMap = new Map(Object.entries(data));
            let SQList = Object.keys(questions);
            let obj = new Map()
            SQList.map(ele => {
                if(securityAnswer.has(ele) && securityAnswer.get(ele) != null){
                    obj.set(ele, securityAnswer.get(ele))
                }else{
                    obj.set(ele, formDataMap.get(ele))
                }
            })
            const securityAnswerList = Object.fromEntries(obj.entries())
            let reqBody = {}
            reqBody.securityAnswerList = securityAnswerList
            dispatch(saveSecurityAnswerForLogin(props, reqBody))
    }  

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row mt50 mb50">
            <div className="col-md-3"></div>
            <div className="col-md-6 forgotPassTabCss">
               
                <div>
                    {(Object.entries(securityAnswerMap).length === Object.entries(questions).length && Object.values(securityAnswerMap).every(a => a != null)) ? ""  :
                    <div className="background-field mt-3">
                        <span className="errorText text-primary">{ForgotPasswordJson.SECURITY_QUESTIONS_NOTE}</span>
                    </div>}
                    <div className="form-group">
                        {Object.entries(questions).sort().map(([key, value]) => {
                        return(
                                <div>
                                    {securityAnswer && securityAnswer.has(key) && (securityAnswer.get(key) != undefined || securityAnswer.get(key) != null) ?
                                     "" :
                                    <div className="row form-group" key={key}>
                                        <div className="col-md-8 d-flex align-items-center">
                                            <label>{value}<span className="errorText">*</span></label>
                                        </div>
                                        {key === 'SQ3' ?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" name={key} id="sq" ref={register({required: true})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                        </div>: ""}
                                        {key === 'SQ4' ?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" maxLength={4} name={key} id="sq" ref={register({required: true, pattern:numberValidation})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                            {_.get(key + ".type", errors) === "pattern" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                            )}
                                        </div>: ""}
                                        {key === ('SQ1') || key === ('SQ2') || key === ('SQ5') || key === ('SQ6')?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" name={key} id="sq" ref={register({required: true, pattern: securityQuestionRegex})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                            {_.get(key + ".type", errors) === "pattern" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                            )}
                                        </div>: ""}
                                    </div> 
                                        }
                                </div>)
                        })}
                    </div>
                </div>
                <div className="buttonAlign">
                    <button type="submit" className="btn btn-primary me-1" disabled={!formState.isValid} >{profile.SAVE}</button>
                    <button type="button" className="btn btn-primary" onClick={()=>{ dispatch(logout(props,true, navigate)) }}>{ForgotPasswordJson.LOGOUT}</button>
                </div>
            </div>
        </form>
    );
}
export default SecurityQuestion;